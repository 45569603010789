import React, { useEffect, useLayoutEffect, useState } from "react";
import SnpComponent from "../global/SnpComponent";
import { useSelector } from "react-redux";
import './BillingModule.css';
import { Grid, TextField, Paper, Box, Checkbox, TableContainer, TableBody, Tooltip, InputAdornment, IconButton } from "@mui/material";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from '@mui/material/CircularProgress';
//Need to refactor this usage
import { makeStyles, withStyles } from "@mui/styles";
import CustomDataGrid from "../global/CustomDataGrid";
import Typography from "@mui/material/Typography";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
    SUCCESS,
    getPreUrlAccordingToCart,
    GET_BILL_CATEOGORY_DATA,
    GET_BILL_PRD_DATA,
    OfferType,
    CART_INITIAL_STATE,
    GET_CUSTOMER_DETAIS,
    POSTRESTOBILL, SETTLE_RESTO_BILL, PRINT_RESTO_BILL, FETCH_BILL, DEL_ITEM_BILL,
    CASHPAY, Spicy, FoodType,
    ONLINEPAY,
    BillStatus, COMPONENT_PARAMS, isEmpty, isNumberEmpty, InternalBillType, UPDATEPARTIALPAY, detectBrowser, RESTO_KOT, BACKDATEALLOWED, GETSINGLECONFIGS, formatDateInReact, formatDateInSnp, INITIATESAVEBILL, DELETEBILL, DashboardComponent, ROLEERROR, ITEMWISEOFFER, DELETEBILLCONFIG, isRoleAllowed, SALESRIGHTS, GETBILLPRODUCTBYPRDID, printDebugLog, GETKOTBYITEM, FAILED, formatDateinStyled, CUSTLOYALITYPOINT, PARTILBILLAUDIT, HOTELSALESCONFIG, GENERATEBILL, SEARCHROOM, PAYLATERRESTOBILL, HOMEDELVDETAILS, KOTPRINTITEMWISE, KOTPRINTNOWISE, PRINT_RESTO_KOT_ITEM, PRINT_RESTO_KOT_NO, BILLPRINTWIDTH, ADMINRIGHTS, DELETEREASON, GSTFEATURE, DELROOM, PRINT_RESTO_BILL_MOBILE, ASKFORPRINT, SEND_TO_KITCHEN, KITCHEN_COOKING, KITCHEN_READY, TRIP_ASSIGNED, TRIP_DELIVERED, TRIP_RUNNING, FETCHFEWEMPLOYEE, RIDERAPPACCESS,
    PayMedium
} from "../../constants/constant";
import DialogBox from "../global/DialogBox";
import {
    Button, FormControl, RadioGroup, FormControlLabel,
    FormLabel, Radio
} from "@mui/material";
import printJS from "print-js";
import spiceLevel3Logo from '../../assets/chili-pepper.png';
import spiceLevel1Logo from '../../assets/chili.png';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import Loader from "../global/loader/Loader";
import { snpAxios } from "../global/api";
import axios from "axios";
import { addDataIndb, getDataFromDb, deleteItemForDb } from "../global/indexedDB";
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";

export default function RestoBillingModule({ setComponentIndex }) {
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 767px)').matches);
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: false,
        iconContent: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
        handleSnpSubmit: null,
        handleSnpCancel: null,
        gui: "",
    };
    const [snpLoader, setSnpLoader] = useState(false);
    const [gstFeature, setGstFeature] = useState(false);
    const [hotelDialogLoader, setHotelDialogLoader] = useState(false);
    const INITIAL_BILL_STOCK_DATA = {
        prdId: "",
        name: "",
        sgst: 0,
        cgst: 0,
        price: 0.0,
        foodQuantityName: "",
        priceIndex: 0,
        finalprice: 0.0,
        note: "",
        savedQuantity: 1,
        quantity: 1,
        offerDenom: 0,
        offerAmt: 0.0,
        print: false,
        combo: false,
    };
    const INITIAL_STOCK_DATA = {
        id: "",
        catgId: "",
        catgName: "",
        name: "",
        productImage: [],
        ingredients: "",
        spicy: Spicy.LESS_SPICY,
        foodType: FoodType.VEG,
        sgst: 0.0,
        cgst: 0.0,
        outOfStock: false,
        offertype: OfferType.No_Offer,
        offerAmt: 0.0,
        price: [],
        liveStockCount: "",
        unitCount: 0.0,
        smallUnitCount: 0.0,
        unitRestrictions: 0.0,
    };
    const INITIAL_BILL_DETAILS = {
        subTotal: 0.0,
        cgst: 0.0,
        cgstAmt: 0.0,
        sgstAmt: 0.0,
        sgst: 0.0,
        isGstAllowed: true,
        roundOff: 0.0,
        cashTender: 0.0,
        cashReturn: 0.0,
        pointRedeem: 0.0,
        isPointAdded: false,
        total: 0.0,
        currDues: 0.0,
        pendingAmt: 0.0,
        offerDenom: OfferType.No_Offer,
        offerAmt: 0.0,
        billingType: InternalBillType.TakeAway,
        lapsedTime: 0,
        lpatialAmt: [],
        token: -1,
    };
    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [billUtils, setBillUtils] = useState({
        tableId: "",
        tableNo: 0,
        billNo: "",
        tableName: "",
        billingType: InternalBillType.TakeAway,
    });
    const [gridNo, setGridNo] = useState(1);
    const [categoryData, setCategoryData] = useState([]);
    const [catgProductData, setCatgProductData] = useState([]);
    const [filteredCatgProductData, setFilteredCatgProductData] = useState([]);
    const [selectedProductData, setSelectedProductData] = useState([]);
    const [showItemDialog, setShowItemDialog] = useState(false);
    const [selectMultipleItemDialog, setSelectMultipleDialog] = useState(false);
    const [isEditItem, setIsEditItem] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [clearDues, setClearDues] = useState(false);
    const [backDateAllowed, setBackDateAllowed] = useState(false);
    const [deletBillOption, setDeleteBillOption] = useState(false);
    const [loyaityPoints, setLoyaityPoints] = useState(false);
    const [partilBillAvail, setPartialBillAvail] = useState(false);

    const [hotelSale, setHotelSales] = useState(false);
    const [genBill, setGenBill] = useState(false);
    const [hotelDelvTxn, setHotelDelvTxn] = useState(false);
    const [kotPrintNoWise, setkotPrintNoWise] = useState(false);
    const [kotPrintItemWise, setKotPrintItemWise] = useState(false);
    const [askForPrint, setAskForPrint] = useState(false);

    const INITIALROOMDETAIS = {
        roomNo: "",
        roomBooked: false,
        hotelBillId: "",
        custName: "",
        saved: false,
        mobNo: "",
        emailId: "",
        address: "",
    };
    const [riders, setRiders] = useState([]);
    const INITIAL_RIDER_DETAILS = {
        id: "", name: "",
    }
    const [riderDetails, setRiderDetails] = useState(INITIAL_RIDER_DETAILS);


    const [roomDetails, setRoomDetails] = useState(INITIALROOMDETAIS);

    const [itemWiseOffer, setItemWiseOffer] = useState(false);

    const [billPrintWidth, setBillPrintWidth] = useState('');
    //const [printJarAllowed,setPrintJarAllowed] = useState(false);
    const [delReason, setDelReason] = useState(false);

    const todayFormattedDate = new Date().toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    });



    const INITIAL_LISTBILLINGDATA = {
        billNo: "",
        value_2: [],
        delItems: [],
        custId: "",
        custMob: "",
        custName: "",
        custEmail: "",
        address: "",
        custGstDetails: "",
        custCompanyName: "",
        billStatus: BillStatus.NO_ENTRY,
        pointRedeem: 0,
        token: 0,
        roundOff: 0.0,
        kot: 0,
        hotelBillId: "",
        hotelBillNo: "",
        homeDelvId: "",
        homeDelvOtp: "",
        generateOtp: "",
        onlineBillOtherName: "",
        settleDown: false,
        payLaterSignal: false,
        createdAt: todayFormattedDate,
        tripUpdateReq: false,
        tripStatus: "",
        pTripStatus: "",
        assignedBy: "",
        assignedByName: "",
        riderId: "",
        riderIdName: "",
        transactionId: "",
    };

    const [listbillingData, setBillingData] = useState(INITIAL_LISTBILLINGDATA);
    const INITIAL_PAYMENT_DETAILS = {
        onlinePayThrough: "",
        onlinePayMedium: "",
        cashPayThrough: "",
        onlinePay: 0.0,
        cashPay: 0.0,
        cashTender: 0.0,
        cashReturn: 0.0,
        delvName: "",
        payMedium: PayMedium.NOPAY,
    };
    const [paymentDetails, setPaymentDetails] = useState(INITIAL_PAYMENT_DETAILS);
    const [stockSingleData, setStockSingleData] = useState(INITIAL_STOCK_DATA);
    const [billStockData, setBillStockData] = useState(INITIAL_BILL_STOCK_DATA);
    const [billDetails, setBillDetails] = useState(INITIAL_BILL_DETAILS);
    const [isCustLoading, setIsCustLoading] = useState(false);
    const [isCustDataAvailable, setIsCustDataAvailable] = useState(false);
    const [isOnlinePayEnabled, setOnlinePayEnb] = useState(false);
    const [isCashPayEnabled, setCashPayEnb] = useState(false);
    const [isOthHomeDelv, setIsOtherHomeDelv] = useState(false);

    const [delvNameOth, setDelvNameOth] = useState("");
    const [delvCharge, setDelvCharge] = useState(0.0);
    const [printDetails, setPrintDetails] = useState({
        shopPrint: "",
        custPrint: "",
    });
    const [customerInputDialog, setCustomerInputDialog] = useState({
        custInputopen: false,
        custInputAdd: false,
        redeemRoyaltyPrice: false,
        getRoyaltyDiscount: false,
        amtPaid: ''
    });
    const [custCopy, setCustCopy] = useState(false);
    const [dateChange, setDateChange] = useState(false);
    const [hotelRoomDialog, setHotelRoomDialog] = useState(false);
    const [printKotNoDialog, setPrintKotNoDialog] = useState(false);

    const INITIAL_DEL_ITEM = {
        showDialog: false,
        prdId: "",
        delReason: "",
        prdName: "",
    };
    const [delItemDialog, setDelItemDialog] = useState(INITIAL_DEL_ITEM);

    const INITIAL_GENERATE_DIALOG = {
        showDialog: false,
        otp: "",
    };
    const [genOtpDialog, setGenOtpDialog] = useState(INITIAL_GENERATE_DIALOG);


    const TRIP_STATUS = [
        SEND_TO_KITCHEN, KITCHEN_COOKING, KITCHEN_READY, TRIP_ASSIGNED, TRIP_DELIVERED
    ];

    const TRIP_STATUS_BACK = [
        {
            no: 1, name: SEND_TO_KITCHEN
        },
        {
            no: 2, name: KITCHEN_COOKING
        },
        {
            no: 3, name: KITCHEN_READY
        },
        {
            no: 4, name: TRIP_ASSIGNED
        },
        {
            no: 6, name: TRIP_DELIVERED
        },
    ];


    const INITIAL_PARTIAL_PAY = {
        id: "",
        partialAmt: 0.0,
        onlineBillPaidThrough: "",
        onlinePay: 0.0,
        cashBillPaidThrough: "",
        cashPay: 0.0,
        paidAcceptBy: "",
        paidOn: "",
        cashTendered: 0.0,
        cashReturn: 0.0,
    };
    const [partialPay, setPartialPay] = useState(INITIAL_PARTIAL_PAY);
    //Network Corner

    const getRiderDetails = async () => {
        //let cartUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_RESTAURANT_DATA+"/"+pageData.page+"/"+pageData.pageSize;
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + FETCHFEWEMPLOYEE;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                roles: RIDERAPPACCESS,
                lookInTripReq: true
            },
        };
        //  console.log("getRestoDetails  => ",data);
        snpAxios
            .post(cartUrl, data)
            .then((response) => {
                // console.log("response  => ", response.data);
                if (response.data.status === SUCCESS) {
                    setRiders(response.data.jcJson.value_2);
                }
            })
            .catch((error) => {
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };

    const getCateogoryData = async () => {
        setSnpLoader(true);
        let catgUrl =
            storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_BILL_CATEOGORY_DATA;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            },
        };
        // //printLog("getCateogoryData => ",data);
        snpAxios
            .post(catgUrl, data)
            .then((response) => {
                //printLog("getCateogoryData => ",response.data);
                if (response.data.status === SUCCESS) {
                    setSnpLoader(false);
                    setGridNo(1);
                    setCategoryData(response.data.jcJson.value_2);
                } else {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Home",
                        positiveAction: "Add Cateogory",
                        positiveReq: true,
                        showDialog: true,
                        outSideClickDismiss: false,
                        cancelExtra: true,
                        requestType: DashboardComponent.CATEOGORY,
                        subTitle: "No Cateogory is available! Please add"
                    });
                    setSnpLoader(false);
                }
            })
            .catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                    negativeReq: true,
                    negativeAction: "Home",
                    showDialog: true,
                    outSideClickDismiss: false,
                    cancelExtra: true,
                    requestType: 1,
                    subTitle: "Internet Connection Lost"
                });
                setSnpLoader(false);
                //printLog("Error : ", error);
                //window.location.href = "#/error";
            });
    };
    const getSingleConfigs = (mConfigName) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETSINGLECONFIGS + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&configName=${mConfigName}` + `&shopHash=${storeActiveShopHash.id}`;
        let data = {};
        //const urlFormattedString = encodeURIComponent(cUrl);
        //printLog("getSingleConfigs => ", cUrl);
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    if (mConfigName === BACKDATEALLOWED)
                        setBackDateAllowed(response.data === 'Yes');
                    else if (mConfigName === ITEMWISEOFFER)
                        setItemWiseOffer(response.data === 'Yes');
                    else if (mConfigName === DELETEBILLCONFIG)
                        setDeleteBillOption(response.data === 'Yes');
                    else if (mConfigName === PARTILBILLAUDIT)
                        setPartialBillAvail(response.data === 'Yes');
                    else if (mConfigName === CUSTLOYALITYPOINT)
                        setLoyaityPoints(response.data === 'Yes');
                    else if (mConfigName === HOTELSALESCONFIG)
                        setHotelSales(response.data === 'Yes');
                    else if (mConfigName === GENERATEBILL)
                        setGenBill(response.data === 'Yes');
                    else if (mConfigName === HOMEDELVDETAILS)
                        setHotelDelvTxn(response.data === 'Yes');
                    else if (mConfigName === KOTPRINTITEMWISE)
                        setKotPrintItemWise(response.data === 'Yes');
                    else if (mConfigName === KOTPRINTNOWISE)
                        setkotPrintNoWise(response.data === 'Yes');
                    else if (mConfigName === BILLPRINTWIDTH)
                        setBillPrintWidth(response.data);
                    /*else if (mConfigName === PRINTJARALLOWED)
                        setPrintJarAllowed(response.data === 'Yes');*/
                    else if (mConfigName === DELETEREASON)
                        setDelReason(response.data === 'Yes');
                    else if (mConfigName === GSTFEATURE) {
                        let featureVal = (response.data === 'No');
                        setGstFeature(featureVal);
                        if (isEmpty(COMPONENT_PARAMS.sBillNo)) {
                            setBillDetails({
                                ...billDetails, isGstAllowed: featureVal,
                            })
                        }
                    } else if (mConfigName === ASKFORPRINT)
                        setAskForPrint(response.data === 'Yes');
                }
            }).catch((error) => {
                //printLog("error : ", error);
                //window.location.href = "#/error";
            });
    };

    const createFilteredList = (e) => {
        let searchChar = e.target.value;
        if (isEmpty(searchChar)) {
            const newArray = catgProductData.map(a => ({ ...a }));//Because of Shallow Copy,I used this method to convert to deep Copy
            //console.log("Combo 2",newArray);
            setFilteredCatgProductData(newArray);
        } else {
            const filteredList = catgProductData.filter(item => item.name.toLowerCase().includes(searchChar.toLowerCase()));
            setFilteredCatgProductData(filteredList);
        }
    };

    const getProductDataByCatgId = async (catgId) => {
        if (!snpLoader)
            setSnpLoader(true);
        let catgUrl =
            storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_BILL_PRD_DATA;
        let data = {
            jcJson: {
                value: CART_INITIAL_STATE.apiToken,
                value_1: catgId,
                shopHash: storeActiveShopHash.id,
            },
        };
        //printLog("getProductDataByCatgId => ",data);
        snpAxios
            .post(catgUrl, data)
            .then((response) => {
                printDebugLog("getProductDataByCatgId => ", response.data);
                if (response.data.status === SUCCESS) {
                    setSnpLoader(false);
                    setGridNo(2);
                    setShowItemDialog(true);
                    setCatgProductData(response.data.jcJson.value_2);
                    const newArray = response.data.jcJson.value_2.map(a => ({ ...a }));//Because of Shallow Copy,I used this method to convert to deep Copy
                    const data = response.data.jcJson.value_2;
                    addDataIndb({ id: catgId, value: data });
                    setFilteredCatgProductData(newArray);
                } else {
                    setShowItemDialog(false);
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Home",
                        positiveAction: "Add Food Item",
                        positiveReq: true,
                        showDialog: true,
                        outSideClickDismiss: true,
                        cancelExtra: true,
                        requestType: DashboardComponent.PRODUCTENTRY,
                        subTitle: "No Food Item is available! Please add"
                    });
                    setSnpLoader(false);
                }
            })
            .catch((error) => {
                setShowItemDialog(false);
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                    negativeReq: true,
                    negativeAction: "Dismiss",
                    showDialog: true,
                    outSideClickDismiss: true,
                    cancelExtra: true,
                    requestType: 99,
                    handleSnpCancel: () => setSnpComponent(INITIAL_SNP_COMPONENT),
                    subTitle: "Internet Connection Lost"
                });
                setSnpLoader(false);
                //printLog("Error : ", error);
                //window.location.href = "#/error";
            });
    };


    const getProductDataByonEdit = async (opt) => {
        setSnpLoader(true);
        let catgUrl =
            storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETBILLPRODUCTBYPRDID;
        let data = {
            jcJson: {
                value: CART_INITIAL_STATE.apiToken,
                value_1: opt.prdId,
                isCombo: opt.combo,
            },
        };
        //printLog("getProductDataByonEdit => ",data);
        snpAxios
            .post(catgUrl, data)
            .then((response) => {
                setSnpLoader(false);
                //printLog("getProductDataByonEdit => ",response.data);
                if (response.data.status === SUCCESS) {
                    setSelectedProductData(oldArray => [...oldArray, response.data.jcJson]);
                    setItemDetailsOnEdit(opt, response.data.jcJson);
                }
            })
            .catch((error) => {
                setSnpLoader(false);
                printDebugLog("Error : ", error);
                //window.location.href = "#/error";
            });
    };


    const deleteFromBill = async (mPrdId) => {
        let error = false;
        if (delReason && isEmpty(delItemDialog.delReason)) {
            error = true;
            setSnpComponent({
                ...snpComponent, snackBarMsg: "Enter Reason for delete",
                snackBarOpen: true,
            })
        }
        if (!error && !isEmpty(listbillingData.billNo)) {
            var array = [...listbillingData.value_2];
            var index = array.findIndex(singleValue => singleValue.prdId === mPrdId);
            if (index !== -1) {
                //printLog("handleCellClick b", index, array);

                array.splice(index, 1);
                //printLog("handleCellClick ", listbillingData.value_2);
            }
            let deleteUrl = storeApiUrl.BACKEND_BASE_URL + DEL_ITEM_BILL;
            let data = {
                jcJson: {
                    value: CART_INITIAL_STATE.apiToken,
                    value_1: mPrdId,
                    shopHash: storeActiveShopHash.id,
                    value_2: listbillingData.billNo,
                    delMsg: delItemDialog.delReason,
                }
            };
            setSnpLoader(true);
            setDelItemDialog(INITIAL_DEL_ITEM);
            snpAxios.post(
                deleteUrl,
                data
            )
                .then((response) => {
                    //printLog("response  => ", response.data.jcJson);
                    setSnpLoader(false);
                    // if (response.data.status === SUCCESS) {
                    //     fetchBill(listbillingData.billNo,array);
                    // }
                    fetchBill(listbillingData.billNo, array);
                }).catch((error) => {
                    setSnpLoader(false);
                    //printLog("errr ", error);
                    //window.location.href = "#/error";
                });
        }
    };

    const checkRoom = () => {
        if (!isEmpty(roomDetails.roomNo)) {
            let mUrl = storeApiUrl.BACKEND_BASE_URL + SEARCHROOM;
            let data = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    roomNo: roomDetails.roomNo,
                    shopHash: storeActiveShopHash.id,
                }
            };
            setHotelDialogLoader(true);
            snpAxios.post(
                mUrl,
                data
            ).then((response) => {
                setHotelDialogLoader(false);
                if (response.data.status === SUCCESS) {
                    setRoomDetails({
                        ...roomDetails, ...response.data.jcJson,
                    });
                    if (response.data.jcJson.roomBooked) {
                        setBillingData({
                            ...listbillingData, hotelBillId: "",
                            hotelBillNo: "",
                        });
                    }

                }
            }).catch((error) => {
                setHotelDialogLoader(false);
                printDebugLog("errr ", error);
                //window.location.href = "#/error";
            });
        } else {
            setSnpComponent({
                ...snpComponent, snackBarMsg: "Plase Enter Room Details",
                snackBarOpen: true,
            })
        }
    }

    const removeRoomApi = () => {
        if (!isEmpty(roomDetails.roomNo)) {
            let mUrl = storeApiUrl.BACKEND_BASE_URL + DELROOM;
            let data = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    hotelBillId: listbillingData.hotelBillId,
                    billNo: listbillingData.billNo,
                    shopHash: storeActiveShopHash.id,
                }
            };
            setSnpLoader(true);
            snpAxios.post(
                mUrl,
                data
            ).then((response) => {
                setSnpLoader(false);
                if (response.data.status === SUCCESS) {
                    fetchBill(listbillingData.billNo, null);
                }
            }).catch((error) => {
                setHotelDialogLoader(false);
                printDebugLog("errr ", error);
                //window.location.href = "#/error";
            });
        } else {
            setSnpComponent({
                ...snpComponent, snackBarMsg: "Plase Enter Room Details before delete",
                snackBarOpen: true,
            })
        }
    }

    const fetchBill = async (mBill, mArray) => {
        if (!isEmpty(mBill)) {
            //setBillingData(INITIAL_LISTBILLINGDATA);
            let fetchBillUrl = storeApiUrl.BACKEND_BASE_URL + FETCH_BILL;
            let data = {
                jcJson: {
                    apiToken: CART_INITIAL_STATE.apiToken,
                    billNo: mBill,
                    shopHash: storeActiveShopHash.id,
                }
            };
            setSnpLoader(true);

            //printLog("fetchBill  => ", data);
            snpAxios.post(
                fetchBillUrl,
                data
            )
                .then((response) => {
                    printDebugLog("fetchBill response  => ", response.data);
                    if (response.data.status === SUCCESS) {
                        // if (mArray == null) {
                        //     setBillingData({
                        //         ...listbillingData, ...response.data.jcJson,
                        //     });    
                        // } else {
                        //     setBillingData({
                        //         ...listbillingData, ...response.data.jcJson,
                        //         value_2:mArray,
                        //     });
                        // }
                        setBillingData({
                            ...listbillingData, ...response.data.jcJson,
                            value_2: response.data.jcJson.value_2,
                            pTripStatus: response.data.jcJson.tripStatus,
                        });
                        if (!isEmpty(response.data.jcJson.riderId))
                            setRiderDetails({
                                ...riderDetails, id: response.data.jcJson.riderId, name: response.data.jcJson.riderIdName,
                            })
                        if (response.data.jcJson.tripUpdateReq) {
                            getRiderDetails();
                        }
                        setBillUtils({
                            ...billUtils,
                            tableId: response.data.jcJson.tableId,
                            tableNo: response.data.jcJson.tableNo,
                            billNo: response.data.jcJson.billNo,
                            tableName: response.data.jcJson.tableName,
                            billingType: response.data.jcJson.billType,
                        });
                        if (!isEmpty(response.data.jcJson.hotelBillId)) {
                            setRoomDetails({
                                ...roomDetails, roomNo: response.data.jcJson.hotelBillNo,
                                roomBooked: true, hotelBillId: response.data.jcJson.hotelBillId,
                                saved: true,
                            })
                        }
                        setBillDetails({
                            ...billDetails,
                            subTotal: response.data.jcJson.totalPrice,
                            cgst: response.data.jcJson.cgst,
                            cgstAmt: response.data.jcJson.cgstAmt,
                            sgstAmt: response.data.jcJson.sgstAmt,
                            sgst: response.data.jcJson.sgst,
                            isGstAllowed: response.data.jcJson.isGstAllowed,
                            roundOff: response.data.jcJson.roundOff,
                            pointRedeem: response.data.jcJson.pointRedeem,
                            isPointAdded: parseFloat(response.data.jcJson.pointRedeem) > 0.0,
                            total: response.data.jcJson.finalPrice,
                            lapsedTime: response.data.jcJson.lapsedTime,
                            billingType: response.data.jcJson.billType,
                            lpatialAmt: response.data.jcJson.lpatialAmt,
                            pendingAmt: response.data.jcJson.duesAmt,
                            token: response.data.jcJson.tokenNo,
                            offerDenom: response.data.jcJson.takenDenom,
                            offerAmt: response.data.jcJson.takenOffrAmt,
                        });
                        setPaymentDetails({
                            ...paymentDetails,
                            cashTender: response.data.jcJson.cashTender,
                            cashReturn: response.data.jcJson.cashReturn,
                            onlinePay: response.data.jcJson.onlinePay,
                            cashPay: response.data.jcJson.cashPay,
                            cashPayThrough: response.data.jcJson.cashPayThrough,
                            onlinePayMedium: response.data.jcJson.onlinePayMedium,
                            onlinePayThrough: response.data.jcJson.onlinePayThrough,
                            delvName: response.data.jcJson.delvName,
                            payMedium: response.data.jcJson.payMedium,
                        });

                        setDelvCharge(response.data.jcJson.delvCharge);
                        setDelvNameOth(response.data.jcJson.delvName);

                        setCustomerInputDialog({
                            ...customerInputDialog,
                            custInputopen: false,
                            custInputAdd: (!isEmpty(response.data.jcJson.custId) || !isEmpty(response.data.jcJson.custMob)),
                            redeemRoyaltyPrice: parseFloat(response.data.jcJson.royalityPoint) > 0.0,
                        });
                        setIsCustDataAvailable(isEmpty(response.data.jcJson.custId));
                        if (response.data.jcJson.billStatus === BillStatus.PAID
                            || response.data.jcJson.billStatus === BillStatus.PARTIALLYPAID
                            || response.data.jcJson.billStatus === BillStatus.HOMEDELIVERYINITIATED
                            || response.data.jcJson.billStatus === BillStatus.HOMEDELIVERYCASHPAY
                            || response.data.jcJson.billStatus === BillStatus.HOMEDELIVERYPAY) {
                            setIsPaid(true);
                        }
                        setOnlinePayEnb(parseFloat(response.data.jcJson.onlinePay) > 0);
                        setCashPayEnb(response.data.jcJson.cashPayThrough === CASHPAY);
                        setSnpLoader(false);
                    } else {
                        setSnpComponent({
                            ...snpComponent,
                            showDialog: true,
                            title: "",
                            iconReq: true,
                            isLoader: false,
                            negativeReq: true,
                            cancelExtra: true,
                            requestType: DashboardComponent.BILLDETAILS,
                            outSideClickDismiss: false,
                            subTitle: "You Are not allowed to Change In Bills.Contact the Owner",
                            negativeAction: "Got It!",
                        });
                        setSnpLoader(false);
                    }
                }).catch((error) => {
                    setSnpLoader(false);
                    printDebugLog("errr ", error);
                    setSnpComponent({
                        ...snpComponent,
                        showDialog: true,
                        title: "",
                        iconReq: true,
                        isLoader: false,
                        negativeReq: true,
                        cancelExtra: true,
                        requestType: DashboardComponent.BILLDETAILS,
                        outSideClickDismiss: false,
                        subTitle: "You Are not allowed to Change In this Bills.Contact the Owner",
                        negativeAction: "Got It!",
                    });
                    //window.location.href = "#/error";
                });
        } else {
            //printLog("Empty Bill No");
        }
    };
    const getCustomerData = (e) => {
        let custgUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GET_CUSTOMER_DETAIS;

        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                custMob: e.target.value
            }
        };

        if (e.target.value.length === 10) {
            setIsCustLoading(true);
            axios.post(
                custgUrl,
                data
            ).then(response => {
                if (response.data.status !== SUCCESS) {
                    setBillingData({ ...listbillingData, custId: "", custMob: e.target.value, custName: "", custEmail: "", address: "" });
                    throw new Error('Customer Data Not Found!!');
                }
                let jcJson = response.data.jcJson;
                // //printLog("getCustomer",jcJson);
                setBillingData({ ...listbillingData, ...jcJson, pointRedeem: jcJson.royalityPoint })
                setCustomerInputDialog({ ...customerInputDialog, custInputAdd: true, redeemRoyaltyPrice: jcJson.royalityPoint > 0 })
                setIsCustDataAvailable(false);
                e.target.value = "";
            }).catch(error => {
                e.target.value = "";
                setIsCustDataAvailable(true);
                //printLog(error);
            }).finally(() => {
                setIsCustLoading(false);
            })

        }
        else {
            setBillingData({ ...listbillingData, custId: "", custMob: "", custName: "", custEmail: "", royalityPoint: "", address: "", });
            setCustomerInputDialog({ ...customerInputDialog, custInputAdd: false, redeemRoyaltyPrice: false, getRoyaltyDiscount: false })
            setIsCustDataAvailable(false);
        }
    }

    const kotprint = async (isSave) => {
        let isError = false;
        if (listbillingData.value_2.length === 0) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Add Item to Start billing",
                snackBarOpen: true,
            });
        }
        if (!isEmpty(listbillingData.hotelBillId) && isEmpty(listbillingData.custMob)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Add Customer Details",
                snackBarOpen: true,
            })
        }
        let bStatus = listbillingData.billStatus;
        if (bStatus === BillStatus.NO_ENTRY)
            bStatus = BillStatus.INITIATED;
        var today = new Date();
        var time = " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if (!isError) {
            setSnpLoader(true);
            let settleUrl = storeApiUrl.BACKEND_BASE_URL + (isSave ? INITIATESAVEBILL : RESTO_KOT);
            let mOnlinePayEnb = (Number(paymentDetails.onlinePay) !== 0.0);
            let mCashPayEnb = (Number(paymentDetails.cashPay) !== 0.0);
            let jcJson = {
                value: CART_INITIAL_STATE.apiToken,
                value_2: listbillingData.value_2,
                shopHash: storeActiveShopHash.id,
                billNo: listbillingData.billNo,
                tableId: billUtils.tableId,
                isMobile: isMobile,
                billType: parseInt(billUtils.billingType),
                payMedium: getPayMedium(mCashPayEnb, mOnlinePayEnb),
                onlinePayThrough: !mOnlinePayEnb ? "" : paymentDetails.onlinePayThrough,
                onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
                cashPayThrough: !mCashPayEnb ? "" : paymentDetails.cashPayThrough,
                onlinePay: Number(paymentDetails.onlinePay),
                cashPay: Number(paymentDetails.cashPay),
                cashTender: mCashPayEnb ? Number(paymentDetails.cashTender) : 0.0,
                cashReturn: mCashPayEnb ? Number(paymentDetails.cashReturn) : 0.0,
                roundOff: parseFloat(billDetails.roundOff),
                cgst: parseFloat(billDetails.cgst),
                sgst: parseFloat(billDetails.sgst),
                cgstAmt: parseFloat(billDetails.cgstAmt),
                sgstAmt: parseFloat(billDetails.sgstAmt),
                isGstAllowed: billDetails.isGstAllowed,
                subTotal: parseFloat(billDetails.subTotal),
                total: parseFloat(billDetails.total),
                takenDenom: billDetails.offerDenom,
                takenOffrAmt: billDetails.offerAmt,
                delvName: delvNameOth,
                delvCharge: delvCharge,
                custId: listbillingData.custId,
                custMob: listbillingData.custMob,
                custEmail: listbillingData.custEmail,
                address: listbillingData.address,
                custName: listbillingData.custName,
                custGstDetails: listbillingData.custGstDetails,
                custCompanyName: listbillingData.custCompanyName,
                createdAt: listbillingData.createdAt + time,
                hotelBillId: listbillingData.hotelBillId,
                hotelBillNo: listbillingData.hotelBillNo,
                homeDelvId: listbillingData.homeDelvId,
                homeDelvOtp: listbillingData.homeDelvOtp,
                billStatus: bStatus,
                tripStatus: isEmpty(listbillingData.tripStatus) ? SEND_TO_KITCHEN : listbillingData.tripStatus,
                riderId: listbillingData.riderId,
            };
            let settleData = { jcJson };
            //printLog("kotprint => ", settleData);
            snpAxios.post(settleUrl, settleData)
                .then(response => {
                    //printLog("responses => ", response.data);
                    setSnpLoader(false);
                    if (response.data.status === SUCCESS) {
                        setBillingData({
                            ...listbillingData,
                            billNo: response.data.jcJson.billNo,
                            billStatus: BillStatus.INITIATED,
                            token: response.data.jcJson.token,
                        });

                        raiseInvoiceClicked(response.data.jcJson.print, "kot@" + response.data.jcJson.billNo);
                        fetchBill(response.data.jcJson.billNo, null);
                    }
                }).catch(error => {
                    //printLog("error : ", error);
                    setSnpLoader(false);
                    //window.location.href = "#/error";
                });
        }
    };

    const updatePartial = () => {
        let isError = false;
        if (listbillingData.billStatus === BillStatus.INITIATED) {
            setClearDues(false);
            setShowItemDialog(false);
            initiatePayment(true);
        } else {
            if (billUtils.billingType !== InternalBillType.Home && isEmpty(listbillingData.custMob)) {
                isError = true;
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Please Add Customer Details",
                    snackBarOpen: true,
                })
            }
            if (isEmpty(partialPay.onlineBillPaidThrough) && isEmpty(partialPay.cashBillPaidThrough)) {
                isError = true;
                //printLog()
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Please Select any of the Payment method",
                    snackBarOpen: true,
                })
            }
            if ((!isEmpty(partialPay.cashBillPaidThrough)) && isNumberEmpty(parseFloat(partialPay.cashTendered))) {
                isError = true;
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Please Input the Cash Tender Amount",
                    snackBarOpen: true,
                })
            }
            if (isNumberEmpty(parseFloat(partialPay.partialAmt))) {
                isError = true;
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "Please Add Partial Amount",
                    snackBarOpen: true,
                })
            }
            if (isError)
                return;
            setSnpLoader(true);
            let custSaveUrl = storeApiUrl.BACKEND_BASE_URL + UPDATEPARTIALPAY;
            let jcJson = {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                partialBillAudit: partialPay,
                billNo: listbillingData.billNo,
                custId: listbillingData.custId,
                custMob: listbillingData.custMob,
                custEmail: listbillingData.custEmail,
                address: listbillingData.address,
                custName: listbillingData.custName,
                custGstDetails: listbillingData.custGstDetails,
                custCompanyName: listbillingData.custCompanyName,
            };

            let saveBillData = { jcJson };
            //printLog("updatePartial", saveBillData);
            handleDialogClose();
            snpAxios.post(custSaveUrl, saveBillData)
                .then(response => {
                    //printLog("updatePartial => ", response.data);
                    setSnpLoader(false);
                    if (response.data.status === SUCCESS) {
                        setPrintDetails({
                            ...printDetails,
                            shopPrint: response.data.jcJson.shopprint,
                            custPrint: response.data.jcJson.custprint
                        });

                        if (!response.data.jcJson.custprint.includes('Successfully Print'))
                            setCustCopy(true);
                        else
                            raiseInvoiceClicked(response.data.jcJson.custprint, "cust");
                        fetchBill(response.data.jcJson.billNo, null);
                    }
                }).catch(error => {
                    //printLog("error : ", error);
                    setSnpLoader(false);
                    //window.location.href = "#/error";
                });
        }

    }

    const handlePayLater = () => {
        let isError = false;
        if (isEmpty(listbillingData.custMob)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Add Customer Details",
                snackBarOpen: true,
            })
        }

        var today = new Date();
        var time = " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if (!isError) {
            setSnpLoader(true);
            let custSaveUrl = storeApiUrl.BACKEND_BASE_URL + PAYLATERRESTOBILL;
            //printLog("data", listbillingData);
            let jcJson = {
                value: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                value_2: listbillingData.value_2,
                billNo: listbillingData.billNo,
                tableId: billUtils.tableId,
                custId: listbillingData.custId,
                custMob: listbillingData.custMob,
                custEmail: listbillingData.custEmail,
                address: listbillingData.address,
                custName: listbillingData.custName,
                custGstDetails: listbillingData.custGstDetails,
                custCompanyName: listbillingData.custCompanyName,
                isMobile: isMobile,
                cartType: storeActiveShopHash.cartType,
                roundOff: parseFloat(billDetails.roundOff),
                cgst: parseFloat(billDetails.cgst),
                sgst: parseFloat(billDetails.sgst),
                cgstAmt: parseFloat(billDetails.cgstAmt),
                sgstAmt: parseFloat(billDetails.sgstAmt),
                isGstAllowed: billDetails.isGstAllowed,
                billType: parseInt(billUtils.billingType),
                subTotal: parseFloat(billDetails.subTotal),
                total: parseFloat(billDetails.total),
                pointRedeem: billDetails.isPointAdded ? parseFloat(billDetails.pointRedeem) : 0.0,
                createdAt: listbillingData.createdAt + time,
                billStatus: BillStatus.NOTPAID,
                takenDenom: billDetails.offerDenom,
                takenOffrAmt: billDetails.offerAmt,
                settleDown: false,
                hotelBillId: listbillingData.hotelBillId,
                hotelBillNo: listbillingData.hotelBillNo,
                homeDelvId: listbillingData.homeDelvId,
                homeDelvOtp: listbillingData.homeDelvOtp,
            };


            let saveBillData = { jcJson };


            delete saveBillData.jcJson.royalityPoint;

            //printLog("initiatePayment", saveBillData);
            snpAxios.post(custSaveUrl, saveBillData)
                .then(response => {
                    //printLog("responses => ", response.data);
                    setSnpLoader(false);
                    if (response.data.status === SUCCESS) {
                        setBillingData({
                            ...listbillingData,
                            billNo: response.data.jcJson.billNo,
                            billStatus: BillStatus.PAID,
                            token: response.data.jcJson.token,
                        });
                        setPrintDetails({
                            ...printDetails,
                            shopPrint: response.data.jcJson.shopprint,
                            custPrint: response.data.jcJson.custprint
                        });
                        //raiseInvoiceClicked(response.data.jcJson.shopprint);
                        setCustCopy(true);
                        //fetchBill(response.data.jcJson.billNo);
                    }
                }).catch(error => {
                    //printLog("error : ", error);
                    setSnpLoader(false);
                    //window.location.href = "#/error";
                });
        }
    };

    const funAskForPrint = (payLoad) => {
        setPrintDetails({
            ...printDetails,
            shopPrint: payLoad.shopprint,
            custPrint: payLoad.custprint
        });
        setCustCopy(true);
    };

    const initiatePayment = (isPartial) => {
        let isError = false;
        if (!isPartial && (isEmpty(paymentDetails.onlinePayThrough) && isEmpty(paymentDetails.cashPayThrough))) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Select any of the Payment method",
                snackBarOpen: true,
            })
        }
        if (!isPartial && isOnlinePayEnabled && isEmpty(paymentDetails.onlinePayMedium)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Select Online Pay Method",
                snackBarOpen: true,
            })
        }
        // if (!isPartial && (!isEmpty(paymentDetails.cashPayThrough)) && isNumberEmpty(parseFloat(paymentDetails.cashTender))) {
        //     isError = true;
        //     setSnpComponent({
        //         ...snpComponent,
        //         snackBarMsg: "Please Input the Cash Tender Amount",
        //         snackBarOpen: true,
        //     })
        // }
        if (billUtils.billingType !== InternalBillType.Home && isEmpty(listbillingData.custMob)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Add Customer Details",
                snackBarOpen: true,
            })
        }
        if (billUtils.billingType === InternalBillType.Home && isEmpty(delvNameOth)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Select Home Delivery",
                snackBarOpen: true,
            })
        }
        var today = new Date();
        var time = " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        if (!isError) {
            setSnpLoader(true);
            let custSaveUrl = storeApiUrl.BACKEND_BASE_URL + POSTRESTOBILL;
            //printLog("data", listbillingData);
            let mOnlinePayEnb = (Number(paymentDetails.onlinePay) !== 0.0);
            let mCashPayEnb = (Number(paymentDetails.cashPay) !== 0.0);
            let jcJson = {
                value: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                value_2: listbillingData.value_2,
                billNo: listbillingData.billNo,
                tableId: billUtils.tableId,
                custId: listbillingData.custId,
                custMob: listbillingData.custMob,
                custEmail: listbillingData.custEmail,
                address: listbillingData.address,
                custName: listbillingData.custName,
                custGstDetails: listbillingData.custGstDetails,
                custCompanyName: listbillingData.custCompanyName,
                cartType: storeActiveShopHash.cartType,
                payMedium: getPayMedium(mCashPayEnb, mOnlinePayEnb),
                onlinePayThrough: !mOnlinePayEnb ? "" : paymentDetails.onlinePayThrough,
                onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
                cashPayThrough: !mCashPayEnb ? "" : paymentDetails.cashPayThrough,
                onlinePay: parseFloat(paymentDetails.onlinePay),
                cashPay: parseFloat(paymentDetails.cashPay),
                cashTender: mCashPayEnb ? parseFloat(paymentDetails.cashTender) : 0.0,
                cashReturn: mCashPayEnb ? parseFloat(paymentDetails.cashReturn) : 0.0,
                roundOff: parseFloat(billDetails.roundOff),
                cgst: parseFloat(billDetails.cgst),
                sgst: parseFloat(billDetails.sgst),
                cgstAmt: parseFloat(billDetails.cgstAmt),
                sgstAmt: parseFloat(billDetails.sgstAmt),
                isGstAllowed: billDetails.isGstAllowed,
                partialBillAudit: partialPay,
                billType: parseInt(billUtils.billingType),
                subTotal: parseFloat(billDetails.subTotal),
                total: parseFloat(billDetails.total),
                pointRedeem: billDetails.isPointAdded ? parseFloat(billDetails.pointRedeem) : 0.0,
                createdAt: listbillingData.createdAt + time,
                billStatus: isPartial ? BillStatus.PARTIALLYPAID : BillStatus.PAID,
                isGenerateBill: false,
                takenDenom: billDetails.offerDenom,
                takenOffrAmt: billDetails.offerAmt,
                settleDown: (billUtils.billingType === InternalBillType.BookTable) ? true : false,
                delvName: delvNameOth,
                isMobile: isMobile,
                delvCharge: delvCharge,
                hotelBillId: listbillingData.hotelBillId,
                hotelBillNo: listbillingData.hotelBillNo,
                homeDelvId: listbillingData.homeDelvId,
                homeDelvOtp: listbillingData.homeDelvOtp,
            };


            let saveBillData = { jcJson };


            delete saveBillData.jcJson.royalityPoint;

            //printLog("initiatePayment", saveBillData);
            snpAxios.post(custSaveUrl, saveBillData)
                .then(response => {
                    //printLog("responses => ", response.data);
                    setSnpLoader(false);
                    if (response.data.status === SUCCESS) {
                        setBillingData({
                            ...listbillingData,
                            billNo: response.data.jcJson.billNo,
                            billStatus: BillStatus.PAID,
                            token: response.data.jcJson.token,
                        });

                        if (askForPrint)
                            funAskForPrint(response.data.jcJson);
                        else
                            raiseInvoiceClicked(response.data.jcJson.custprint, "cust");

                        //raiseInvoiceClicked(response.data.jcJson.shopprint);
                        //setCustCopy(true);
                        fetchBill(response.data.jcJson.billNo);
                    }
                }).catch(error => {
                    //printLog("error : ", error);
                    setSnpLoader(false);
                    //window.location.href = "#/error";
                });
        }
    }

    const finalSettle = () => {
        let isError = false;
        //printLog("finalSettle ",listbillingData);
        if (listbillingData.billStatus === BillStatus.INITIATED) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                isLoader: false,
                negativeReq: true,
                negativeAction: "Ok!",
                positiveReq: false,
                showDialog: true,
                outSideClickDismiss: true,
                cancelExtra: true,
                requestType: 1,
                subTitle: "First Initiate Pay to Settle the bill"
            });
            setSnpLoader(false);
        }
        if (isError)
            return;
        let custSaveUrl = storeApiUrl.BACKEND_BASE_URL + SETTLE_RESTO_BILL;
        setSnpLoader(true);
        let jcJson = {
            value: CART_INITIAL_STATE.apiToken,
            shopHash: storeActiveShopHash.id,
            billNo: listbillingData.billNo,
            settleDown: true,
            hotelBillId: listbillingData.hotelBillId,
            hotelBillNo: listbillingData.hotelBillNo,
            homeDelvId: listbillingData.homeDelvId,
            homeDelvOtp: listbillingData.homeDelvOtp,
        };
        let saveBillData = { jcJson };


        delete saveBillData.jcJson.royalityPoint;

        //printLog("finalSettle", saveBillData);
        snpAxios.post(custSaveUrl, saveBillData)
            .then(response => {
                //printLog("responses => ", response.data);
                setSnpLoader(false);
                if (response.data.status === SUCCESS) {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Ok!",
                        positiveReq: false,
                        showDialog: true,
                        outSideClickDismiss: true,
                        cancelExtra: true,
                        requestType: DashboardComponent.BILLDETAILS,
                        subTitle: "Bill Successfully Settled"
                    });
                }
            }).catch(error => {
                //printLog("error : ", error);
                setSnpLoader(false);
                //window.location.href = "#/error";
            });
    }

    const generateBill = () => {
        let error = false;
        if (!isEmpty(listbillingData.generateOtp) && genBill) {
            if (isEmpty(genOtpDialog.otp) || genOtpDialog.otp !== listbillingData.generateOtp)
                error = true;
        }
        if (!error) {
            let custSaveUrl = storeApiUrl.BACKEND_BASE_URL + POSTRESTOBILL;
            setSnpLoader(true);
            var today = new Date();
            var time = " " + today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            // //printLog("data", listbillingData);
            let mOnlinePayEnb = (Number(paymentDetails.onlinePay) !== 0.0);
            let mCashPayEnb = (Number(paymentDetails.cashPay) !== 0.0);
            let jcJson = {
                value: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                value_2: listbillingData.value_2,
                billNo: listbillingData.billNo,
                tableId: billUtils.tableId,
                custId: listbillingData.custId,
                custMob: listbillingData.custMob,
                custEmail: listbillingData.custEmail,
                address: listbillingData.address,
                custName: listbillingData.custName,
                custGstDetails: listbillingData.custGstDetails,
                custCompanyName: listbillingData.custCompanyName,
                cartType: storeActiveShopHash.cartType,
                payMedium: getPayMedium(mCashPayEnb, mOnlinePayEnb),
                onlinePayThrough: !mOnlinePayEnb ? "" : paymentDetails.onlinePayThrough,
                onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
                cashPayThrough: !mCashPayEnb ? "" : paymentDetails.cashPayThrough,
                onlinePay: parseFloat(paymentDetails.onlinePay),
                cashPay: parseFloat(paymentDetails.cashPay),
                cashTender: mCashPayEnb ? parseFloat(paymentDetails.cashTender) : 0.0,
                cashReturn: mCashPayEnb ? parseFloat(paymentDetails.cashReturn) : 0.0,
                roundOff: parseFloat(billDetails.roundOff),
                cgst: parseFloat(billDetails.cgst),
                sgst: parseFloat(billDetails.sgst),
                cgstAmt: parseFloat(billDetails.cgstAmt),
                sgstAmt: parseFloat(billDetails.sgstAmt),
                isGstAllowed: billDetails.isGstAllowed,
                partialBillAudit: partialPay,
                isMobile: isMobile,
                billType: parseInt(billUtils.billingType),
                subTotal: parseFloat(billDetails.subTotal),
                total: parseFloat(billDetails.total),
                pointRedeem: billDetails.isPointAdded ? parseFloat(billDetails.pointRedeem) : 0.0,
                createdAt: listbillingData.createdAt + time,
                billStatus: BillStatus.INITIATED,
                takenDenom: billDetails.offerDenom,
                takenOffrAmt: billDetails.offerAmt,
                settleDown: (billUtils.billingType === InternalBillType.BookTable) ? true : false,
                delvName: delvNameOth,
                delvCharge: delvCharge,
                isGenerateBill: true,
                hotelBillId: listbillingData.hotelBillId,
                hotelBillNo: listbillingData.hotelBillNo,
                homeDelvId: listbillingData.homeDelvId,
                homeDelvOtp: listbillingData.homeDelvOtp,
            };


            let saveBillData = { jcJson };


            delete saveBillData.jcJson.royalityPoint;
            setGenOtpDialog(INITIAL_GENERATE_DIALOG);
            //printLog("generateBill", saveBillData);
            snpAxios.post(custSaveUrl, saveBillData)
                .then(response => {
                    //printLog("responses => ", response.data);
                    setSnpLoader(false);
                    if (response.data.status === SUCCESS) {
                        setBillingData({
                            ...listbillingData,
                            billNo: response.data.jcJson.billNo,
                            billStatus: BillStatus.INITIATED,
                            token: response.data.jcJson.token,
                        });
                        raiseInvoiceClicked(response.data.jcJson.custprint, "gen");

                        fetchBill(response.data.jcJson.billNo, null);
                    }
                }).catch(error => {
                    //printLog("error : ", error);
                    setSnpLoader(false);
                    //window.location.href = "#/error";
                });
        } else {
            setGenOtpDialog(INITIAL_GENERATE_DIALOG);
            setSnpComponent({
                ...snpComponent,
                snackBarOpen: true,
                snackBarMsg: "Entered OTP is wrong"
            })
        }
    }

    const printBillAfterPay = (e) => {
        //printLog("printBillAfterPay",e.target.id);
        setSnpLoader(true);
        let printFor = e.target.id === 'shopbill' ? 1 : 0;
        let catgUrl = "";
        if (isMobile)
            catgUrl = storeApiUrl.BACKEND_BASE_URL + PRINT_RESTO_BILL_MOBILE + "/" + CART_INITIAL_STATE.apiToken + "/" + listbillingData.billNo + "/" + storeActiveShopHash.id + "/" + printFor;
        else
            catgUrl = storeApiUrl.BACKEND_BASE_URL + PRINT_RESTO_BILL + "/" + CART_INITIAL_STATE.apiToken + "/" + listbillingData.billNo + "/" + storeActiveShopHash.id + "/" + printFor;
        let data = {};
        //printLog("printBillAfterPay => ", catgUrl);
        axios.get(
            catgUrl,
            data
        )
            .then((response) => {
                //printLog(response.data);
                setSnpLoader(false);
                if (response.data !== '')
                    raiseInvoiceClicked(response.data, printFor === 1 ? "shop" : "cust");
            }).catch((error) => {
                printDebugLog("error : ", error);
                setSnpLoader(false);
                //printLog("error : ", error);
                //window.location.href = "#/error";
            });
    }

    const deleteEntireBill = () => {
        let dUrl = storeApiUrl.BACKEND_BASE_URL + DELETEBILL + "/" + CART_INITIAL_STATE.apiToken + "/" + listbillingData.billNo + "/" + storeActiveShopHash.id;
        let data = {};
        setSnpLoader(true);
        //printLog("deleteEntireBill => ", dUrl);
        axios.get(
            dUrl,
            data
        )
            .then((response) => {
                //printLog("deleteEntireBill<=",response.data);
                if (response.data === SUCCESS) {
                    setSnpLoader(false);
                    setComponentIndex(DashboardComponent.BILLDETAILS);
                } else if (response.data === ROLEERROR) {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Ok!",
                        positiveReq: false,
                        showDialog: true,
                        outSideClickDismiss: true,
                        cancelExtra: true,
                        requestType: 1,
                        subTitle: "You're not allowed to Delete Bill! Contact Shop Admin",
                    });
                    setSnpLoader(false);
                } else {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Logout!",
                        positiveReq: false,
                        showDialog: true,
                        outSideClickDismiss: false,
                        cancelExtra: true,
                        requestType: DashboardComponent.LOGOUT,
                        subTitle: "Invalid User",
                    });
                    setSnpLoader(false);
                }
            }).catch((error) => {
                setSnpLoader(false);
                //printLog("error : ", error);
                //window.location.href = "#/error";
            });
    };

    const buildKotDisplayData = (prodName, kots) => {
        return (
            <>
                {!isEmpty(kots) &&
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" className="rate_card">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Kot No.</TableCell>
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">Quan.</TableCell>
                                    <TableCell align="center">On</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.values(kots).map((singleKot) => {
                                    return (
                                        <TableRow
                                            className="rate_card--row"
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center">{singleKot.count}</TableCell>
                                            <TableCell align="center"><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{prodName}</p></TableCell>
                                            <TableCell align="center">{(singleKot.positive ? "+" : "-") + singleKot.quantity}</TableCell>
                                            <TableCell align="center"><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{formatDateinStyled(singleKot.createdOn)}</p></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </>
        );
    };

    const buildDelItemDisplayData = (delItems) => {
        return (
            <>
                {!isEmpty(delItems) &&
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12} lg={12} sm={12}>
                            <Typography variant="p" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>
                                Previously Deleted Items
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12} lg={12} sm={12}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table" className="rate_card">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">Item</TableCell>
                                            <TableCell align="center">Qty</TableCell>
                                            <TableCell align="center">Reason</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.values(delItems).map((singleItems) => {
                                            return (
                                                <TableRow
                                                    className="rate_card--row"
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell align="center"><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{singleItems.name + " " + (!isEmpty(singleItems.foodQuantityName) ? "(" + singleItems.foodQuantityName + ")" : "")}</p></TableCell>
                                                    <TableCell align="center">{singleItems.quantity}</TableCell>
                                                    <TableCell align="center"><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{singleItems.delReason}</p></TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer></Grid></Grid>
                }
            </>
        );
    };


    const printKotItmeWise = (prdId) => {
        //printLog("printKotItmeWise",e.target.id);
        setSnpLoader(true);
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + PRINT_RESTO_KOT_ITEM + "/" + CART_INITIAL_STATE.apiToken + "/" + listbillingData.billNo + "/" + storeActiveShopHash.id + "/" + prdId;
        let data = {};
        //printLog("printKotItmeWise => ", catgUrl);
        axios.get(
            catgUrl,
            data
        )
            .then((response) => {
                //printLog(response.data);
                setSnpLoader(false);
                if (response.data !== '') {
                    raiseInvoiceClicked(response.data, "def");
                }
            }).catch((error) => {
                printDebugLog("error : ", error);
                setSnpLoader(false);
                //printLog("error : ", error);
                //window.location.href = "#/error";
            });
    }

    const printKotNoWise = (no) => {
        //printLog("printKotNoWise",e.target.id);
        let isError = false;
        if (isNumberEmpty(no)) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please enter Kot number",
                snackBarOpen: true,
            });
        } else if (Number(no) === 0) {
            isError = true;
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please enter valid kot number(i.e. 1 to ....)",
                snackBarOpen: true,
            });
        }
        if (!isError) {
            setSnpLoader(true);
            setPrintKotNoDialog(false);
            let catgUrl = storeApiUrl.BACKEND_BASE_URL + PRINT_RESTO_KOT_NO + "/" + CART_INITIAL_STATE.apiToken + "/" + listbillingData.billNo + "/" + storeActiveShopHash.id + "/" + no;
            let data = {};
            //printLog("printKotItmeWise => ", catgUrl);
            axios.get(
                catgUrl,
                data
            )
                .then((response) => {
                    //printLog(response.data);
                    setSnpLoader(false);
                    setKotNo('');
                    if (response.data !== '' && !response.data.includes("Error@")) {
                        raiseInvoiceClicked(response.data, "def");
                    } else if (response.data !== '' && response.data.includes("Error@")) {
                        setSnpComponent({
                            ...snpComponent,
                            snackBarMsg: response.data,
                            snackBarOpen: true,
                        });
                    }
                }).catch((error) => {
                    printDebugLog("error : ", error);
                    setSnpLoader(false);
                    setKotNo('');
                    //printLog("error : ", error);
                    //window.location.href = "#/error";
                });
        }
    }


    const kotDisplay = (prdId) => {
        let dUrl = storeApiUrl.BACKEND_BASE_URL + GETKOTBYITEM + "/" + CART_INITIAL_STATE.apiToken + "/" + listbillingData.billNo + "/" + storeActiveShopHash.id + "/" + prdId;
        let data = {};
        setSnpLoader(true);
        axios.get(
            dUrl,
            data
        )
            .then((response) => {
                //printLog("kotDisplay<=",response.data);
                setSnpLoader(false);
                if (response.data.status === SUCCESS) {
                    let kots = response.data.jcJson.kots;
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Dismiss",
                        positiveReq: kotPrintItemWise,
                        positiveAction: "Print",
                        showDialog: true,
                        iconContent: <SoupKitchenIcon />,
                        outSideClickDismiss: true,
                        cancelExtra: true,
                        requestType: 99,
                        handleSnpCancel: () => setSnpComponent(INITIAL_SNP_COMPONENT),
                        handleSnpSubmit: () => { printKotItmeWise(prdId); setSnpComponent(INITIAL_SNP_COMPONENT); },
                        gui: buildKotDisplayData(response.data.jcJson.prdName, kots),
                    });
                } else if (response.data.status === FAILED) {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                        negativeReq: true,
                        negativeAction: "Ok!",
                        positiveReq: false,
                        showDialog: true,
                        outSideClickDismiss: true,
                        cancelExtra: true,
                        requestType: 2,
                        subTitle: "Still Bill not reached to KOT state!",
                    });
                }
            }).catch((error) => {
                setSnpLoader(false);
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                    negativeReq: true,
                    negativeAction: "Ok!",
                    positiveReq: false,
                    showDialog: true,
                    outSideClickDismiss: true,
                    cancelExtra: true,
                    requestType: 2,
                    subTitle: "Still Bill not reached to KOT state!",
                });
                printDebugLog("error : ", error);
                //window.location.href = "#/error";
            });
    }
    //Network Corner end

    //Function Corner
    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        //var currentTime = +new Date();
        printDebugLog("useEffect<=");
        const handleMediaQueryChange = (event) => {
            setIsMobile(event.matches);
        };

        mediaQuery.addEventListener('change', handleMediaQueryChange);

        return () => {
            mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };
    }, []);
    useLayoutEffect(() => {
        printDebugLog("useLayoutEffect ", listbillingData);
        if (COMPONENT_PARAMS.tableProps !== null) {
            setBillUtils({
                ...billUtils,
                tableId: COMPONENT_PARAMS.tableProps.id,
                tableNo: COMPONENT_PARAMS.tableProps.tableNo,
                billNo: COMPONENT_PARAMS.tableProps.billNo,
                tableName: COMPONENT_PARAMS.tableProps.tableName,
                billingType: (COMPONENT_PARAMS.type !== -1) ? COMPONENT_PARAMS.type : InternalBillType.TakeAway,
            });
        }
        if (isEmpty(roomDetails.roomNo))
            getSingleConfigs(PARTILBILLAUDIT);
        getSingleConfigs(GSTFEATURE);
        getSingleConfigs(HOTELSALESCONFIG);
        getSingleConfigs(BACKDATEALLOWED);
        getSingleConfigs(ITEMWISEOFFER);
        getSingleConfigs(DELETEBILLCONFIG);
        getSingleConfigs(CUSTLOYALITYPOINT);
        getSingleConfigs(GENERATEBILL);
        getSingleConfigs(HOMEDELVDETAILS);
        getSingleConfigs(KOTPRINTITEMWISE);
        getSingleConfigs(KOTPRINTNOWISE);
        getSingleConfigs(DELETEREASON);
        getSingleConfigs(BILLPRINTWIDTH);
        getSingleConfigs(ASKFORPRINT);
        //getSingleConfigs(PRINTJARALLOWED);
        if (!isEmpty(COMPONENT_PARAMS.sBillNo)) {
            fetchBill(COMPONENT_PARAMS.sBillNo, null);
        }

        getCateogoryData();

    }, [storeActiveShopHash, storeApiUrl]);

    const handleSnpDialogClose = () => {
        //printLog("handleSnpDialogClose")
        setSnpComponent(INITIAL_SNP_COMPONENT);
    };

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    const handleSnpCancel = () => {
        //printLog("handleSnpCancel",snpComponent.requestType);
        if (snpComponent.requestType === DashboardComponent.CATEOGORY) {
            setSnpComponent(INITIAL_SNP_COMPONENT);
        } else if (snpComponent.requestType === DashboardComponent.BILLDETAILS
            || snpComponent.requestType === DashboardComponent.LOGOUT) {
            setComponentIndex(snpComponent.requestType);
        } else {
            setComponentIndex(DashboardComponent.BILLDETAILS);
        }
    };
    const handleSnpSubmit = () => {
        //printLog("handleSnpSubmit", snpComponent.requestType);
        setComponentIndex(snpComponent.requestType);
    };

    const handleRefereshData = async () => {
        if (filteredCatgProductData.length > 0) {
            let catgId = filteredCatgProductData[0].catgId;
            if (!isEmpty(catgId)) {
                handleDialogClose();
                setSnpLoader(true);
                await deleteItemForDb(catgId);
                getProductDataByCatgId(catgId);
            } else
                setSnpComponent({
                    ...snpComponent, snackBarOpen: true, snackBarMsg: "Some Internal Problem",
                });
        } else
            setSnpComponent({
                ...snpComponent, snackBarOpen: true, snackBarMsg: "Some Internal Problem",
            });
    };

    const handleItemData = async (opt) => {
        //printLog("handleItemData",opt);
        if (isPaid) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "This Bill is Already Paid",
                snackBarOpen: true,
            });
        } else {
            if (gridNo === 1) {
                setSnpLoader(true);
                //printDebugLog("handleItemData init",new Date().toISOString());
                let savedData = await getDataFromDb(opt.id);
                //printDebugLog("handleItemData getDataFromDb",new Date().toISOString());
                if (savedData !== undefined) {
                    //setSnpLoader(false);
                    setGridNo(2);
                    //printDebugLog("handleItemData grid",new Date().toISOString());
                    setFilteredCatgProductData(savedData.value);
                    //printDebugLog("handleItemData catgProduct",new Date().toISOString());
                    setSnpLoader(false);
                    //printDebugLog("handleItemData loader",new Date().toISOString());
                    setShowItemDialog(true);
                    //printDebugLog("handleItemData show start",new Date().toISOString());
                    setCatgProductData(savedData.value);
                    //const newArray = savedData.value.map(a => ({ ...a }));//Because of Shallow Copy,I used this method to convert to deep Copy
                    //printDebugLog("handleItemData end",new Date().toISOString());
                } else
                    getProductDataByCatgId(opt.id);
            } else if (gridNo === 2) {
                // if (opt.price.length > 1)
                //     handleProductSelectMultiPrice(opt);
                // else
                //     handleProductSelectSinglePrice(opt);
                handleProductSelectSinglePrice(opt);
            }
        }
    };

    const handleColQuantityChange = (cellData, event) => {
        //printLog("handleColQuantityChange => ", cellData,event.target.value);
        const value = event.target.value;
        let allowed = true;
        let quantity = parseFloat(value);
        if (isNumberEmpty(quantity)) {
            let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === cellData.prdId);
            listbillingData.value_2[index].quantity = 0;
            quantity = 0;
            // setBillingData({
            //     ...listbillingData,
            //     value_2: listbillingData.value_2,
            //     cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
            // });
        } else {
            quantity = quantity * 1;
            if (!isEmpty(value) && value.length > 1 && value.charAt(0) === '0')
                event.target.value = quantity;
            if (Number(stockSingleData.unitCount) > 0) {
                if (quantity > stockSingleData.unitCount) {
                    allowed = false;
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Total Count i.e " + stockSingleData.liveStockCount,
                    });
                }
            }
        }
        //printLog("handleColQuantityChange => quantity", quantity);
        if (allowed) {
            // if (quantity <= 0) {
            //     quantity = 0.5;
            // }
            // if (isNumberEmpty(quantity)) {
            //     quantity = 0;
            // }

            let sPrice = 0.0;
            if (cellData.price > 0.0) {
                quantity = parseFloat(quantity);
                if (parseInt(cellData.offerDenom) === OfferType.No_Offer) {
                    sPrice = cellData.price;
                } else if (parseInt(cellData.offerDenom) === OfferType.Flat) {
                    sPrice = cellData.price - cellData.offerAmt;
                } else if (parseInt(cellData.offerDenom) === OfferType.Percentage) {
                    sPrice = cellData.price
                        - ((cellData.price * cellData.offerAmt) / 100);
                }
                //sPrice = sPrice + (((stockSingleData.cgst + stockSingleData.sgst) * sPrice) / 100);
                sPrice = quantity * sPrice;
                let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === cellData.prdId);
                //printLog("handleColQuantityChange =",sPrice,index)
                let oldPrice = parseFloat(listbillingData.value_2[index].finalprice);
                listbillingData.value_2[index].finalprice = sPrice;
                listbillingData.value_2[index].quantity = quantity;
                listbillingData.value_2[index].print = false;
                //printLog("handleColQuantityChange <=",listbillingData.value_2[index])
                doTotal(oldPrice, sPrice, parseFloat(cellData.cgst), parseFloat(cellData.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, billDetails.offerAmt);
                setBillingData({
                    ...listbillingData,
                    value_2: listbillingData.value_2,
                    cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
                });
            }
        }
    };

    //opt means billStockData , prvsVal means stockSingleData
    const setItemDetailsOnEdit = (opt, prvsVal) => {
        printDebugLog("setItemDetailsOnEdit ", prvsVal);
        if (!isEmpty(prvsVal)) {
            if (!isEmpty(opt.foodQuantityName)) {
                if (isNumberEmpty(opt.priceIndex) || opt.priceIndex < -1) {
                    let index = prvsVal.price.findIndex(singleValue => singleValue.name === opt.foodQuantityName);
                    if (index > -1)
                        opt.priceIndex = index;
                }
            }
            setStockSingleData({
                ...stockSingleData,
                id: prvsVal.id,
                catgId: prvsVal.catgId,
                catgName: prvsVal.catgName,
                name: prvsVal.name,
                productImage: prvsVal.productImage,
                spicy: prvsVal.spicy,
                foodType: prvsVal.foodType,
                sgst: prvsVal.sgst,
                cgst: prvsVal.cgst,
                ingredients: prvsVal.ingredients,
                outOfStock: prvsVal.outOfStock,
                offertype: prvsVal.offertype,
                offerAmt: prvsVal.offerAmt,
                price: prvsVal.price,
                liveStockCount: prvsVal.liveStockCount,
                unitCount: prvsVal.unitCount,
                smallUnitCount: prvsVal.smallUnitCount,
                unitRestrictions: prvsVal.unitRestrictions,
            });
        }
        setBillStockData({
            ...billStockData, ...opt
        });
        setGridNo(3);
        setIsEditItem(true);
        setShowItemDialog(true);
    };




    const handleCellClick = (btn, event) => {
        //printLog("handleCellClick",event.row);
        if (!isPaid) {
            if (event.field === 'select') {
                let opt = event.row;
                let index = selectedProductData.findIndex(singleValue => singleValue.id === opt.prdId);
                if (index > -1) {
                    let prvsVal = selectedProductData[index];
                    setItemDetailsOnEdit(opt, prvsVal);
                } else {
                    getProductDataByonEdit(opt);
                }
            } else if (event.field === 'del') {
                if (isEmpty(listbillingData.billNo)) {
                    var array = [...listbillingData.value_2];
                    var index = array.findIndex(singleValue => singleValue.prdId === event.row.prdId);
                    if (index !== -1) {
                        //printLog("handleCellClick b", index, array);
                        let oldPrice = parseFloat(array[index].price) * array[index].quantity;
                        doTotal(oldPrice, 0.0, parseFloat(array[index].cgst), parseFloat(array[index].sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, billDetails.offerAmt);
                        array.splice(index, 1);
                        //printLog("handleCellClick ", listbillingData.value_2);
                        setBillingData({
                            ...listbillingData,
                            value_2: array,
                        });
                    }
                } else {
                    if (delReason) {
                        setDelItemDialog({
                            delItemDialog,
                            showDialog: true,
                            prdId: event.row.prdId,
                            delReason: "",
                            prdName: event.row.name,
                        })
                    } else
                        deleteFromBill(event.row.prdId);
                }
            }
        } else {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Paid Dish Can't be Edited/Deleted",
                snackBarOpen: true,
            });
        }

    };

    const getBillTypeName = () => {
        let data = "";
        if (billUtils.billingType === InternalBillType.Quick)
            data = "Quick Bill";
        else if (billUtils.billingType === InternalBillType.BookTable)
            data = "Table Booking";
        else if (billUtils.billingType === InternalBillType.Home)
            data = "Home Delivery";
        else if (billUtils.billingType === InternalBillType.TakeAway)
            data = "Take Away";
        if (billDetails.token > -1) {
            data = data + "(Order No: " + billDetails.token + ")";
        }
        return data;
    };

    const handleDialogClose = () => {
        //printLog("handleDialogClose");
        setShowItemDialog(false);
        setIsEditItem(false);
        setGridNo(1);
        if (clearDues) {
            setPartialPay(INITIAL_PARTIAL_PAY);
        }
        setClearDues(false);
        setStockSingleData(INITIAL_STOCK_DATA);
        setBillStockData(INITIAL_BILL_STOCK_DATA);
    };

    const handleAddItemDialogClose = () => {
        //printLog("handleDialogClose");
        if (!selectMultipleItemDialog) {
            setShowItemDialog(false);
            setGridNo(1);
        }
        setIsEditItem(false);
        if (clearDues) {
            setPartialPay(INITIAL_PARTIAL_PAY);
        }
        setClearDues(false);
        setStockSingleData(INITIAL_STOCK_DATA);
        setBillStockData(INITIAL_BILL_STOCK_DATA);
    };

    const handleProductSelectSinglePrice = (cellData) => {
        //printLog("handleProductSelectSinglePrice =>", cellData);
        let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === cellData.id);
        if (parseInt(index) === -1) {
            var billObj = INITIAL_BILL_STOCK_DATA;
            billObj.cgst = cellData.cgst;
            billObj.prdId = cellData.id;
            billObj.name = cellData.name;
            billObj.sgst = cellData.sgst;
            billObj.price = cellData.price.sAmt;
            billObj.foodQuantityName = cellData.price.name;
            billObj.finalprice = cellData.price.sAmt;
            billObj.note = "";
            billObj.priceIndex = 0;
            billObj.quantity = 1;
            billObj.offerDenom = cellData.offertype;
            billObj.offerAmt = cellData.offerAmt;
            billObj.print = false;
            billObj.combo = (cellData.catgId === 'snp_combo_id');
            //printLog("handleProductSelectSinglePrice =", billObj,listbillingData.value_2);
            setBillingData(prvsState => ({
                ...listbillingData,
                value_2: [...prvsState.value_2, { ...billObj }],
            }));
            setOnlinePayEnb(false);
            setCashPayEnb(false);
            handleAddItemDialogClose();
            doTotal(0.0, cellData.price.sAmt, parseFloat(cellData.cgst), parseFloat(cellData.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, billDetails.offerAmt);
            //printLog("handleProductSelectSinglePrice =>");

            handleChangeInSelectedProduct('', false, cellData);
        } else {
            //printLog("Already Added => ", cellData);
            // setSnpComponent({
            //     ...snpComponent,
            //     snackBarOpen: true,
            //     snackBarMsg: cellData.name + " is already added"
            // })
            let opt = listbillingData.value_2[index];
            setItemDetailsOnEdit(opt, cellData);
        }
    };

    const handleChangeInSelectedProduct = (mPrdId, isDel, opt) => {
        //printLog("handleChangeInSelectedProduct", mPrdId, isDel, stockSingleData);
        //printLog("selectedProductData ",selectedProductData);
        if (isDel) {
            var array = [...selectedProductData];
            var index = array.findIndex(singleValue => singleValue.id === mPrdId);
            if (index !== -1) {
                array.splice(index, 1);
                setSelectedProductData(array);
            }
        } else {
            if (opt != null) {
                // //printLog("opt ",opt);
                stockSingleData.id = opt.id;
                stockSingleData.catgId = opt.catgId;
                stockSingleData.catgName = opt.catgName;
                stockSingleData.name = opt.name;
                stockSingleData.productImage = opt.productImage;
                stockSingleData.spicy = opt.spicy;
                stockSingleData.foodType = opt.foodType;
                stockSingleData.sgst = opt.sgst;
                stockSingleData.cgst = opt.cgst;
                stockSingleData.ingredients = opt.ingredients;
                stockSingleData.outOfStock = opt.outOfStock;
                stockSingleData.offertype = opt.offertype;
                stockSingleData.offerAmt = opt.offerAmt;
                stockSingleData.price = opt.price;
                stockSingleData.liveStockCount = opt.liveStockCount;
                stockSingleData.unitCount = opt.unitCount;
                stockSingleData.smallUnitCount = opt.smallUnitCount;
                stockSingleData.unitRestrictions = opt.unitRestrictions;
                // //printLog("stockSingleData opt wala ",stockSingleData);
            }

            let index = selectedProductData.findIndex(singleValue => singleValue.id === stockSingleData.id);
            // //printLog("selectedProductData index",index);
            if (parseInt(index) === -1) {
                setSelectedProductData(oldArray => [...oldArray, stockSingleData]);
            } else {
                var selPrdArray = [...selectedProductData];
                selPrdArray[index] = stockSingleData;
                setSelectedProductData(selPrdArray);
            }

        }
        setStockSingleData(INITIAL_STOCK_DATA);
    };

    const handleProductSelectMultiPrice = (opt) => {
        printDebugLog("handleProductSelectMultiPrice =>", opt);
        setGridNo(3);
        setStockSingleData({
            ...stockSingleData, ...opt
        });
        let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === opt.id);
        if (parseInt(index) === -1) {
            initialUpdateStockPrice(opt);
        } else {
            let prvsVal = listbillingData.value_2[index];
            setItemDetailsOnEdit(prvsVal, opt);
        }
    }

    const initialUpdateStockPrice = (opt) => {
        //printLog("initialUpdateStockPrice",opt);
        let sPrice = 0.0;
        let price = opt.price;
        if (price.sAmt > 0.0) {
            if (parseInt(opt.offertype) === OfferType.No_Offer) {
                sPrice = price.sAmt;
            } else if (parseInt(opt.offertype) === OfferType.Flat) {
                sPrice = price.sAmt - opt.offerAmt;
            } else if (parseInt(opt.offertype) === OfferType.Percentage) {
                sPrice = price.sAmt
                    - ((price.sAmt * opt.offerAmt) / 100);
            }
            //sPrice = sPrice + (((stockSingleData.cgst + stockSingleData.sgst) * sPrice) / 100);
            sPrice = 1 * sPrice;
        }
        setBillStockData({
            ...billStockData,
            prdId: opt.id,
            name: opt.name,
            sgst: opt.sgst,
            cgst: opt.cgst,
            offerDenom: opt.offertype,
            offerAmt: opt.offerAmt,
            priceIndex: 0,
            combo: (opt.catgId === 'snp_combo_id'),
            price: price.sAmt,
            finalprice: sPrice,
            quantity: 1,
            savedQuantity: 1,
            foodQuantityName: price.name,
        })
    };

    const handleDialogSubmit = () => {
        //printLog("handleDialogSubmit", clearDues);
        if (clearDues)
            updatePartial();
        else
            handleAddItem();
    }
    const handleAddItem = () => {
        if (isNumberEmpty(billStockData.priceIndex)) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Kindly Select the Rate Card Options",
                snackBarOpen: true,
            })
        } else if (billStockData.priceIndex === -1) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Kindly Select the Rate Card Options",
                snackBarOpen: true,
            })
        } else {
            let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === billStockData.prdId);
            //printLog("handleAddItem:  ", billStockData, index, listbillingData.value_2.length);
            let oldPrice = 0.0;
            let nwPrice = parseFloat(billStockData.finalprice);
            if (parseInt(index) === -1) {
                setBillingData(prvsState => ({
                    ...listbillingData,
                    value_2: [...prvsState.value_2, { ...billStockData, price: parseFloat(billStockData.price) }],
                    cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
                }));
            } else {
                oldPrice = parseFloat(listbillingData.value_2[index].finalprice);
                listbillingData.value_2[index].prdId = billStockData.prdId;
                listbillingData.value_2[index].name = billStockData.name;
                listbillingData.value_2[index].sgst = parseFloat(billStockData.sgst);
                listbillingData.value_2[index].cgst = parseFloat(billStockData.cgst);
                listbillingData.value_2[index].foodQuantityName = billStockData.foodQuantityName;
                listbillingData.value_2[index].priceIndex = billStockData.priceIndex;
                listbillingData.value_2[index].price = parseFloat(billStockData.price);
                listbillingData.value_2[index].finalprice = parseFloat(billStockData.finalprice);
                listbillingData.value_2[index].offerDenom = parseInt(billStockData.offerDenom);
                listbillingData.value_2[index].offerAmt = parseFloat(billStockData.offerAmt);
                listbillingData.value_2[index].quantity = parseFloat(billStockData.quantity);
                listbillingData.value_2[index].note = billStockData.note;
                listbillingData.value_2[index].print = false;
                setBillingData({
                    ...listbillingData,
                    value_2: listbillingData.value_2,
                    cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
                });
                setOnlinePayEnb(false);
                setCashPayEnb(false);
            }
            handleChangeInSelectedProduct('', false, null);
            handleDialogClose();
            //printLog("handleAddItem",billDetails);
            doTotal(oldPrice, nwPrice, parseFloat(billStockData.cgst), parseFloat(billStockData.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, billDetails.offerAmt);
        }

    };

    const getPayMedium = (cashAllowed, onlineAllowed) => {
        let mPayMedium = PayMedium.NOPAY;
        if (cashAllowed && onlineAllowed)
            mPayMedium = PayMedium.BOTH;
        else if (cashAllowed && !onlineAllowed)
            mPayMedium = PayMedium.CASH;
        else if (!cashAllowed && onlineAllowed)
            mPayMedium = PayMedium.ONLINE;
        return mPayMedium;
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        //printLog("handleInputChange", event.target);
        if (name === 'offerDenom') {
            updateStockPrice(billStockData.price, value, billStockData.offerAmt, billStockData.quantity, billStockData.priceIndex);
        } else if (name === 'offerAmt') {
            updateStockPrice(billStockData.price, billStockData.offerDenom, value, billStockData.quantity, billStockData.priceIndex);
        } else if (name === 'cashTender') {
            if (billDetails.total > 0.0) {
                let tot = 0.0;
                if (isOnlinePayEnabled)
                    tot = paymentDetails.cashPay;
                else
                    tot = billDetails.total;
                let rt = parseFloat(value) - tot;
                if (rt >= 0.0) {
                    setPaymentDetails({
                        ...paymentDetails,
                        cashTender: parseFloat(value),
                        cashReturn: rt,
                    });
                } else {
                    setPaymentDetails({
                        ...paymentDetails,
                        cashTender: value,
                        cashReturn: 0.0,
                    });
                    if (rt < 0.0) {
                        setSnpComponent({
                            ...snpComponent,
                            snackBarOpen: true,
                            snackBarMsg: "Cash Tender should be more or same as Total Amount i.e " + tot,
                        });
                    }
                }
            } else {
                setSnpComponent({
                    ...snpComponent,
                    snackBarOpen: true,
                    snackBarMsg: "Please add Dish to start Bill process",
                });
                setPaymentDetails({
                    ...paymentDetails,
                    cashTender: 0.0,
                    cashReturn: 0.0,
                });
            }
        } else if (name === 'rate-select') {
            printDebugLog("rate-select", value, stockSingleData);
            updateStockPrice(parseFloat(event.target.id), billStockData.offerDenom, billStockData.offerAmt, billStockData.quantity, value);
        } else if (name === 'onlinePay') {
            let val = !isOnlinePayEnabled;
            let rt = parseFloat(billDetails.total);
            setPaymentDetails({
                ...paymentDetails,
                onlinePayThrough: val ? value : "",
                onlinePayMedium: val ? CART_INITIAL_STATE.onlinePayMethod[0] : "",
                payMedium: getPayMedium(isCashPayEnabled, val),
                onlinePay: !val ? 0.0 : isCashPayEnabled ? (rt > 0) ? (rt / 2) : 0.0 : rt,
                cashPay: !isCashPayEnabled ? 0.0 : val ? (rt > 0) ? (rt / 2) : 0.0 : rt,
            });
            setOnlinePayEnb(val);
        } else if (name === 'onlinePayAmt') {
            if (isNumberEmpty(value)) {
                let rt = parseFloat(billDetails.total);
                setPaymentDetails({
                    ...paymentDetails,
                    onlinePay: 0.0,
                    cashPay: isCashPayEnabled ? rt : 0.0,
                    cashTender: 0.0,
                    cashReturn: 0.0,
                    payMedium: getPayMedium(isCashPayEnabled, true),
                });
                setOnlinePayEnb(true);
            } else {
                let rt = parseFloat(billDetails.total);

                if (parseFloat(value) > rt) {
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Total Amount i.e " + rt,
                    });
                } else {
                    let mOnlinePay = Number(value);
                    let mCashPay = isCashPayEnabled ? (rt - mOnlinePay) : 0.0;
                    let mOnlinePayEnb = (mOnlinePay !== 0.0);
                    let mCashPayEnb = (mCashPay !== 0.0);
                    //printLog("onlinePayAmt", value,rt,mCashPay);
                    setPaymentDetails({
                        ...paymentDetails,
                        onlinePay: mOnlinePay,
                        cashPay: mCashPay,
                        cashPayThrough: !mCashPayEnb ? "" : paymentDetails.cashPayThrough,
                        onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
                        cashTender: 0.0,
                        cashReturn: 0.0,
                        payMedium: getPayMedium(mCashPayEnb, mOnlinePayEnb),
                    });
                    setOnlinePayEnb(mOnlinePayEnb);
                    setCashPayEnb(mCashPayEnb);
                }
            }
        } else if (name === 'cashPayAmt') {
            //printLog("cashPayAmt",value)
            if (isNumberEmpty(value)) {
                let rt = parseFloat(billDetails.total);
                setPaymentDetails({
                    ...paymentDetails,
                    cashPay: 0.0,
                    onlinePay: isOnlinePayEnabled ? rt : 0.0,
                    cashTender: value,
                    cashReturn: 0.0,
                    payMedium: getPayMedium(true, isOnlinePayEnabled),
                });
                setCashPayEnb(true);
            } else {
                let rt = parseFloat(billDetails.total);
                if (parseFloat(value) > rt) {
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Total Amount i.e " + rt,
                    });
                } else {
                    let mCashPay = Number(value);
                    let mOnlinePay = isOnlinePayEnabled ? (rt - mCashPay) : 0.0;
                    let mOnlinePayEnb = (mOnlinePay !== 0.0);
                    let mCashPayEnb = (mCashPay !== 0.0);
                    setPaymentDetails({
                        ...paymentDetails,
                        cashPay: mCashPay,
                        onlinePay: mOnlinePay,
                        onlinePayThrough: !mOnlinePayEnb ? "" : paymentDetails.onlinePayThrough,
                        onlinePayMedium: !mOnlinePayEnb ? "" : paymentDetails.onlinePayMedium,
                        cashTender: 0.0,
                        cashReturn: 0.0,
                        payMedium: getPayMedium(mCashPayEnb, mOnlinePayEnb),
                    });
                    setOnlinePayEnb(mOnlinePayEnb);
                    setCashPayEnb(mCashPayEnb);
                }
            }
        } else if (name === 'cashPay') {
            let val = !isCashPayEnabled;
            let rt = parseFloat(billDetails.total);
            setPaymentDetails({
                ...paymentDetails,
                cashPayThrough: val ? value : "",
                cashPay: !val ? 0.0 : isOnlinePayEnabled ? (rt > 0) ? (rt / 2) : 0.0 : rt,
                onlinePay: !isOnlinePayEnabled ? 0.0 : val ? (rt > 0) ? (rt / 2) : 0.0 : rt,
                payMedium: getPayMedium(val, isOnlinePayEnabled),
            });
            setCashPayEnb(val);
        } else if (name === 'isGstAllowed') {
            let val = !billDetails.isGstAllowed;
            printDebugLog("isGstAllowed val", val);
            printDebugLog("isGstAllowed ", billDetails.isGstAllowed);
            setBillDetails({
                ...billDetails, isGstAllowed: val
            });
            doTotal(0.0, 0.0, parseFloat(billDetails.cgst), parseFloat(billDetails.sgst), parseFloat(listbillingData.pointRedeem), val, delvCharge, billDetails.offerDenom, billDetails.offerAmt);
        } else if (name === 'partialAmt') {
            if (parseFloat(value) > parseFloat(billDetails.total)) {
                setSnpComponent({
                    ...snpComponent,
                    snackBarOpen: true,
                    snackBarMsg: "Exceeds Total Amt i.e " + billDetails.total,
                });
                setBillDetails({
                    ...billDetails,
                    pendingAmt: 0, partialAmt: 0, cashTender: 0, cashReturn: 0,
                });
            } else {
                setOnlinePayEnb(false);
                setCashPayEnb(false);
                setBillingData({
                    ...listbillingData,
                    cashPay: 0, onlinePay: 0, onlinePayMedium: "", cashPayThrough: "", onlinePayThrough: "",
                });
                doTotal(0.0, 0.0, parseFloat(billDetails.cgst), parseFloat(billDetails.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, parseFloat(value), billDetails.offerDenom, billDetails.offerAmt);
            }
        } else if (name === 'delvNameOth') {
            setDelvNameOth(value);
        } else if (name === 'delvCharge') {
            doTotal(0.0, 0.0, parseFloat(billDetails.cgst), parseFloat(billDetails.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, parseFloat(value), billDetails.offerDenom, billDetails.offerAmt);
        } else if (name === 'bofferDenom') {
            doTotal(0.0, 0.0, parseFloat(billDetails.cgst), parseFloat(billDetails.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, parseInt(value), billDetails.offerAmt);
        } else if (name === 'bofferAmt') {
            doTotal(0.0, 0.0, parseFloat(billDetails.cgst), parseFloat(billDetails.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, value);
        } else if (name === 'quantity') {
            let data = 0;
            if (!isEmpty(value))
                data = parseFloat(value);
            if (!isEmpty(value) && value.length > 1 && value.charAt(0) === '0')
                event.target.value = data;
            if (Number(stockSingleData.unitCount) > 0) {
                if (data > stockSingleData.unitCount) {
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Total Count i.e " + stockSingleData.liveStockCount,
                    });
                } else {
                    updateStockPrice(billStockData.price, billStockData.offerDenom, billStockData.offerAmt, data, billStockData.priceIndex);
                }
            } else {
                updateStockPrice(billStockData.price, billStockData.offerDenom, billStockData.offerAmt, data, billStockData.priceIndex);
            }

        } else {
            setBillStockData({
                ...billStockData,
                [name]: value,
            });
        }
    };
    const doTotal = (oldPrice, billTotal, mCgst, mSgst, mPointRedeem, mIgstAllowed, mDelvCharge, m_OfferDenom, m_OfferAmt) => {
        printDebugLog("doTotal =>", oldPrice, billTotal, mCgst, mSgst, mPointRedeem, mIgstAllowed, mDelvCharge, m_OfferDenom, m_OfferAmt);
        if (isNumberEmpty(mPointRedeem)) {
            mPointRedeem = 0.0;
        }

        if (isNumberEmpty(mDelvCharge)) {
            mDelvCharge = 0.0;
        }

        if (isNumberEmpty(m_OfferAmt)) {
            m_OfferAmt = 0.0;
        }
        if (isNumberEmpty(billTotal)) {
            billTotal = 0.0;
        }
        if (isNumberEmpty(oldPrice)) {
            oldPrice = 0.0;
        }
        printDebugLog("doTotal =>", oldPrice, billTotal, mCgst, mSgst, mPointRedeem, mIgstAllowed, mDelvCharge, m_OfferDenom, m_OfferAmt);
        let mCgstAmt = 0.0;
        let mSgstAmt = 0.0;
        let mRoundOff = 0.0;
        let mTotal = 0.0;
        billTotal = parseFloat(billDetails.subTotal) + parseFloat(billTotal) - parseFloat(oldPrice);
        if (parseInt(m_OfferDenom) === OfferType.No_Offer) {
            mTotal = billTotal;
            m_OfferAmt = 0.0;
        } else if (parseInt(m_OfferDenom) === OfferType.Flat) {
            mTotal = billTotal - m_OfferAmt;
        } else if (parseInt(m_OfferDenom) === OfferType.Percentage) {
            mTotal = billTotal
                - ((billTotal * m_OfferAmt) / 100);
        }
        printDebugLog("doTotal",mTotal);
        if (mIgstAllowed) {
            mCgstAmt = (parseFloat(mTotal) * parseFloat(mCgst)) / 100;
            mSgstAmt = (parseFloat(mTotal) * parseFloat(mSgst)) / 100;
            printDebugLog("doTotal",mCgstAmt,mSgstAmt);
        }
        if (parseFloat(mPointRedeem) >= parseFloat(mTotal)) {
            mPointRedeem = mTotal.toFixed(2);
            mTotal = 0.0;
        } else {
            mTotal = parseFloat(mTotal) + parseFloat(mCgstAmt.toFixed(2)) + parseFloat(mSgstAmt.toFixed(2)) - (parseFloat(mPointRedeem)) + mDelvCharge;
            mRoundOff = mTotal.toFixed() - mTotal;
            mTotal = mTotal + mRoundOff;
        }

        printDebugLog("doTotal <=",mTotal, billTotal, mCgstAmt, mSgstAmt, mRoundOff);
        var dues = partilBillAvail ? mTotal : 0.0;
        setBillDetails({
            ...billDetails,
            subTotal: billTotal.toFixed(2),
            cgst: mCgst,
            sgst: mSgst,
            pendingAmt: dues.toFixed(2),
            cgstAmt: mCgstAmt.toFixed(2),
            sgstAmt: mSgstAmt.toFixed(2),
            isGstAllowed: mIgstAllowed,
            total: mTotal.toFixed(2),
            roundOff: mRoundOff.toFixed(2),
            pointRedeem: mPointRedeem,
            isPointAdded: (mPointRedeem > 0.0),
            offerDenom: m_OfferDenom,
            offerAmt: m_OfferAmt,
            cashTender: 0,
            cashReturn: 0,
        });
        if (mDelvCharge > 0.0) {
            setPaymentDetails({
                ...paymentDetails,
                delvName: paymentDetails.delvName,
                onlinePayMedium: "",
                onlinePayThrough: "",
                onlinePay: 0.0,
                cashPay: 0.0,
                cashPayThrough: "",
                cashReturn: 0.0,
                cashTender: 0.0,
                payMedium: PayMedium.NOPAY,
            });
        } else
            setPaymentDetails(INITIAL_PAYMENT_DETAILS);
        setOnlinePayEnb(false);
        setCashPayEnb(false);
        setDelvCharge(mDelvCharge);
    }



    const updateStockPrice = (m_price, m_OfferDenom, m_OfferAmt, m_Quantity, m_priceIndex) => {
        //printLog("updateStockPrice",m_price, m_OfferDenom, m_OfferAmt, m_Quantity, m_priceIndex);
        let sPrice = 0.0;
        if (m_price > 0.0) {
            if (parseInt(m_OfferDenom) === OfferType.No_Offer) {
                sPrice = m_price;
            } else if (parseInt(m_OfferDenom) === OfferType.Flat) {
                sPrice = m_price - m_OfferAmt;
            } else if (parseInt(m_OfferDenom) === OfferType.Percentage) {
                sPrice = m_price
                    - ((m_price * m_OfferAmt) / 100);
            }
            //sPrice = sPrice + (((stockSingleData.cgst + stockSingleData.sgst) * sPrice) / 100);
            sPrice = m_Quantity * sPrice;
        }
        let price = stockSingleData.price;

        setBillStockData({
            ...billStockData,
            price: m_price,
            offerDenom: m_OfferDenom,
            offerAmt: m_OfferAmt,
            finalprice: sPrice,
            quantity: m_Quantity,
            priceIndex: 0,
            foodQuantityName: price.name,
        });
        //printLog("updateStockPrice ", billStockData);
    };



    const downloadFile = async (url, name) => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();

            // Create a link element
            const link = document.createElement('a');

            var currentTime = +new Date();

            // Set the download attribute with the desired file name
            link.download = "Snp_" + name + "_" + storeActiveShopHash.id + "_" + billPrintWidth + "_" + currentTime + ".pdf";

            // Create a URL for the Blob and set it as the href attribute of the link
            link.href = window.URL.createObjectURL(blob);

            // Append the link to the document
            document.body.appendChild(link);

            // Trigger a click event on the link to start the download
            link.click();

            // Remove the link from the document
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };


    const printFallBackMechanism = (printUrl) => {
        let browser = detectBrowser();
        printDebugLog("printFallBackMechanism => ", "Mobile: " + isMobile + " ,Browser" + browser);
        if (!isMobile && browser === 'Chrome') {
            printJS(printUrl);
        } else
            window.open(printUrl, '_blank');
    };



    //https://printjs.crabbly.com/
    const raiseInvoiceClicked = (rPrint, name) => {
        setCustCopy(false);
        if (!isEmpty(rPrint) && !rPrint.includes('Successfully Print')) {
            const url = storeApiUrl.BACKEND_BASE_URL + "files/" + rPrint;
            //e.preventDefault();
            printFallBackMechanism(url);


            // var iframe = document.createElement('iframe');
            // // iframe.id = 'pdfIframe'
            // iframe.className = 'pdfIframe'
            // document.body.appendChild(iframe);
            // iframe.style.display = 'none';
            // iframe.onload = function () {
            //     setTimeout(function () {
            //         iframe.focus();
            //         iframe.contentWindow.print();
            //         URL.revokeObjectURL(url)
            //         // document.body.removeChild(iframe)
            //     }, 1);
            // };
            // iframe.src = url;
        } else if (!isEmpty(rPrint) && rPrint.includes('Successfully Print')) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Print Initiated...",
                snackBarOpen: true,
            })
            if (name.includes('gen'))
                generateDynamicHTML(0, 0, 1, "");
            else if (name.includes('kot')) {
                const kotBillNo = name.split("@");
                generateDynamicHTML(1, 0, 0, kotBillNo[1]);
            } else if (name.includes('shop'))
                generateDynamicHTML(0, 1, 0, "");
            else if (name.includes('cust',))
                generateDynamicHTML(0, 0, 0, "");
        }
        else {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Saved Successfully",
                snackBarOpen: true,
            })
            //printLog("raiseInvoiceClicked => print is Empty");
        }
    }
    const customerInputDialogClose = () => {
        setCustomerInputDialog({ ...customerInputDialog, custInputopen: false, custInputAdd: false })
        setBillingData({ ...listbillingData, custMob: "", custName: "", custEmail: "", address: "" });
    }

    const addCustomerInput = () => {
        if (listbillingData.custMob.length > 0 && listbillingData.custMob.length === 10) {
            setCustomerInputDialog({ ...customerInputDialog, custInputopen: false, custInputAdd: true })
            setIsCustDataAvailable(false);
        } else {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Please Enter Correct Mobile Number",
                snackBarOpen: true,
            });
        }
    }

    const isSubmitButtonEnb = () => {
        if (isPaid) {
            return false;
        }
        return (listbillingData.billStatus === BillStatus.INITIATED || billUtils.billingType !== InternalBillType.BookTable);
    };

    const handleSelectChange = (event, payMethod) => {
        //printLog("handleSelectChange", payMethod);
        if (payMethod != null) {
            setPartialPay({
                ...partialPay,
                onlineBillPaidThrough: payMethod,
            });
        } else {
            setPartialPay({
                ...partialPay,
                onlineBillPaidThrough: "",
            });
        }

    };
    const handleOnlineSelectChange = (event, payMethod) => {
        //printLog("handleOnlineSelectChange", payMethod);
        if (payMethod != null) {
            setPaymentDetails({
                ...paymentDetails,
                onlinePayMedium: payMethod,
            });
        } else {
            setPaymentDetails({
                ...paymentDetails,
                onlinePayMedium: "",
            });
        }

        setBillingData({
            ...listbillingData, onlineBillOtherName: "",
        })
    };
    const handleDelvSelectChange = (event, payMethod) => {
        //printLog("handleDelvSelectChange", payMethod);
        if (payMethod != null) {
            let isOther = (payMethod === 'Other');
            setPaymentDetails({
                ...paymentDetails,
                delvName: payMethod,
                onlinePayMedium: 'Other',
                onlinePayThrough: ONLINEPAY,
                onlinePay: Number(billDetails.total),
                cashPay: 0.0,
                payMedium: PayMedium.ONLINE,
            });
            setBillingData({
                ...listbillingData, onlineBillOtherName: payMethod,
            })
            setDelvNameOth(isOther ? "" : payMethod);
            setIsOtherHomeDelv(isOther);
            setOnlinePayEnb(true);
        } else {
            setPaymentDetails({
                ...paymentDetails,
                delvCharge: 0.0,
                delvName: "",
                onlinePayMedium: "",
                onlinePayThrough: "",
                onlinePay: 0.0,
                cashPay: 0.0,
                payMedium: PayMedium.NOPAY,
            });
            setBillingData({
                ...listbillingData, onlineBillOtherName: "",
            })
            setDelvNameOth(payMethod);
            setIsOtherHomeDelv(false);
            setOnlinePayEnb(false);
        }
    };
    const handlePartialPayChange = (event) => {
        const { name, value } = event.target;
        //printLog("handlePartialPayChange", name, value);
        if (name === "clearDues") {
            setClearDues(true);
            setShowItemDialog(true);
        } else if (name === 'partialAmt') {
            if (!isNumberEmpty(value)) {
                if (parseFloat(value) > parseFloat(billDetails.pendingAmt)) {
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Remaining Amt i.e " + billDetails.pendingAmt,
                    });
                    setBillDetails({
                        ...billDetails,
                        currDues: 0
                    });
                    setPartialPay({
                        ...partialPay,
                        partialAmt: 0, onlineBillPaidThrough: "", onlinePay: 0, cashBillPaidThrough: "", cashPay: 0, cashTendered: 0, cashReturn: 0,
                    });
                } else {
                    let dues = parseFloat(billDetails.pendingAmt) - parseFloat(value);
                    setPartialPay({
                        ...partialPay,
                        partialAmt: parseFloat(value), onlineBillPaidThrough: "", onlinePay: 0, cashBillPaidThrough: "", cashPay: 0, cashTendered: 0, cashReturn: 0,
                    });
                    setBillDetails({
                        ...billDetails,
                        currDues: dues
                    });
                }
            } else {
                setPartialPay({
                    ...partialPay,
                    partialAmt: 0, onlineBillPaidThrough: "", onlinePay: 0, cashBillPaidThrough: "", cashPay: 0, cashTendered: 0, cashReturn: 0,
                });
                setBillDetails({
                    ...billDetails,
                    currDues: 0
                });
            }

        } else if (name === 'cashPayAmt') {
            let rt = partialPay.partialAmt;
            if (isEmpty(value)) {
                setPartialPay({
                    ...partialPay,
                    cashPay: 0.0,
                    onlinePay: (isEmpty(partialPay.onlineBillPaidThrough)) ? 0.0 : rt,
                    cashTendered: 0, cashReturn: 0,
                });
            } else {
                if (parseFloat(value) > rt) {
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Remaining Amt i.e " + rt,
                    });
                } else {
                    let mOnlinePay = (!isEmpty(partialPay.onlineBillPaidThrough)) ? (rt - parseFloat(value)) : 0.0;
                    setPartialPay({
                        ...partialPay,
                        cashPay: parseFloat(value),
                        onlinePay: mOnlinePay,
                        onlineBillPaidThrough: (mOnlinePay === 0.0) ? "" : partialPay.onlineBillPaidThrough
                        , cashTendered: 0, cashReturn: 0,
                    });
                }
            }
        } else if (name === 'cashPay') {
            //printLog("cashPay",partialPay);
            let val = (isEmpty(partialPay.cashBillPaidThrough)) ? CASHPAY : '';
            //printLog("val",val);
            let rt = partialPay.partialAmt;
            if (rt > 0) {
                setPartialPay({
                    ...partialPay,
                    cashBillPaidThrough: val,
                    cashPay: (!isEmpty(partialPay.onlineBillPaidThrough)) ? (!isEmpty(val)) ? (rt / 2) : 0.0 : (!isEmpty(val)) ? rt : 0.0,
                    onlinePay: (!isEmpty(partialPay.onlineBillPaidThrough)) ? (!isEmpty(val)) ? (rt / 2) : rt : 0.0,
                    cashTendered: 0, cashReturn: 0,
                });
            } else {
                setSnpComponent({
                    ...snpComponent,
                    snackBarOpen: true,
                    snackBarMsg: "Please Enter Amount to be Paid",
                });
            }

        } else if (name === 'onlinePay') {
            //printLog("CART_INITIAL_STATE.onlinePayMethod",CART_INITIAL_STATE.onlinePayMethod);
            let val = (isEmpty(partialPay.onlineBillPaidThrough)) ? CART_INITIAL_STATE.onlinePayMethod[0] : '';
            let rt = partialPay.partialAmt;
            if (rt > 0) {
                setPartialPay({
                    ...partialPay,
                    onlineBillPaidThrough: val,
                    cashPay: (!isEmpty(partialPay.cashBillPaidThrough)) ? (!isEmpty(val)) ? (rt / 2) : rt : 0.0,
                    onlinePay: (!isEmpty(partialPay.cashBillPaidThrough)) ? (!isEmpty(val)) ? (rt / 2) : 0.0 : (!isEmpty(val)) ? rt : 0.0,
                    cashTendered: 0, cashReturn: 0,
                });
            } else {
                setSnpComponent({
                    ...snpComponent,
                    snackBarOpen: true,
                    snackBarMsg: "Please Enter Amount to be Paid",
                });
            }
        } else if (name === 'onlinePayAmt') {
            let rt = partialPay.partialAmt;
            if (isEmpty(value)) {
                setPartialPay({
                    ...partialPay,
                    cashPay: (isEmpty(partialPay.cashBillPaidThrough)) ? 0.0 : rt,
                    onlinePay: 0.0,
                    cashTendered: 0, cashReturn: 0,
                });
            } else {
                if (parseFloat(value) > rt) {
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Remaining Amt i.e " + rt,
                    });
                } else {
                    let mCashPay = (!isEmpty(partialPay.cashBillPaidThrough)) ? (rt - parseFloat(value)) : 0.0;
                    setPartialPay({
                        ...partialPay,
                        onlinePay: parseFloat(value),
                        cashPay: mCashPay,
                        cashBillPaidThrough: (mCashPay === 0.0) ? "" : partialPay.cashBillPaidThrough
                        , cashTendered: 0, cashReturn: 0,
                    });
                }
            }
        } else if (name === 'cashTender') {
            if (isNumberEmpty(value)) {
                setPartialPay({
                    ...partialPay,
                    cashTendered: 0.0,
                    cashReturn: 0.0,
                });
            } else {
                let tot = 0.0;
                if (!isEmpty(partialPay.onlineBillPaidThrough))
                    tot = partialPay.cashPay;
                else
                    tot = partialPay.partialAmt;
                let rt = parseFloat(value) - tot;
                if (rt >= 0.0) {
                    setPartialPay({
                        ...partialPay,
                        cashTendered: parseFloat(value),
                        cashReturn: rt,
                    });
                } else {
                    setPartialPay({
                        ...partialPay,
                        cashTendered: parseFloat(value),
                        cashReturn: 0.0,
                    });
                    if (rt < 0.0) {
                        setSnpComponent({
                            ...snpComponent,
                            snackBarOpen: true,
                            snackBarMsg: "Cash Tender should be more or same as Cash Amount i.e " + tot,
                        });
                    }
                }
            }
        }
    };
    const getPartialPaidThrough = (val) => {
        if (val.onlineBillPaidThrough && val.cashBillPaidThrough) {
            return val.onlineBillPaidThrough + "(" + val.onlinePay + ") " + val.cashBillPaidThrough + "(" + val.cashPay + ")";
        } else if (val.onlineBillPaidThrough)
            return val.onlineBillPaidThrough;
        else
            return val.cashBillPaidThrough;
    };
    //Function Corner end

    //UI Corner

    const useStyles = makeStyles({
        input: {
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
    });
    const colQuantityClasses = useStyles();


    const handleIncrement = (cellData) => {
        if (isPaid) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Paid Dish Can't be Changed",
                snackBarOpen: true,
            });
        } else {
            let allowed = true;
            let value = cellData.quantity;
            if (isNumberEmpty(value))
                value = 0;
            let quantity = value + 1;
            if (Number(stockSingleData.unitCount) > 0) {
                if (quantity > stockSingleData.unitCount) {
                    allowed = false;
                    setSnpComponent({
                        ...snpComponent,
                        snackBarOpen: true,
                        snackBarMsg: "Exceeds Total Count i.e " + stockSingleData.liveStockCount,
                    });
                }
            }
            if (allowed) {
                let sPrice = 0.0;
                if (cellData.price > 0.0) {
                    quantity = Number(quantity);
                    if (Number(cellData.offerDenom) === OfferType.No_Offer) {
                        sPrice = cellData.price;
                    } else if (Number(cellData.offerDenom) === OfferType.Flat) {
                        sPrice = cellData.price - cellData.offerAmt;
                    } else if (Number(cellData.offerDenom) === OfferType.Percentage) {
                        sPrice = cellData.price
                            - ((cellData.price * cellData.offerAmt) / 100);
                    }
                    //sPrice = sPrice + (((stockSingleData.cgst + stockSingleData.sgst) * sPrice) / 100);
                    sPrice = quantity * sPrice;
                    let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === cellData.prdId);
                    //printLog("handleColQuantityChange =",sPrice,index)
                    let oldPrice = Number(listbillingData.value_2[index].finalprice);
                    listbillingData.value_2[index].finalprice = sPrice;
                    listbillingData.value_2[index].quantity = quantity;
                    listbillingData.value_2[index].print = false;
                    //printLog("handleColQuantityChange <=",listbillingData.value_2[index])
                    doTotal(oldPrice, sPrice, Number(cellData.cgst), Number(cellData.sgst), Number(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, billDetails.offerAmt);
                    setBillingData({
                        ...listbillingData,
                        value_2: listbillingData.value_2,
                        cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
                    });
                }
            }
        }
    };

    const handleDecrement = (cellData) => {
        if (isPaid) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Paid Dish Can't be Changed",
                snackBarOpen: true,
            });
        } else {
            let value = cellData.quantity;
            if (isNumberEmpty(value))
                value = 0;
            if (value > 0) {
                let allowed = true;
                let quantity = value - 1;
                if (quantity < 0) //For Decimal Safety
                    quantity = 0;
                if (Number(stockSingleData.unitCount) > 0) {
                    if (quantity > stockSingleData.unitCount) {
                        allowed = false;
                        setSnpComponent({
                            ...snpComponent,
                            snackBarOpen: true,
                            snackBarMsg: "Exceeds Total Count i.e " + stockSingleData.liveStockCount,
                        });
                    }
                }
                if (allowed) {
                    let sPrice = 0.0;
                    if (cellData.price > 0.0) {
                        quantity = Number(quantity);
                        if (Number(cellData.offerDenom) === OfferType.No_Offer) {
                            sPrice = cellData.price;
                        } else if (Number(cellData.offerDenom) === OfferType.Flat) {
                            sPrice = cellData.price - cellData.offerAmt;
                        } else if (Number(cellData.offerDenom) === OfferType.Percentage) {
                            sPrice = cellData.price
                                - ((cellData.price * cellData.offerAmt) / 100);
                        }
                        //sPrice = sPrice + (((stockSingleData.cgst + stockSingleData.sgst) * sPrice) / 100);
                        sPrice = quantity * sPrice;
                        let index = listbillingData.value_2.findIndex(singleValue => singleValue.prdId === cellData.prdId);
                        //printLog("handleColQuantityChange =",sPrice,index)
                        let oldPrice = Number(listbillingData.value_2[index].finalprice);
                        listbillingData.value_2[index].finalprice = sPrice;
                        listbillingData.value_2[index].quantity = quantity;
                        listbillingData.value_2[index].print = false;
                        //printLog("handleColQuantityChange <=",listbillingData.value_2[index])
                        doTotal(oldPrice, sPrice, Number(cellData.cgst), Number(cellData.sgst), Number(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, billDetails.offerAmt);
                        setBillingData({
                            ...listbillingData,
                            value_2: listbillingData.value_2,
                            cashPayThrough: "", onlinePayMedium: "", cashPay: 0, onlinePay: 0, onlinePayThrough: "",
                        });
                    }
                }
            }
        }
    };


    const columns = [
        {
            field: 'prddets', headerName: 'Item', flex: 1, minWidth: 190,
            renderCell: (val) => {
                let data = val.row.name;
                if (val.row.foodQuantityName)
                    data = data + "(" + val.row.foodQuantityName + ")";
                return data;
            }
        },
        {
            field: 'quantity', headerName: 'Qty', flex: 1, minWidth: 180, editable: true,
            renderCell: (val) => {
                return (
                    <Grid container spacing={0}>
                        <Grid item md={12} sm={12} xs={12} lg={12}>
                            <TextField
                                id="colQuantity"
                                className={colQuantityClasses.input}
                                name="colQuantity"
                                type="number"
                                inputMode="numeric"
                                value={Number(val.row.quantity)}
                                variant="standard"
                                size="small"
                                disabled={isPaid}
                                sx={{
                                    justifyContent: "center", alignContent: "center",
                                    '& .MuiInputBase-input': {
                                        textAlign: "center",
                                    },
                                }}
                                onChange={(e) => handleColQuantityChange(val.row, e)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment>
                                            <IconButton>
                                                <AddIcon color="success" sx={{
                                                    fontSize: "2rem",
                                                }} onClick={() => handleIncrement(val.row)} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment>
                                            <IconButton>
                                                <RemoveIcon color="warning" sx={{
                                                    fontSize: "2rem",
                                                }} onClick={() => handleDecrement(val.row)} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                );
            },
        },
        {
            field: 'savedQuantity', headerName: 'P.Qty', flex: 1, minWidth: 70
        },
        {
            field: 'price', headerName: 'Price', flex: 1,
            renderCell: (val) => {
                return "\u20B9 " + val.row.price;
            }

        },
        // {
        //     field: 'offer', headerName: 'Offer', flex: 1,
        //     renderCell: (val) => {
        //         return (parseInt(val.row.offerDenom) === OfferType.No_Offer) ?
        //             0.0 : (parseInt(val.row.offerDenom) === OfferType.Percentage) ? val.row.offerAmt + "%" : "Flat" + val.row.offerAmt;
        //     }
        // },
        {
            field: 'finalPrice', headerName: 'T. Price', flex: 1,
            renderCell: (val) => {
                return "\u20B9 " + val.row.finalprice;
            }
        },
        {
            field: "select",
            headerName: "Edit",
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <EditIcon disabled={isPaid} style={{ cursor: "pointer" }}
                        onClick={(event) => {
                            if (listbillingData.payLaterSignal)
                                setSnpComponent({
                                    ...snpComponent,
                                    snackBarMsg: "Paid bill cannot be edit(Paid from Hotel Pay)",
                                    snackBarOpen: true,
                                });
                            else
                                handleCellClick(event, cellValues);
                        }}
                    >
                    </EditIcon>
                );
            }
        },
        {
            field: "del",
            headerName: "Del",
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <DeleteIcon style={{ cursor: "pointer" }}
                        onClick={(event) => {
                            if (listbillingData.payLaterSignal)
                                setSnpComponent({
                                    ...snpComponent,
                                    snackBarMsg: "Paid bill cannot be deleted(Paid from Hotel Pay)",
                                    snackBarOpen: true,
                                });
                            else
                                handleCellClick(event, cellValues);
                        }}
                    >
                    </DeleteIcon>
                );
            }
        },
        {
            field: "kot",
            headerName: "KOT",
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <SoupKitchenIcon style={{ cursor: "pointer" }}
                        onClick={(event) => {
                            kotDisplay(cellValues.row.prdId);
                        }}
                    >
                    </SoupKitchenIcon>
                );
            }
        }
    ];
    const BrownTextTypography = withStyles({
        root: {
            color: "#A52A2A"
        }
    })(Typography);


    const renderAddItemContent = () => {
        return (
            <>
                <div>
                    {
                        gridNo === 3 ?
                            <div>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} md={12} sm={12} lg={12} style={{ textAlign: "center" }}>
                                        {stockSingleData.productImage[0] &&
                                            <img
                                                alt=""
                                                style={{ height: "100px", width: "100px", borderRadius: "40%" }}
                                                src={storeApiUrl.BASE_IMAGE_URL + stockSingleData.productImage[0]}
                                            />
                                        }
                                        <br />
                                        <Typography variant="p">
                                            {"Cateogory : " + stockSingleData.catgName}
                                        </Typography>
                                        <br />
                                        <Typography variant="p" className="shop-details resto-price" >{stockSingleData.ingredients}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={12} sm={12} lg={12}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Rate Card</FormLabel>
                                            {/* <RadioGroup aria-label="rate-select" name="rate-select" value={billStockData.priceIndex} onChange={handleInputChange}>
                                                {[...stockSingleData.price].map((data,index) => (
                                                    <div>
                                                        <FormControlLabel control={<Radio
                                                            value={index}
                                                            id={data.sAmt}
                                                        />}
                                                            label="" />
                                                        <Typography variant="p" className="shop-details shop-address" >{billStockData.name + (!isEmpty(data.name) ? "(" + data.name + ")" : "")}</Typography>
                                                        <br /> {"       "}
                                                        <Typography variant="p" className="shop-details resto-price" >Price : &#8377;{data.sAmt}</Typography>
                                                    </div>
                                                ))}
                                            </RadioGroup> */}
                                            <RadioGroup aria-label="rate-select" name="rate-select" value={billStockData.priceIndex} onChange={handleInputChange}>
                                                <div>
                                                    <FormControlLabel control={<Radio
                                                        value={0}
                                                        id={stockSingleData.price.sAmt}
                                                    />}
                                                        label="" />
                                                    <Typography variant="p" className="shop-details shop-address" >{billStockData.name + (!isEmpty(stockSingleData.price.name) ? "(" + stockSingleData.price.name + ")" : "")}</Typography>
                                                    <br /> {"       "}
                                                    <Typography variant="p" className="shop-details resto-price" >Price : &#8377;{stockSingleData.price.sAmt}</Typography>
                                                </div>
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <br />
                                {
                                    itemWiseOffer && <div>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Any Offer</FormLabel>
                                            <RadioGroup row aria-label="offerDenom" name="offerDenom" value={billStockData.offerDenom.toString()} onChange={handleInputChange}>
                                                <FormControlLabel value={OfferType.Flat.toString()} control={<Radio />} label="Discount In Flat" />
                                                <FormControlLabel value={OfferType.Percentage.toString()} control={<Radio />} label="Discount In Percentage" />
                                                <FormControlLabel value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                                            </RadioGroup>
                                        </FormControl>
                                        <br /><br />
                                    </div>
                                }

                                <div>
                                    <Grid container spacing={3}>
                                        {
                                            (itemWiseOffer && billStockData.offerDenom > 1) &&
                                            <Grid item xs={12} md={6} sm={6} lg={6}>
                                                <TextField
                                                    id="offerAmt"
                                                    name="offerAmt"
                                                    variant="filled"
                                                    size="small"
                                                    label="Enter Offer Value"
                                                    value={billStockData.offerAmt.toString()}
                                                    onChange={handleInputChange}
                                                    style={{ width: "100%" }}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={12} md={6} sm={6} lg={6}>
                                            <TextField
                                                id="quantity"
                                                name="quantity"
                                                className={colQuantityClasses.input}
                                                type="number"
                                                inputMode="numeric"
                                                value={billStockData.quantity}
                                                variant="standard"
                                                size="small"
                                                sx={{
                                                    width: "100%",
                                                    justifyContent: "center", alignContent: "center",
                                                    '& .MuiInputBase-input': {
                                                        textAlign: "center",
                                                    },
                                                }}
                                                label="Enter Quantity"
                                                onChange={handleInputChange}
                                                InputProps={{
                                                    min: '0',
                                                    startAdornment: (
                                                        <InputAdornment>
                                                            <IconButton>
                                                                <AddIcon color="success" sx={{
                                                                    fontSize: "2rem",
                                                                }} onClick={() => {
                                                                    let data = billStockData.quantity + 1;
                                                                    if (Number(stockSingleData.unitCount) > 0) {
                                                                        if (data > stockSingleData.unitCount) {
                                                                            setSnpComponent({
                                                                                ...snpComponent,
                                                                                snackBarOpen: true,
                                                                                snackBarMsg: "Exceeds Total Count i.e " + stockSingleData.liveStockCount,
                                                                            });
                                                                        } else {
                                                                            updateStockPrice(billStockData.price, billStockData.offerDenom, billStockData.offerAmt, data, billStockData.priceIndex);
                                                                        }
                                                                    } else {
                                                                        updateStockPrice(billStockData.price, billStockData.offerDenom, billStockData.offerAmt, data, billStockData.priceIndex);
                                                                    }
                                                                }} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment>
                                                            <IconButton>
                                                                <RemoveIcon color="warning" sx={{
                                                                    fontSize: "2rem",
                                                                }} onClick={() => {
                                                                    if (billStockData.quantity > 0) {
                                                                        let data = billStockData.quantity - 1;
                                                                        if (data < 0)
                                                                            data = 0;
                                                                        if (Number(stockSingleData.unitCount) > 0) {
                                                                            if (data > stockSingleData.unitCount) {
                                                                                setSnpComponent({
                                                                                    ...snpComponent,
                                                                                    snackBarOpen: true,
                                                                                    snackBarMsg: "Exceeds Total Count i.e " + stockSingleData.liveStockCount,
                                                                                });
                                                                            } else {
                                                                                updateStockPrice(billStockData.price, billStockData.offerDenom, billStockData.offerAmt, data, billStockData.priceIndex);
                                                                            }
                                                                        } else {
                                                                            updateStockPrice(billStockData.price, billStockData.offerDenom, billStockData.offerAmt, data, billStockData.priceIndex);
                                                                        }
                                                                    }
                                                                }} />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                    <br />
                                </div>
                                <BrownTextTypography variant="p" style={{ color: "#00333E" }}>
                                    Selling Price : {billStockData.quantity}
                                    {(parseInt(billStockData.offerDenom) === OfferType.No_Offer) ?
                                        " x " + billStockData.price + " = "
                                        : (parseInt(billStockData.offerDenom) === OfferType.Flat) ?
                                            " x (" + billStockData.price + " - Flat " + billStockData.offerAmt + ") = "
                                            : " x (" + billStockData.price + " - " + billStockData.offerAmt + "%) = "
                                    }  &#8377; {Number(billStockData.finalprice).toFixed(2)}
                                    <br />
                                    {"(Excluded GST@" + (parseFloat(stockSingleData.sgst) + parseFloat(stockSingleData.cgst)) + "%)"}
                                </BrownTextTypography>
                                <br /><br />
                                <Grid container spacing={0}>
                                    <Grid item md={12} xs={12} lg={12} sm={12}>
                                        <TextField
                                            id="note"
                                            name="note"
                                            variant="filled"
                                            size="small"
                                            rows={3}
                                            multiline
                                            onChange={handleInputChange}
                                            label="Note(If Any)"
                                            value={billStockData.note}
                                            sx={{ width: "100%" }}
                                        />
                                    </Grid>
                                </Grid>
                            </div> :
                            <Box
                                component="form"
                                sx={{
                                    "& .MuiTextField-root": { m: 1, width: "145ch" },
                                }}
                            >
                                <Grid container spacing={0} justifyContent="center"
                                    alignItems="center" sx={{
                                        padding: 0, mb: 2,
                                    }}>
                                    <Grid item md={10} xs={12} sm={10} lg={10}>
                                        <center>
                                            <TextField
                                                required
                                                id="sItem"
                                                name="sItem"
                                                variant="filled"
                                                size="small"
                                                label="Search Item"
                                                onChange={createFilteredList}
                                                style={{ width: "70%" }}
                                            />
                                        </center>
                                    </Grid>
                                    <Grid item md={2} xs={12} sm={2} lg={2}>
                                        <center>
                                            <Button variant="contained" color="primary" onClick={handleRefereshData}>
                                                Refresh Data
                                            </Button>
                                        </center>
                                    </Grid>
                                    <Grid md={3} xs={12} sm={6} lg={2}>
                                        <center>
                                            <FormControlLabel name="selectMultipleItemDialog"
                                                checked={selectMultipleItemDialog} control={<Checkbox />} onChange={() => {
                                                    let pVal = selectMultipleItemDialog;
                                                    setSelectMultipleDialog(!pVal);
                                                }} label={selectMultipleItemDialog ? "Add Single Item" : "Add Multiple Item"} />
                                        </center>
                                    </Grid>


                                </Grid>
                                <Grid container spacing={1} direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start" sx={{
                                        padding: 0, margin: 0,
                                    }}>

                                    {(filteredCatgProductData.length > 0) &&
                                        [...filteredCatgProductData].map(data => (
                                            <Grid item md={filteredCatgProductData.length <= 3 ? 6 : 3} sm={filteredCatgProductData.length <= 4 ? 6 : 4} lg={filteredCatgProductData.length <= 3 ? 6 : 3} xs={6}
                                                key={data.id}>
                                                <div>
                                                    <Tooltip title={data.ingredients}>
                                                        <Paper
                                                            elevation={0}
                                                            autoHeight
                                                            onClick={() => handleItemData(data)}
                                                            className="restaurant-product-data-container"
                                                            sx={{
                                                                borderRadius: 4,
                                                                margin: "2px",
                                                                borderLeft: (data.foodType === FoodType.NON_VEG) ? "5px solid #8a1b1b" : (data.foodType === FoodType.EGG) ? "5px solid #e79a53" : "5px solid #0b3104",
                                                                backgroundColor: "rgba(233, 231, 231, 0.849)",
                                                            }}>

                                                            <center>
                                                                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "14px" }}>{getProductName(data)}<>{getItemSpicyLevel(data)}</>{getLiveStockCount(data)}</p>
                                                            </center>
                                                        </Paper>
                                                    </Tooltip>
                                                </div>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Box>
                    }
                </div>
            </>
        );
    };

    const getItemSpicyLevel = (product) => {
        return (
            <React.Fragment>
                <div>
                    {product.spicy === Spicy.LESS_SPICY &&
                        <img style={{ height: "15px", width: "15px", borderRadius: "0%" }} src={spiceLevel1Logo} alt="Less Spice" />
                    }
                    {product.spicy === Spicy.MEDIUM_SPICY &&
                        <><img style={{ height: "15px", width: "15px", borderRadius: "0%" }} src={spiceLevel1Logo} alt="Medium Spice" /> <img style={{ height: "15px", width: "15px", borderRadius: "0%" }} src={spiceLevel1Logo} alt="Medium Spice" /></>
                    }
                    {product.spicy === Spicy.EXTRA_SPICY &&
                        <img style={{ height: "15px", width: "15px", borderRadius: "0%" }} src={spiceLevel3Logo} alt="Extra Spice" />
                    }
                </div>
            </React.Fragment>
        )
    };

    const getProductName = (data) => {
        let name = data.name;
        if (isEmpty(data.price.name))
            name = name + "(₹" + data.price.sAmt + ")";
        else
            name = name + "(" + data.price.name + "-₹" + data.price.sAmt + ")";
        return name;
    };

    const getLiveStockCount = (data) => {
        if (!isEmpty(data.liveStockCount))
            return data.liveStockCount + " avail.";
        return '';
    };

    const renderClearDues = () => {
        return (
            <React.Fragment>
                <Grid container spacing={1}
                    justifyContent="center"
                    alignItems="center" sx={{
                        padding: 0, margin: 0,
                    }} >
                    <Grid md={7} xs={12} sm={12} >
                        <Typography variant="p" color="#02056e" fontWeight='bold'>Remaining Amt To Paid ₹{billDetails.pendingAmt}</Typography>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <TextField style={{ width: "180px" }} value={partialPay.partialAmt}
                            id="partialAmt" name="partialAmt" disabled={listbillingData.billStatus === BillStatus.PAID
                                || listbillingData.billStatus === BillStatus.HOMEDELIVERYCASHPAY || listbillingData.billStatus === BillStatus.HOMEDELIVERYPAY} label="Enter Amount Paid" variant="filled" size="small" onChange={handlePartialPayChange} />
                    </Grid>
                    <Grid item md={4} xs={12} sm={12}>
                        <center>
                            <Typography variant="p" color="#3e0109" fontWeight='bold'>Current Remaining Dues ₹{billDetails.currDues}</Typography></center>
                    </Grid>

                    <Grid md={12} xs={12} sm={12}>
                        <FormLabel id="paymentMethod"> <p ><u>Payment Methods:</u> </p></FormLabel>
                    </Grid>
                    <Grid item xs={12} md={2} sm={6}>
                        <FormControlLabel disabled={listbillingData.billStatus === BillStatus.PAID} name="onlinePay" value={ONLINEPAY} checked={(!isEmpty(partialPay.onlineBillPaidThrough))} control={<Checkbox />} label="Online" onChange={handlePartialPayChange} />
                    </Grid>
                    <Grid item xs={12} md={5} sm={6}>
                        <Autocomplete
                            id="pOnline-select"
                            options={CART_INITIAL_STATE.onlinePayMethod}
                            autoHighlight
                            variant="filled"
                            size="small"
                            fullWidth
                            disabled={listbillingData.billStatus === BillStatus.PAID || isEmpty(partialPay.onlineBillPaidThrough)}
                            onChange={handleSelectChange}
                            isOptionEqualToValue={(option, value) => option === value}
                            getOptionLabel={(option) => option}
                            value={partialPay.onlineBillPaidThrough}
                            defaultValue={partialPay.onlineBillPaidThrough}
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    {...props}
                                >
                                    {option}
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search Online Pay Method"
                                    inputProps={{
                                        ...params.inputProps,
                                    }}
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} md={5} sm={6}>
                        <TextField
                            id="onlinePayAmt"
                            name="onlinePayAmt"
                            variant="filled"
                            size="small"
                            fullWidth
                            disabled={enableDisableOnlineCashPay()}
                            label="Enter Online Amount"
                            value={partialPay.onlinePay.toString()}
                            onChange={handlePartialPayChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={2} sm={6}>
                        <FormControlLabel name="cashPay" disabled={listbillingData.billStatus === BillStatus.PAID} value={CASHPAY} checked={partialPay.cashBillPaidThrough === CASHPAY} control={<Checkbox />} label="Cash" onChange={handlePartialPayChange} />
                    </Grid>
                    <Grid item xs={12} md={5} sm={6}>
                        <TextField
                            id="cashPayAmt"
                            name="cashPayAmt"
                            variant="filled"
                            size="small"
                            fullWidth
                            disabled={enableDisableOnlineCashPay()}
                            label="Enter Cash Amount"
                            value={partialPay.cashPay.toString()}
                            onChange={handlePartialPayChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} sm={6}>
                        <TextField
                            id="cashTender"
                            name="cashTender"
                            variant="filled"
                            size="small"
                            fullWidth
                            disabled={listbillingData.billStatus === BillStatus.PAID || (isEmpty(partialPay.cashBillPaidThrough))}
                            label="Enter Cash Tender"
                            value={partialPay.cashTendered}
                            onChange={handlePartialPayChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={5} sm={6}>
                        {
                            partialPay.cashReturn > 0.0 &&
                            <Typography variant="subtitle4" component="div" style={{ color: "#A52A2A" }} >Cash Return: ₹{partialPay.cashReturn}</Typography>
                        }
                    </Grid>
                </Grid>
                {billDetails.lpatialAmt.length > 0 &&
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" className="rate_card">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Paid On</TableCell>
                                    <TableCell align="center">Accept By</TableCell>
                                    <TableCell align="center">Amount</TableCell>
                                    <TableCell align="center">Paid Through</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.values(billDetails.lpatialAmt).map((val) => {
                                    return (
                                        <TableRow
                                            className="rate_card--row"
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center"><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{val.paidOn}</p></TableCell>
                                            <TableCell align="center">{val.paidAcceptBy}</TableCell>
                                            <TableCell align="center">{val.partialAmt}</TableCell>
                                            <TableCell align="center"><p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>{getPartialPaidThrough(val)}</p></TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }

            </React.Fragment>
        );
    };

    const renderDialogContent = () => {
        return (
            <>
                {
                    clearDues ? renderClearDues() : renderAddItemContent()
                }
            </>
        );
    };

    const renderDialogAction = () => {
        return (
            <React.Fragment>
                {
                    clearDues && <SnpButton disabled={listbillingData.billStatus === BillStatus.PAID} variant="contained" color="primary" onClick={updatePartial}>
                        Submit
                    </SnpButton>
                }
                {
                    (gridNo < 3) ? null : !isPaid && <SnpButton variant="contained" color="primary" onClick={handleDialogSubmit}>
                        {isEditItem ? "Update" : "Add"}
                    </SnpButton>
                }
                <DismissButton variant="contained" color="primary" onClick={handleDialogClose}>
                    Cancel
                </DismissButton>
            </React.Fragment>
        );
    };

    const custCopyUi = () => {
        return (
            <React.Fragment>
                <Typography variant="subtitle4" component="div" style={{ color: "#2f1160" }} >Do You Want to Print Customer Copy</Typography>
            </React.Fragment>
        )
    };

    const custCopyAction = () => {
        return (
            <React.Fragment>
                <SnpButton variant="contained" color="primary" onClick={() => {
                    raiseInvoiceClicked(printDetails.custPrint, "cust");
                    fetchBill(listbillingData.billNo, null);
                }}>
                    Yes
                </SnpButton>
                <DismissButton variant="contained" color="primary" onClick={() => { setCustCopy(false); fetchBill(listbillingData.billNo, null); }}>
                    No
                </DismissButton>
            </React.Fragment>
        )
    };
    const custCopyFunc = () => {
        return < DialogBox
            showDialog={custCopy}
            title="Alert!"
            onClose={() => { setCustCopy(false); fetchBill(listbillingData.billNo, null); }}
            dialogContent={custCopyUi()}
            dialogActions={custCopyAction()}
        ></DialogBox>
    };



    const customerInputDialogActions = () => {
        return (
            <React.Fragment>
                <SnpButton variant="contained" color="primary" onClick={addCustomerInput}>
                    Add
                </SnpButton>
                <DismissButton variant="contained" color="primary" onClick={customerInputDialogClose}>
                    Cancel
                </DismissButton>
            </React.Fragment>
        )
    }

    const customerInputFields = () => {
        return (
            <div className="billing-cust-input-data">
                <Grid container spacing={1}>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer Name" variant="filled" size="small" required onChange={e => setBillingData({ ...listbillingData, custName: e.target.value })} sx={{ width: "100%" }} />
                    </Grid><Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer Mobile Number" variant="filled" size="small" disabled={listbillingData.custMob.length > 0} required value={listbillingData.custMob}
                            onChange={e => setBillingData({ ...listbillingData, custMob: e.target.value })} sx={{ width: "100%" }} />
                    </Grid><Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer Email Address" variant="filled" size="small" onChange={e => setBillingData({ ...listbillingData, custEmail: e.target.value })} sx={{ width: "100%" }} />
                    </Grid><Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer Address" variant="filled" size="small" onChange={e => setBillingData({ ...listbillingData, address: e.target.value })} sx={{ width: "100%" }} />
                    </Grid><Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer GST Details" variant="filled" size="small" onChange={e => setBillingData({ ...listbillingData, custGstDetails: e.target.value })} sx={{ width: "100%" }} />
                    </Grid><Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField id="outlined-basic" label="Customer Company Details" variant="filled" size="small" onChange={e => setBillingData({ ...listbillingData, custCompanyName: e.target.value })} sx={{ width: "100%" }} />
                    </Grid></Grid>
            </div>
        )
    }

    const inputCustomerDetails = () => {
        return (
            <DialogBox
                showDialog={customerInputDialog.custInputopen}
                title="Enter Customer Details"
                onClose={customerInputDialogClose}
                dialogContent={customerInputFields()}
                dialogActions={customerInputDialogActions()}
            >
            </DialogBox>

        )
    }

    const applyRoyaltyPrice = () => {
        if (listbillingData.value_2.length > 0) {
            setCustomerInputDialog({ ...customerInputDialog, getRoyaltyDiscount: true });
            if (billDetails.isPointAdded) {
                doTotal(0.0, 0.0, parseFloat(billDetails.cgst), parseFloat(billDetails.sgst), 0.0, billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, billDetails.offerAmt)
            } else {
                doTotal(0.0, 0.0, parseFloat(billDetails.cgst), parseFloat(billDetails.sgst), parseFloat(listbillingData.pointRedeem), billDetails.isGstAllowed, delvCharge, billDetails.offerDenom, billDetails.offerAmt)
            }
        }
    }
    const getTableName = () => {
        let data = billUtils.tableNo;
        if (billUtils.tableName)
            data = "Table: " + billUtils.tableName + "(" + billUtils.tableNo + ")";
        else
            data = "Table No: " + billUtils.tableNo + "";
        return data;
    };

    const shopDetails = () => {
        return (
            <div className="billing-data-container">
                <Typography variant="p" component="div" className="shop-details" >Paid To :</Typography>
                <Typography variant="p" component="div" className="shop-name"
                >{storeActiveShopHash.shop} </Typography>
                {
                    (hotelSale && billUtils.billingType !== InternalBillType.Home) && <Button
                        size="small"
                        variant="contained" color="primary" onClick={() => setHotelRoomDialog(true)}>
                        {isEmpty(listbillingData.hotelBillNo) ? "Search Room" : "Room No : " + listbillingData.hotelBillNo}
                    </Button>
                }
                <Typography variant="p" component="div" className="shop-details shop-gst" >{storeActiveShopHash.gstDetail} </Typography>
                {/* <Typography variant="p" component="div" className="shop-details shop-address" >{storeActiveShopHash.location}</Typography>
                <Typography variant="p" component="div" className="shop-details shop-gst" >{storeActiveShopHash.gstDetail} </Typography> */}
                {
                    billUtils.tableId &&
                    <Typography variant="p" component="div" className="shop-details table-no" >{getTableName()} </Typography>
                }
                {renderDialogHotelRoom()}
            </div>
        )
    }

    const handleSetDate = (event) => {
        const formatDateVal = formatDateInSnp(event.target.value, listbillingData.createdAt);
        //printLog("handleSetDate",name,value,formatDateVal);
        setBillingData({
            ...listbillingData,
            createdAt: formatDateVal,
        })
    };
    const dialogDateChangeContent = () => {
        return (
            <React.Fragment>
                <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{ p: 1 }}>
                    <Grid item xs={12} md={12} sm={12} lg={12}>
                        <TextField
                            type="date"
                            id="createdAt"
                            name="createdAt"
                            variant="filled"
                            size="small"
                            disabled={!backDateAllowed}
                            label="Enter Previous Date"
                            value={formatDateInReact(listbillingData.createdAt)}
                            onChange={handleSetDate}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputProps: {
                                    min: '2000-01-01',
                                    max: formatDateInReact(todayFormattedDate),
                                },
                            }} />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    const dialogDateChangeAction = () => {
        return (
            <React.Fragment>
                <SnpButton variant="contained" color="primary" onClick={() => setDateChange(false)}>
                    Change
                </SnpButton>
                <DismissButton variant="contained" color="primary" onClick={() => {
                    setDateChange(false);
                    setBillingData({
                        ...listbillingData,
                        createdAt: todayFormattedDate,
                    });
                }}>
                    Dismiss
                </DismissButton>
            </React.Fragment>
        )
    }

    const renderDialogDateChange = () => {
        return < DialogBox
            showDialog={dateChange}
            title=""
            // onClose={customerInputDialogClose}
            dialogContent={dialogDateChangeContent()}
            dialogActions={dialogDateChangeAction()}
        ></DialogBox>
    };



    const dialogHotelRoomContent = () => {
        return (
            <>
                <div> {
                    hotelDialogLoader ? <Loader /> : <Grid container spacing={0} display="flex" alignItems="center" sx={{
                        m: 1, p: 1
                    }} >
                        {
                            !roomDetails.saved && <Grid md={12} xs={12} sm={12}>
                                <TextField
                                    id="searchRoom"
                                    name="searchRoom"
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                    label="Enter Room No"
                                    defaultValue={roomDetails.roomNo}
                                    onChange={(e) => setRoomDetails({
                                        ...roomDetails, roomNo: e.target.value,
                                    })}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton>
                                                    <SearchIcon onClick={checkRoom} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        }
                        {
                            roomDetails.saved &&
                            <Grid md={12} xs={12} sm={12}>
                                <Typography variant="p" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>Room No :{roomDetails.roomNo}</Typography>
                            </Grid>
                        }
                        <Grid md={12} xs={12} sm={12}>
                            <Typography variant="p" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>Room Book Status :{roomDetails.roomBooked ? "Booked" : "Empty"}</Typography>
                        </Grid>
                        {
                            roomDetails.custName && <Grid md={12} xs={12} sm={12}>
                                <Typography variant="p" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Customer Name :{roomDetails.roomBooked ? roomDetails.custName : ""}</Typography>
                            </Grid>
                        }

                    </Grid>
                }
                </div>
            </>
        );
    };

    const dialogHotelRoomAction = () => {
        return (
            <>
                {
                    (!roomDetails.saved && !isEmpty(roomDetails.roomNo) && roomDetails.roomBooked) && <SnpButton variant="contained" color="primary" onClick={() => {
                        setHotelRoomDialog(false);
                        setBillingData({
                            ...listbillingData, hotelBillId: roomDetails.hotelBillId,
                            hotelBillNo: roomDetails.roomNo,
                            custMob: roomDetails.mobNo, custName: roomDetails.custName,
                            custEmail: roomDetails.emailId, address: roomDetails.address
                        });
                        setCustomerInputDialog({ ...customerInputDialog, custInputAdd: true, redeemRoyaltyPrice: false })
                        setIsCustDataAvailable(false);
                    }}>
                        Set
                    </SnpButton>
                }
                {
                    (!roomDetails.saved && !isEmpty(roomDetails.roomNo)) && <DismissButton variant="contained" color="primary" onClick={() => {
                        setRoomDetails(INITIALROOMDETAIS);
                        setBillingData({
                            ...listbillingData, hotelBillId: "",
                            hotelBillNo: "",
                            custMob: "", custName: "",
                            custEmail: "", address: ""
                        });
                        setCustomerInputDialog({ ...customerInputDialog, custInputAdd: false, redeemRoyaltyPrice: false })
                        setIsCustDataAvailable(true);
                        setHotelRoomDialog(false);
                    }}>
                        Clear
                    </DismissButton>
                }
                {
                    (roomDetails.saved && !isEmpty(roomDetails.roomNo) && !listbillingData.payLaterSignal && listbillingData.billStatus !== BillStatus.PAID) && <SnpButton variant="contained" color="primary" onClick={() => {
                        removeRoomApi();
                        setHotelRoomDialog(false);
                    }}>
                        Remove Room
                    </SnpButton>
                }
                <DismissButton variant="contained" color="primary" onClick={() => {
                    if (!roomDetails.saved)
                        setRoomDetails(INITIALROOMDETAIS);
                    setHotelRoomDialog(false);
                }}>
                    Dismiss
                </DismissButton>
            </>
        )
    }

    const renderDialogHotelRoom = () => {
        return < DialogBox
            showDialog={hotelRoomDialog}
            title="Room Details"
            // onClose={customerInputDialogClose}
            dialogContent={dialogHotelRoomContent()}
            dialogActions={dialogHotelRoomAction()}
        ></DialogBox>
    };


    const renderHeaderForm = () => {
        return (
            <div className="input-bill-number">
                <Grid container spacing={0} display="flex" alignItems="center" sx={{
                    m: 0, p: 0
                }} >
                    <Grid item md={12} xs={12} sm={12}>
                        <center>
                            <Typography variant="p" component="div" className="bill-header" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "12px" }} onClick={(e) => setDateChange(true)}>Date : {listbillingData.createdAt} </Typography>
                        </center>
                    </Grid>
                    <Grid md={1} xs={1} sm={1}>
                        <center>
                            <Tooltip title="Refresh Bill">
                                <RefreshIcon onClick={(e) => fetchBill(listbillingData.billNo, null)} />
                            </Tooltip>
                        </center>
                    </Grid>
                    <Grid item md={6} xs={11} sm={6}>
                        <Typography variant="p" component="div" className="bill-header" > {getBillTypeName()}</Typography>
                    </Grid>

                    {
                        !isEmpty(listbillingData.billNo) &&
                        <Grid item md={5} xs={12} sm={6}>
                            <Typography variant="p" component="div" className="bill-header">Invoice No: {listbillingData.billNo}</Typography>
                        </Grid>
                    }
                </Grid>
            </div>
        );
    };
    const renderCustomerForm = () => {
        return (
            <React.Fragment>
                <div className="billing-data-grid-container">
                    {shopDetails()}
                    {
                        (!isPaid) && <div className="billing-data-container">

                            <Typography variant="p" component="div" className="existing-customer-search" style={{ marginBottom: "10px" }}>Search for Customer :</Typography>
                            <TextField id="outlined-basic" label="Mobile Number" variant="filled" size="small" className="existing-customer__mobile-number" style={{ marginBottom: "15px" }} onChange={getCustomerData} disabled={isCustLoading ? true : false} />
                            <span style={{ verticalAlign: "middle" }}>
                                {isCustLoading ? <CircularProgress color="primary" size={30} /> : ''}
                            </span>

                        </div>
                    }
                    {(isCustDataAvailable || customerInputDialog.custInputAdd) &&
                        <div className="billing-data-container">
                            <Typography variant="p" component="div" className="existing-customer__error" style={{ whiteSpace: "initial" }}>{isCustDataAvailable ? ("Customer Not Found!! Please make a new entry.") : ''}</Typography>
                            {inputCustomerDetails()}
                            {customerInputDialog.custInputAdd &&
                                <div className="customer-details-container">
                                    <Typography variant="p" component="div" className="customer-details" >Bill To : </Typography>
                                    <Typography variant="p" component="div" className="customer-name" >{listbillingData.custName} </Typography>
                                    <Typography variant="p" component="div" className="customer-details customer-mob-num" >{listbillingData.custMob} </Typography>
                                    <Typography variant="p" component="div" className="customer-details customer-email-id" style={{ whiteSpace: "initial", wordBreak: 'break-word' }}>{listbillingData.custEmail}</Typography>
                                </div>
                            }
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    };

    const getPartialPayButtonName = () => {
        let name = "";
        if (listbillingData.billStatus === BillStatus.PARTIALLYPAID) {
            name = "Clear Dues";
            if (billDetails.pendingAmt > 0)
                name = name + " (₹ " + billDetails.pendingAmt + ")";
        } else {
            name = "Partial Pay";
        }
        return name;
    };

    const [kotNo, setKotNo] = useState('');
    const buildKotEnterData = () => {
        return (
            <TextField
                id="kotNo"
                name="kotNo"
                type="number"
                variant="filled"
                size="small"
                label={"Enter Valid KOT No(1 to " + listbillingData.kot + ")"}
                defaultValue={kotNo}
                onChange={(e) => setKotNo(e.target.value)}
                style={{ width: "100%" }}
            />
        );
    };
    const buildDelItemUi = () => {
        return (
            <React.Fragment>
                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }}>{"Do you want to delete " + delItemDialog.prdName}</p>
                <TextField
                    id="delReason"
                    required
                    name="delReason"
                    variant="filled"
                    size="small"
                    label={"Enter Reason For Delete"}
                    defaultValue={delItemDialog.delReason}
                    onChange={(e) => setDelItemDialog({
                        ...delItemDialog, delReason: e.target.value,
                    })}
                    style={{ width: "100%" }}
                />
            </React.Fragment>
        );
    };

    const buildDelItemAction = () => {
        return (
            <>
                <SnpButton variant="contained" color="primary" onClick={() => deleteFromBill(delItemDialog.prdId)}>
                    Delete
                </SnpButton>
                <DismissButton variant="contained" color="primary" onClick={() => setDelItemDialog(INITIAL_DEL_ITEM)}>
                    Dismiss
                </DismissButton>
            </>
        )
    };

    const renderDialogDeleteItem = () => {
        return < DialogBox
            showDialog={delItemDialog.showDialog}
            title="Delete Item"
            dialogContent={buildDelItemUi()}
            dialogActions={buildDelItemAction()}
        ></DialogBox>
    };

    const buildGenOtpUi = () => {
        return (
            <TextField
                id="otp"
                name="otp"
                variant="filled"
                size="small"
                label={"Enter OTP to Generate Bill"}
                defaultValue={genOtpDialog.otp}
                onChange={(e) => setGenOtpDialog({
                    ...genOtpDialog, otp: e.target.value,
                })}
                style={{ width: "100%" }}
            />
        );
    };

    const buildGenOtpAction = () => {
        return (
            <>
                <SnpButton variant="contained" color="primary" onClick={generateBill}>
                    Generate Bill
                </SnpButton>
                <DismissButton variant="contained" color="primary" onClick={() => setGenOtpDialog(INITIAL_GENERATE_DIALOG)}>
                    Dismiss
                </DismissButton>
            </>
        )
    };

    const renderDialogGenOtp = () => {
        return < DialogBox
            showDialog={genOtpDialog.showDialog}
            title="OTP For Generate Bill"
            dialogContent={buildGenOtpUi()}
            dialogActions={buildGenOtpAction()}
        ></DialogBox>
    };


    const dialogPrintKotNoAction = () => {
        return (
            <>
                <SnpButton variant="contained" color="primary" onClick={() => printKotNoWise(kotNo)}>
                    Print
                </SnpButton>
                <DismissButton variant="contained" color="primary" onClick={() => {
                    setPrintKotNoDialog(false);
                    setKotNo('');
                }}>
                    Dismiss
                </DismissButton>
            </>
        )
    }

    const renderDialogPrintKotNo = () => {
        return < DialogBox
            showDialog={printKotNoDialog}
            title="Print Kot By No"
            // onClose={customerInputDialogClose}
            dialogContent={buildKotEnterData()}
            dialogActions={dialogPrintKotNoAction()}
        ></DialogBox>
    };

    const generateDynamicHTML = (k, s, g, blNo) => {
        let myBill = listbillingData.billNo;
        if (!isEmpty(blNo))
            myBill = blNo;
        let dynHtml =
            "print://snpnextgen.com/snp/a2z/print?key1=" + CART_INITIAL_STATE.apiToken + "&key2=" + storeActiveShopHash.id + "&key3=" + myBill + "&k1=" + k + "&s1=" + s + "&g1=" + g;
        window.location.href = dynHtml;
    }

    const renderBillingScreenLeft = () => {
        return (
            <React.Fragment>
                <div>
                    <div>{renderDialogPrintKotNo()}</div>
                    <div>{renderDialogDeleteItem()}</div>
                    <div>{renderDialogGenOtp()}</div>
                    <div>{renderHeaderForm()}</div>
                    <div>{renderCustomerForm()}</div>
                    {
                        backDateAllowed && <div>{renderDialogDateChange()}</div>
                    }
                    <div>{custCopyFunc()}</div>
                    <div className="billing-cust-button-data">
                        {(customerInputDialog.redeemRoyaltyPrice && loyaityPoints) &&
                            <Button variant="contained" color="primary" onClick={applyRoyaltyPrice}>
                                {billDetails.isPointAdded ? "Remove Loyality Price" : "Redeem Loyality Price"}
                            </Button>
                        }
                        {
                            (isRoleAllowed(ADMINRIGHTS) && delReason && listbillingData.delItems.length !== 0) && <Button variant="contained" color="primary" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} sx={{
                                mr: 2,
                            }} onClick={() => {
                                setSnpComponent({
                                    ...snpComponent,
                                    isLoader: false,
                                    negativeReq: true,
                                    negativeAction: "Dismiss",
                                    positiveReq: false,
                                    showDialog: true,
                                    outSideClickDismiss: true,
                                    cancelExtra: true,
                                    requestType: 99,
                                    handleSnpCancel: () => setSnpComponent(INITIAL_SNP_COMPONENT),
                                    gui: buildDelItemDisplayData(listbillingData.delItems),
                                })
                            }
                            }>
                                Show Deleted Items
                            </Button>
                        }
                        {(isCustDataAvailable && !isPaid) && <Button variant="contained" className="new-customer-entry" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} onClick={event => setCustomerInputDialog({ ...customerInputDialog, custInputopen: true })}>Add New Customer</Button>}
                    </div>
                    < DialogBox
                        showDialog={showItemDialog}
                        title={clearDues ? "Partial Bill Update" : (isEditItem ? "Update Item" : "Add Item")}
                        onClose={handleDialogClose}
                        dialogContent={renderDialogContent()}
                        dialogActions={renderDialogAction()}
                    ></DialogBox>
                    <div>
                        <CustomDataGrid
                            getRowId={row => row.prdId}
                            rows={listbillingData.value_2}
                            columns={columns}
                        />
                    </div>
                    <div>
                        <Grid container spacing={0} className="summary summary-section">
                            <Grid item md={9} xs={12} lg={9} sm={7}>
                                <Grid container spacing={0.5}>
                                    <Grid item md={12} xs={12} lg={12} sm={12}>
                                        <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '23px' }} ><u>Bill Option:</u></Typography>
                                    </Grid>
                                    <Grid item md={2} xs={12} lg={2} sm={4}>
                                        <FormControlLabel
                                            name="isGstAllowed"
                                            disabled={isPaid || !isRoleAllowed(SALESRIGHTS)}
                                            checked={billDetails.isGstAllowed} control={<Checkbox />} onChange={handleInputChange}
                                            label={
                                                <Box component="div" fontSize={15}>
                                                    {billDetails.isGstAllowed ? "Disable GST" : "Enable GST"}
                                                </Box>
                                            } />
                                    </Grid>
                                    <Grid item xs={12} md={10} lg={10} sm={8}>
                                        <FormControl>
                                            <FormLabel id="billOffer">Any Offer :</FormLabel>
                                            <RadioGroup row aria-label="bofferDenom" disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} name="bofferDenom" value={billDetails.offerDenom.toString()} onChange={handleInputChange}>
                                                <FormControlLabel disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} value={OfferType.No_Offer.toString()} control={<Radio />} label="No Offer" />
                                                <FormControlLabel disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} value={OfferType.Flat.toString()} control={<Radio />} label="Flat" />
                                                <FormControlLabel disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} value={OfferType.Percentage.toString()} control={<Radio />} label="In Percentage" />
                                                {
                                                    (billDetails.offerDenom > 1) &&
                                                    <div>
                                                        <TextField
                                                            id="bofferAmt"
                                                            name="bofferAmt"
                                                            variant="filled"
                                                            size="small"
                                                            disabled={isPaid || !isRoleAllowed(SALESRIGHTS)}
                                                            label="Enter Offer Value"
                                                            value={billDetails.offerAmt}
                                                            onChange={handleInputChange}
                                                            style={{ width: "60%" }}
                                                        />
                                                    </div>
                                                }
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    {(billUtils.billingType === InternalBillType.Home) &&
                                        <Grid item md={isOthHomeDelv ? 4 : 6} xs={12} lg={isOthHomeDelv ? 4 : 6} sm={6}>
                                            <Autocomplete
                                                id="home-select"
                                                options={CART_INITIAL_STATE.homeDelvMethod}
                                                autoHighlight
                                                variant="filled"
                                                size="small"
                                                disabled={isPaid || !isRoleAllowed(SALESRIGHTS)}
                                                style={{ width: "90%", maxHeight: "20%" }}
                                                onChange={handleDelvSelectChange}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                getOptionLabel={(option) => option}
                                                defaultValue={paymentDetails.delvName}
                                                renderOption={(props, option) => (
                                                    <Box
                                                        component="li"
                                                        {...props}
                                                    >
                                                        {option}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Select Home Delivery"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    }
                                    {
                                        isOthHomeDelv &&
                                        <Grid item md={4} xs={12} lg={4} sm={6}>
                                            <TextField
                                                id="delvNameOth"
                                                name="delvNameOth"
                                                variant="filled"
                                                size="small"
                                                disabled={isPaid || !isRoleAllowed(SALESRIGHTS)}
                                                label="Enter Other Delivery Name"
                                                value={delvNameOth}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                    }
                                    {!isEmpty(paymentDetails.delvName) &&
                                        <Grid item md={isOthHomeDelv ? 4 : 6} xs={12} lg={isOthHomeDelv ? 4 : 6} sm={6}>
                                            <TextField
                                                id="delvCharge"
                                                name="delvCharge"
                                                variant="filled"
                                                size="small"
                                                disabled={isPaid || !isRoleAllowed(SALESRIGHTS)}
                                                label="Enter Delivery Amount"
                                                value={delvCharge}
                                                onChange={handleInputChange}
                                            />
                                        </Grid>
                                    }
                                    {
                                        (hotelDelvTxn && !isEmpty(paymentDetails.delvName)) &&
                                        <Grid item md={6} xs={12} lg={6} sm={6}>
                                            <TextField
                                                id="homeDelvId"
                                                name="homeDelvId"
                                                variant="filled"
                                                size="small"
                                                disabled={isPaid || !isRoleAllowed(SALESRIGHTS)}
                                                label="Enter Delivery OrderId"
                                                value={listbillingData.homeDelvId}
                                                onChange={(e) => setBillingData({
                                                    ...listbillingData, homeDelvId: e.target.value,
                                                })}
                                            />
                                        </Grid>
                                    }
                                    {
                                        (hotelDelvTxn && !isEmpty(paymentDetails.delvName)) &&
                                        <Grid item md={6} xs={12} lg={6} sm={6}>
                                            <TextField
                                                id="homeDelvOtp"
                                                name="homeDelvOtp"
                                                variant="filled"
                                                size="small"
                                                disabled={isPaid || !isRoleAllowed(SALESRIGHTS)}
                                                label="Enter Delivery OTP"
                                                value={listbillingData.homeDelvOtp}
                                                onChange={(e) => setBillingData({
                                                    ...listbillingData, homeDelvOtp: e.target.value,
                                                })}
                                            />
                                        </Grid>
                                    }
                                    {listbillingData.tripUpdateReq &&
                                        <Grid item xs={12} md={6} sm={6} lg={6}>
                                            <Autocomplete
                                                id="trip-select"
                                                options={TRIP_STATUS}
                                                disabled={TRIP_DELIVERED === listbillingData.pTripStatus}
                                                autoHighlight
                                                variant="filled"
                                                size="small"
                                                onChange={(e, val) => {
                                                    printDebugLog("trip-select", val);
                                                    if (isEmpty(listbillingData.pTripStatus)) {
                                                        //When Trip is not assigned
                                                        setBillingData({
                                                            ...listbillingData, tripStatus: isEmpty(val) ? listbillingData.pTripStatus : val,
                                                        })
                                                    } else {
                                                        const mCurrentValue = TRIP_STATUS_BACK.find(item => item.name === val);
                                                        if (listbillingData.pTripStatus === TRIP_RUNNING && mCurrentValue.no < 5) {
                                                            //Hardcoded 5 as TRIP_RUNNING index is 5, if TRIP_RUNNING and current val is going back so not allowed
                                                            setSnpComponent({
                                                                ...snpComponent,
                                                                isLoader: false,
                                                                negativeReq: true,
                                                                negativeAction: "Ok!",
                                                                positiveReq: false,
                                                                showDialog: true,
                                                                outSideClickDismiss: false,
                                                                cancelExtra: true,
                                                                requestType: 99,
                                                                subTitle: "Cannot Update previous Trip status(i.e." + mCurrentValue.name + ") Current Status: " + listbillingData.pTripStatus,
                                                                handleSnpCancel: () => {
                                                                    setSnpComponent(INITIAL_SNP_COMPONENT);
                                                                    fetchBill(listbillingData.billNo, null);
                                                                },
                                                            });

                                                        } else {
                                                            const myPreviousValue = TRIP_STATUS_BACK.find(item => item.name === listbillingData.pTripStatus);
                                                            if (myPreviousValue && myPreviousValue.no > mCurrentValue.no) {
                                                                //Here if My Current Value is less than My Previous value
                                                                setSnpComponent({
                                                                    ...snpComponent,
                                                                    isLoader: false,
                                                                    negativeReq: true,
                                                                    negativeAction: "Ok!",
                                                                    positiveReq: false,
                                                                    showDialog: true,
                                                                    outSideClickDismiss: false,
                                                                    cancelExtra: true,
                                                                    requestType: 99,
                                                                    subTitle: "Cannot Update previous Trip status(i.e." + mCurrentValue.name + ") Current Status: " + listbillingData.pTripStatus,
                                                                    handleSnpCancel: () => {
                                                                        setSnpComponent(INITIAL_SNP_COMPONENT);
                                                                        fetchBill(listbillingData.billNo, null);
                                                                    },
                                                                });

                                                            } else {
                                                                setBillingData({
                                                                    ...listbillingData, tripStatus: isEmpty(val) ? listbillingData.pTripStatus : val,
                                                                })
                                                            }
                                                        }
                                                    }
                                                }}
                                                style={{ width: "90%", maxHeight: "20%" }}
                                                isOptionEqualToValue={(option, value) => option === value}
                                                getOptionLabel={(option) => option}
                                                defaultValue={listbillingData.tripStatus}
                                                renderOption={(props, option) => (
                                                    <Box
                                                        component="li"
                                                        {...props}
                                                    >
                                                        {option}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Update Trip Status"
                                                        placeholder="Select Trip Status"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    }
                                    {/* https://mui.com/material-ui/react-autocomplete/ */}
                                    {(
                                        listbillingData.tripStatus === TRIP_ASSIGNED
                                        || listbillingData.tripStatus === TRIP_RUNNING
                                        || listbillingData.tripStatus === TRIP_DELIVERED
                                    ) &&
                                        <Grid item xs={12} md={6} sm={6} lg={6}>
                                            <Autocomplete
                                                id="rider-select"
                                                options={riders}
                                                autoHighlight
                                                disabled={TRIP_DELIVERED === listbillingData.pTripStatus}
                                                variant="filled"
                                                size="small"
                                                aria-label="Hello"
                                                label="Hello"
                                                onChange={(e, val) => {
                                                    //console.log("riders",val)
                                                    setRiderDetails({
                                                        ...riderDetails, id: (val === null) ? "" : val.id,
                                                        name: (val === null) ? "" : val.name,
                                                    })

                                                    setBillingData({
                                                        ...listbillingData, riderId: (val === null) ? "" : val.id,
                                                        riderIdName: (val === null) ? "" : val.name,
                                                    })
                                                }}
                                                style={{ width: "90%", maxHeight: "20%" }}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                getOptionLabel={(option) => option.name}
                                                value={riderDetails}
                                                renderOption={(props, option) => (
                                                    <Box
                                                        component="li"
                                                        {...props}
                                                    >
                                                        {option.name + "(" + option.mob + ")"}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Assigned Rider"
                                                        helperText={isEmpty(listbillingData.assignedByName) ? "" : "Assigned By " + listbillingData.assignedByName}
                                                        placeholder="Select Rider"
                                                        inputProps={{
                                                            ...params.inputProps,
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    }
                                    <Grid item md={12} xs={12} sm={12}>
                                        <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '23px' }} ><u>Payment Methods:</u></Typography>
                                    </Grid>
                                    <Grid item xs={12} md={2} sm={6}>
                                        <FormControlLabel disabled={isPaid || !isRoleAllowed(SALESRIGHTS)} name="onlinePay" value={ONLINEPAY} checked={isOnlinePayEnabled} control={<Checkbox />} label="Online" onChange={handleInputChange} />
                                    </Grid>
                                    <Grid item xs={12} md={5} sm={6}>
                                        <Autocomplete
                                            id="online-select"
                                            options={CART_INITIAL_STATE.onlinePayMethod}
                                            autoHighlight
                                            variant="filled"
                                            size="small"
                                            fullWidth
                                            isOptionEqualToValue={(option, value) => option === value}
                                            disabled={isPaid || !isOnlinePayEnabled || !isRoleAllowed(SALESRIGHTS)}
                                            style={{ width: "70%", maxHeight: "20%" }}
                                            onChange={handleOnlineSelectChange}
                                            getOptionLabel={(option) => option}
                                            value={paymentDetails.onlinePayMedium}
                                            renderOption={(props, option) => (
                                                <Box
                                                    component="li"
                                                    {...props}
                                                >
                                                    {option}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Search Online Pay Method"
                                                    inputProps={{
                                                        ...params.inputProps,
                                                    }}
                                                />
                                            )}
                                        />

                                    </Grid>
                                    <Grid item xs={12} md={5} sm={6}>
                                        <TextField
                                            id="onlinePayAmt"
                                            name="onlinePayAmt"
                                            variant="filled"
                                            size="small"
                                            style={{ width: "70%", maxHeight: "20%" }}
                                            disabled={(isPaid || !isOnlinePayEnabled || !isCashPayEnabled) || !isRoleAllowed(SALESRIGHTS)}
                                            label="Enter Online Amount"
                                            value={paymentDetails.onlinePay.toString()}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2} sm={6}>
                                        <FormControlLabel name="cashPay" disabled={(isPaid || billUtils.billingType === 3) || !isRoleAllowed(SALESRIGHTS)} value={CASHPAY} checked={isCashPayEnabled} control={
                                            <Checkbox />
                                        } label="Cash" onChange={handleInputChange} />
                                    </Grid>
                                    <Grid item xs={12} md={5} sm={6}>
                                        <TextField
                                            id="cashPayAmt"
                                            name="cashPayAmt"
                                            variant="filled"
                                            size="small"
                                            style={{ width: "70%" }}
                                            disabled={(isPaid || !isOnlinePayEnabled || !isCashPayEnabled) || !isRoleAllowed(SALESRIGHTS)}
                                            label="Enter Cash Amount"
                                            value={paymentDetails.cashPay.toString()}
                                            onChange={handleInputChange}
                                        />
                                    </Grid>
                                </Grid>
                                {
                                    (!isEmpty(roomDetails.roomNo) && listbillingData.payLaterSignal) &&
                                    <Grid md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }}><u>** Note : Payment of this bill will take care by Hotel Payment **</u></p>
                                        </center>
                                    </Grid>
                                }
                                {
                                    (!isEmpty(listbillingData.transactionId)) &&
                                    <Grid md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }}><u>** Transaction Id : {listbillingData.transactionId} **</u></p>
                                        </center>
                                    </Grid>
                                }
                                {
                                    (listbillingData.billStatus === BillStatus.HOMEDELIVERYINITIATED) &&
                                    <Grid md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }}><u>** Failed to Initiate Pay By Customer,You can delete the bill **</u></p>
                                        </center>
                                    </Grid>
                                }
                                {
                                    (listbillingData.billStatus === BillStatus.HOMEDELIVERYCASHPAY) &&
                                    <Grid md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }}><u>** Customer Selected Cash on Delivery **</u></p>
                                        </center>
                                    </Grid>
                                }
                                {
                                    (listbillingData.billStatus === BillStatus.PARTIALLYPAID) &&
                                    <Grid md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px', color: "#2f1160" }}><u>** Partially Paid. To Check status click on Partial Pay/Clear Dues **</u></p>
                                        </center>
                                    </Grid>
                                }
                                {
                                    (listbillingData.billStatus === BillStatus.PAID && listbillingData.settleDown) &&
                                    <Grid md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px' }}><u>** Full Paid & Settled **</u></p>
                                        </center>
                                    </Grid>
                                }
                                {
                                    (listbillingData.billStatus === BillStatus.PAID && !listbillingData.settleDown) &&
                                    <Grid md={12} xs={12} sm={12} lg={12}>
                                        <center>
                                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '16px', color: "#641906" }}><u>** Full Paid & But not settled. Please settle the bill **</u></p>
                                        </center>
                                    </Grid>
                                }
                            </Grid>
                            <Grid item md={3} xs={12} lg={3} sm={5}>
                                <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '23px' }} ><u>Bill Summary:</u></Typography>
                                <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Sub Total: ₹{billDetails.subTotal}</Typography>
                                {
                                    (billDetails.offerDenom === OfferType.Flat) &&
                                    <Typography variant="subtitle4" component="div" style={{ color: "rgb(189, 116, 67)", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Offer Applied {"(- Flat )"} {billDetails.offerAmt}</Typography>
                                }
                                {
                                    (billDetails.offerDenom === OfferType.Percentage) &&
                                    <Typography variant="subtitle4" component="div" style={{ color: "rgb(189, 116, 67)", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Offer Applied {"(-)"} {billDetails.offerAmt + " %"}</Typography>
                                }
                                <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >CGST{"(" + billDetails.cgst + "%):"} ₹{billDetails.cgstAmt}</Typography>
                                <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >SGST{"(" + billDetails.sgst + "%):"} ₹{billDetails.sgstAmt}</Typography>
                                {
                                    (billDetails.roundOff !== 0.0 || billDetails.roundOff !== -0.0) &&
                                    <Typography variant="subtitle4" component="div" style={{ color: "#A52A2A", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >RoundOff{billDetails.roundOff > 0.0 ? "(+)" : "(-)"} {billDetails.roundOff}</Typography>
                                }
                                {
                                    billDetails.isPointAdded &&
                                    <Typography variant="subtitle4" component="div" style={{ color: "#A52A2A", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>Royality Point(-) ₹{billDetails.pointRedeem}</Typography>
                                }
                                {delvCharge > 0.0 &&
                                    <Grid item xs="auto">
                                        <Typography variant="subtitle4" component="div" style={{ color: "#A52A2A", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>Delivery Charge ₹{delvCharge}</Typography>
                                    </Grid>
                                }
                                <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Grand Total: ₹{billDetails.total}</Typography>
                                <TextField
                                    id="cashTender"
                                    name="cashTender"
                                    variant="filled"
                                    size="small"
                                    style={{ width: "70%" }}
                                    disabled={(isPaid || (isOnlinePayEnabled && !isCashPayEnabled) || (!isOnlinePayEnabled && !isCashPayEnabled)) || !isRoleAllowed(SALESRIGHTS)}
                                    label="Enter Cash Tender"
                                    value={paymentDetails.cashTender}
                                    onChange={handleInputChange}
                                    sx={{
                                        marginTop: 2,
                                    }}

                                />
                                {
                                    paymentDetails.cashReturn > 0.0 &&
                                    <Typography variant="subtitle4" component="div" style={{ color: "#A52A2A", whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }} >Cash Return: ₹{paymentDetails.cashReturn}</Typography>
                                }
                            </Grid>
                        </Grid>
                    </div>

                    <div className="billing-button-grid-container">
                        {
                            !(listbillingData.billStatus === BillStatus.HOMEDELIVERYCASHPAY
                                || listbillingData.billStatus === BillStatus.HOMEDELIVERYPAY) &&
                            <div>
                                <Button variant="contained" disabled={isPaid || (!isEmpty(roomDetails.roomNo) && listbillingData.payLaterSignal) || listbillingData.value_2.length === 0} color="primary" onClick={() => kotprint(false)}>
                                    {listbillingData.kot > 0 ? "Update Kitchen Copy" : "Kitchen Copy"}
                                </Button>
                            </div>
                        }

                        {
                            (listbillingData.billStatus === BillStatus.HOMEDELIVERYCASHPAY
                                || listbillingData.billStatus === BillStatus.HOMEDELIVERYPAY) &&
                            <div>
                                <Button variant="contained" color="primary" onClick={() => kotprint(false)}>
                                    {listbillingData.kot > 0 ? "Update Kitchen Copy" : "Kitchen Copy"}
                                </Button>
                            </div>
                        }

                        {
                            (kotPrintNoWise && listbillingData.kot > 0) && <Button variant="contained" color="primary" onClick={() => setPrintKotNoDialog(true)}>
                                Previous KOT
                            </Button>
                        }

                        {
                            !(listbillingData.billStatus === BillStatus.HOMEDELIVERYCASHPAY
                                || listbillingData.billStatus === BillStatus.HOMEDELIVERYPAY) &&
                            <div>
                                <Button variant="contained" disabled={isPaid || (!isEmpty(roomDetails.roomNo) && listbillingData.payLaterSignal) || listbillingData.value_2.length === 0} color="primary" onClick={() => kotprint(true)}>
                                    Save Bill
                                </Button>
                            </div>
                        }
                        {
                            (listbillingData.billStatus === BillStatus.HOMEDELIVERYCASHPAY
                                || listbillingData.billStatus === BillStatus.HOMEDELIVERYPAY) &&
                            <div>
                                <Button variant="contained" color="primary" onClick={() => kotprint(true)}>
                                    Save Bill
                                </Button>
                            </div>
                        }

                        {
                            (isEmpty(roomDetails.roomNo) && partilBillAvail) && <div><Button variant="contained" disabled={listbillingData.value_2.length === 0 || !isRoleAllowed(SALESRIGHTS) || listbillingData.billStatus === BillStatus.NOTPAID} color="primary" name="clearDues" onClick={handlePartialPayChange}>
                                {getPartialPayButtonName()}
                            </Button>
                            </div>
                        }

                        {
                            (listbillingData.billStatus === BillStatus.INITIATED && !isEmpty(listbillingData.hotelBillNo)) && <div><Button variant="contained" disabled={listbillingData.value_2.length === 0 || !isRoleAllowed(SALESRIGHTS) || listbillingData.payLaterSignal} color="primary" name="payLater" onClick={handlePayLater}>
                                Pay Later
                            </Button>
                            </div>
                        }
                        <div>
                            <Button disabled={(!isSubmitButtonEnb() || (!isEmpty(roomDetails.roomNo) && listbillingData.payLaterSignal) || isPaid || listbillingData.value_2.length === 0) || !isRoleAllowed(SALESRIGHTS)} variant="contained" color="primary" onClick={(e) => initiatePayment(false)}>
                                Initiate Payment
                            </Button>
                        </div>
                        <div>
                            <Button disabled={(!(!isPaid && ((listbillingData.billStatus === BillStatus.INITIATED)))) || !isRoleAllowed(SALESRIGHTS)} variant="contained" color="primary" onClick={() => {
                                if (genBill) {
                                    if (isEmpty(listbillingData.generateOtp))
                                        generateBill();
                                    else
                                        setGenOtpDialog({
                                            ...genOtpDialog, showDialog: true,
                                        })
                                } else
                                    generateBill();

                            }}>
                                Generate Bill
                            </Button>
                        </div>
                        <div>
                            <Button variant="contained" disabled={!isPaid} color="primary" id="custbill" name="custbill" onClick={printBillAfterPay} >
                                Print Customer Bill
                            </Button>
                        </div>
                        <div>
                            <Button disabled={getSettleBillDisableStatus()} variant="contained" color="primary" onClick={finalSettle} >
                                Settle Bill
                            </Button>
                        </div>

                        <div>
                            <Button variant="contained" disabled={!isPaid} color="primary" id="shopbill" name="shopbill" onClick={printBillAfterPay}>
                                Print Shop Bill
                            </Button>
                        </div>
                        {
                            ((listbillingData.billStatus >= BillStatus.INITIATED || listbillingData.billStatus === BillStatus.PAID || listbillingData.billStatus === BillStatus.PARTIALLYPAID) && deletBillOption) && <div>
                                <Button variant="contained" color="primary" id="deleteBill" name="deleteBill" onClick={deleteEntireBill}>
                                    Delete Bill
                                </Button>
                            </div>
                        }

                        {
                            (listbillingData.billStatus === BillStatus.HOMEDELIVERYINITIATED) && <div>
                                <Button variant="contained" color="primary" id="deleteBill" name="deleteBill" onClick={deleteEntireBill}>
                                    Remove Bill
                                </Button>
                            </div>
                        }

                        {/* <div>
                            <Button variant="contained" disabled={!isPaid} color="primary" id="mtest" name="mtest" onClick={generateDynamicHTML}>
                                Mobile Test
                            </Button>
                        </div> */}
                    </div>
                </div>

            </React.Fragment>
        );
    };

    const getSettleBillDisableStatus = () => {
        let disable = (listbillingData.billStatus === BillStatus.NO_ENTRY || listbillingData.billStatus === BillStatus.NOTPAID || listbillingData.billStatus === BillStatus.PARTIALLYPAID || listbillingData.settleDown);
        if (!disable)
            disable = billUtils.billingType === InternalBillType.BookTable;
        return disable;
    };

    const renderBillingScreenRight = () => {
        return (
            <React.Fragment>
                <Grid container spacing={1} sx={{
                    mb: 1,
                }} columns={{ md: 2, xs: 12, sm: 4 }}>
                    {
                        (categoryData && categoryData.length > 0) &&
                        [...categoryData].map(data => (
                            <Grid item md={1} xs={4} sm={1}>
                                <Paper
                                    elevation={0}
                                    autoHeight
                                    onClick={() => handleItemData(data)}
                                    className="restaurant-right-data-container"
                                    sx={{
                                        borderRadius: 4,
                                        margin: "2px",
                                        backgroundColor: "rgb(194, 204, 180)",
                                    }}>

                                    <center>
                                        <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '12px' }}><b>{data.catgName}</b></p>
                                    </center>
                                </Paper>
                            </Grid>
                        ))
                    }
                </Grid>
            </React.Fragment>
        );
    };

    const renderSnpContent = () => {
        return (
            <React.Fragment>
                <Box sx={{ display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none' } }}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            {renderBillingScreenRight()}
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            {renderBillingScreenLeft()}
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}>
                    <Grid container>
                        <Grid item md={10}>
                            {renderBillingScreenLeft()}
                        </Grid>
                        <Grid item md={2}>
                            {renderBillingScreenRight()}
                        </Grid>
                    </Grid>
                </Box>
                {/* {
                    !isMobile ? <Grid container>
                        <Grid item md={10}>
                            {renderBillingScreenLeft()}
                        </Grid>
                        <Grid item md={2}>
                            {renderBillingScreenRight()}
                        </Grid>
                    </Grid> :
                        <Grid container>
                            <Grid item xs={12}>
                                {renderBillingScreenRight()}
                            </Grid>
                            <Grid item xs={12}>
                                {renderBillingScreenLeft()}
                            </Grid>
                        </Grid>
                } */}

            </React.Fragment>
        );
    };


    const enableDisableOnlineCashPay = () => {
        let status = listbillingData.billStatus === BillStatus.PAID;
        if (!status) {
            status = isEmpty(partialPay.onlineBillPaidThrough) || isEmpty(partialPay.cashBillPaidThrough);
        }
        return status;
    }




    return (
        <React.Fragment>
            <SnpComponent showDialog={snpComponent.showDialog}
                isLoader={snpLoader}
                title={snpComponent.title}
                subTitle={snpComponent.subTitle}
                negativeAction={snpComponent.negativeAction}
                positiveReq={snpComponent.positiveReq}
                positiveAction={snpComponent.positiveAction}
                negativeReq={snpComponent.negativeReq}
                handleDialogClose={handleSnpDialogClose}
                iconReq={snpComponent.iconReq}
                iconContent={snpComponent.iconContent}
                content={renderSnpContent()}
                onSnackClose={handleSnackClose}
                cancelExtra={snpComponent.cancelExtra}
                onCancel={snpComponent.requestType === 99 ? snpComponent.handleSnpCancel : handleSnpCancel}
                snackBarOpen={snpComponent.snackBarOpen}
                snackBarMsg={snpComponent.snackBarMsg}
                outSideClickDismiss={snpComponent.outSideClickDismiss}
                handleSubmit={snpComponent.requestType === 99 ? snpComponent.handleSnpSubmit : handleSnpSubmit}
                gui={snpComponent.gui}
            />
        </React.Fragment>
    );
    //UI Corner end

}