// CustomButton.js
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const DismissButton = styled(Button)(({ theme }) => ({
  border: '1px solid #00333e',  // Primary border color
  backgroundColor: '#ffffff',  // Default white background
  borderRadius: '20px',
  color: '#00333e',             // Primary text color
  '&:hover': {
    backgroundColor: '#93a7ab', // Slightly darker on hover
  },
}));

export default DismissButton;
