import React, { useEffect, useState } from "react";
import { Grid, TextField, FormControl,Autocomplete,Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
    SUCCESS,
    CART_INITIAL_STATE,
    isRoleAllowed,
    ADMINRIGHTS,
    getPreUrlAccordingToCart,
    SHOWCONFIGS,
    ConfigInputType,
    isEmpty,
    ConfigType,
    PERSISTCONFIGS,
    CartDets,
    OFFLINEIP,
    DEFDASHBOARDSCREEN,
    DashboardComponent,
    printDebugLog,
    DEFBALANCETYPE,
    printInfoLog,
    MAINACCOUNTING,
    GETVIDEOPATH
} from "../../constants/constant";
import DialogBox from "../global/DialogBox";
import CustomDataGrid from "../global/CustomDataGrid";
import {
    Button, RadioGroup, FormControlLabel,
    FormLabel, Radio
} from "@mui/material";
import { useSelector } from "react-redux";
import SnpComponent from "../global/SnpComponent";
import imageUploadService from "../../service/imageUploadService";
import { snpAxios } from "../global/api";
import { SCREEN_CONFIG } from "../../constants/VideoConstant";
import axios from "axios";
import YouTubeVideo from "../global/YouTubeVideo";
import SingleFab from "../global/fab/SingleFab";

export default function Configuration({ setComponentIndex }) {
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const [showDialog, setShowDialog] = useState(false);
    const [isEditProduct, setEditProduct] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [imageSelectedError, setImageSelectedError] = useState(false);
    const [uploadedDocument, setUploadedDocument] = useState("");
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: false,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
    };
    const [videoModel, setVideoModel] = useState({show:false,path:""});
    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const INITIAL_CONFIGS = {
        id: "",
        configName: "",
        configNameStr:"",
        employeeName: "",
        prefix: "",
        val: "",
        displayVal:"",
        comments: "",
        type: 0,
        valType: 0,
        allowed: true,
        allowedStr: "",
    };
    const [configs, setConfigs] = useState(INITIAL_CONFIGS);
    const [listConfigs, setListConfigs] = useState([]);
    const [listAcc, setListAcc] = useState([]);
    const columns = [
        { field: 'configNameStr', headerName: 'Config Name', width: 240 },
        { field: 'prefix', headerName: 'Config Type', width: 180 },
        {
            field: "edit",
            headerName: "Edit",
            width: 80,
            renderCell: (cellValues) => {
                return (
                    <EditIcon
                        onClick={(event) => {
                            handleCellClick(event, cellValues);
                        }}
                    >
                    </EditIcon>
                );
            }
        }
    ];


    const columnsAdmin = [
        { field: 'employeeName', headerName: 'Emp. Name', width: 180 },
        { field: 'configNameStr', headerName: 'Config Name', width: 240 },
        { field: 'prefix', headerName: 'Config Type', width: 150 },
        { field:'displayVal', headerName: 'Value', width: 150 },
        { field: 'allowedStr', headerName: 'Allowed', width: 150 },
        {
            field: "edit",
            headerName: "Edit",
            width: 90,
            renderCell: (cellValues) => {
                return (
                    <EditIcon
                        onClick={(event) => {
                            handleCellClick(event, cellValues);
                        }}
                    >
                    </EditIcon>
                );
            }
        }
    ];

    const handleDeleteImage= () => {
        setSelectedImage("");
    }

    const handleCellClick = (param, event) => {
        setEditProduct(true);
        //console.log("handleCellClick=>", event.row)
        if (event.row.valType === ConfigInputType.ATTACHMENT && !isEmpty(event.row.val)) {
            setSelectedImage(storeApiUrl.BACKEND_BASE_URL + "files/" + event.row.val)
        }
        setShowDialog(true);
        setConfigs({
            id: event.row.id,
            configName: event.row.configName,
            configNameStr: event.row.configNameStr,
            employeeName: event.row.employeeName,
            prefix: event.row.prefix,
            val: event.row.val,
            comments: event.row.comments,
            type: event.row.type,
            valType: event.row.valType,
            allowed: event.row.allowed,
            allowedStr: event.row.allowedStr,
        });
    };
    const getConfigs = () => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + SHOWCONFIGS;
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            }
        };
        snpAxios.post(cUrl, data)
            .then((response) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                printDebugLog("getConfigs", response.data);
                if (response.data.status === SUCCESS) {
                    setListAcc(response.data.jcJson.delvList);
                    setListConfigs(response.data.jcJson.value_2);
                }
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                console.log("getConfigs ", error);
            });
    };
    const handleDialogClose = () => {
        // console.log("handleDialogClose");
        setConfigs(INITIAL_CONFIGS);
        setShowDialog(false);
        setEditProduct(false);
        setSelectedImage('');
        setImageSelectedError(false);
        setUploadedDocument('');
    };
    useEffect(() => {
        //console.log("useEffect",CART_INITIAL_STATE)
        getYoutubeVideo(SCREEN_CONFIG,'hi');
        getConfigs();
    }, [storeActiveShopHash,storeApiUrl]);

    const getYoutubeVideo = (videoName,lang) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETVIDEOPATH + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&name=${videoName}` + `&lang=${lang}`;
        let data = {};
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    setVideoModel({...videoModel,path : response.data});
                } else 
                    setVideoModel({...videoModel,path : ''});
            }).catch((error) => {
                //printLog("error : ", error);
                //window.location.href = "#/error";
                setVideoModel({...videoModel,path : ''});
            });
    };

    const handleFileUpload = async () => {
        if (configs.valType === ConfigInputType.ATTACHMENT) {
            if (uploadedDocument) {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: true,
                });
                let formData = new FormData();
                formData.append('fnm', CART_INITIAL_STATE.apiToken);
                formData.append("file", uploadedDocument);
                await imageUploadService.upload(storeApiUrl.BACKEND_BASE_URL,getPreUrlAccordingToCart(storeActiveShopHash.cartType), formData).then(response => {
                    console.log("folderName", response.data);
                    if (response.data.folderName) {
                        configs.val = response.data.folderName;
                        handleComboSubmit();
                    }
                }
                ).catch(error => {
                    console.log("handleFileUpload = > ", error);
                    // window.location.href = "#/error";
                });
            } else {
                handleComboSubmit();
            }
        } else {
            handleComboSubmit();
        }
    };

    const handleComboSubmit = () => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + PERSISTCONFIGS;
        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
                id: configs.id,
                configName: configs.configName,
                prefix: configs.prefix,
                comments: configs.comments,
                val: configs.val,
                type: configs.type,
                allowed: configs.allowed,
            }
        };
        setShowDialog(false);
        //console.log("handleComboSubmit=>",data);
        snpAxios.post(cUrl, data)
            .then((response) => {
                //console.log("handleComboSubmit<=", response.data);
                if (response.data.status === SUCCESS) {
                    if (configs.configName === OFFLINEIP) {
                        setSnpComponent({
                            ...snpComponent,
                            isLoader: false,
                            negativeReq: true,
                            negativeAction: "Ok",
                            showDialog: true,
                            outSideClickDismiss: true,
                            cancelExtra: true,
                            requestType: 1,
                            subTitle: "Your IP is changed, Please Logout and again login for changes to take effect"
                        });
                    } else if (configs.configName === DEFDASHBOARDSCREEN) {
                        CART_INITIAL_STATE.defScreen = Number(configs.val);
                        getConfigs();
                    } else if (configs.configName === DEFBALANCETYPE) {
                        CART_INITIAL_STATE.defBalanceType = configs.val;
                        getConfigs();
                    } else {
                        getConfigs();
                    }
                        
                } else {
                    setSnpComponent({
                        ...snpComponent,
                        isLoader: false,
                    });
                }
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                console.log("handleComboSubmit ", error);
            });
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        printDebugLog("handleInputChange", name , value);
        if (name === 'imgPath') {
            let file = event.target.files[0];
            setUploadedDocument(file);
            if (file) {
                setImageSelectedError(false);
                const reader = new FileReader();
                //const url = reader.readAsDataURL(file);

                reader.onloadend = function (e) {
                    console.log('onloadend', reader.result);
                    setSelectedImage(reader.result)
                }

            } else {
                setImageSelectedError(true);
            }
        } else {
            setConfigs({
                ...configs,
                [name]: value,
            });

        }
    };
    const handleBalanceTypeChanged = (e, balanceType) => {
        printInfoLog("handleBalanceTypeChanged",balanceType);
        if (!isEmpty(balanceType)) {
            setConfigs({
                ...configs,
                val: balanceType,
            });
        } else {
            setConfigs({
                ...configs,
                val: MAINACCOUNTING,
            });
        }
    };
    const renderMainDialogContent = () => {
        return (
            <React.Fragment>
                <div>
                    <Grid container justifyContent="center" alignItems="center" spacing={1} sx={{ p: 1 }}>
                        <Grid item md={12} sm={12} xs={12} lg={12} >
                            <TextField
                                id="configName"
                                disabled
                                label="Configuration Name"
                                name="configName"
                                value={configs.configNameStr}
                                variant="filled"
                                size="small"
                                helperText={isEmpty(configs.comments) && configs.comments}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        {
                            configs.type === ConfigType.ONLY_OWNERMULTIVISIBLE &&
                            <Grid item md={12} sm={12} xs={12} lg={12} >
                                <TextField
                                    id="prefix"
                                    label="Config Type"
                                    name="prefix"
                                    value={configs.prefix}
                                    variant="filled"
                                    size="small"
                                    style={{ width: "100%" }}
                                />
                            </Grid>
                        }
                        {
                            (configs.valType === ConfigInputType.ATTACHMENT && isEmpty(selectedImage)) &&
                            <Grid item md={8} sm={8} xs={8} lg={8} >
                                <TextField
                                    accept="image/*"
                                    id="imgPath"
                                    name="imgPath"
                                    label=""
                                    multiple
                                    type="file"
                                    autoHighlight
                                    size="small"
                                    helperText="Enter Configuration Image(Image should be less than 2Mb.)"
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            (configs.valType === ConfigInputType.ATTACHMENT && !isEmpty(selectedImage)) &&
                            <Grid item md={4} sm={4} xs={4} lg={4} >
                                <Button variant="contained" color="primary" onClick={handleDeleteImage}>
                                    Delete Image
                                </Button>
                            </Grid>
                        }
                        {
                            (configs.valType === ConfigInputType.ATTACHMENT && !isEmpty(selectedImage)) &&
                            <Grid item md={4} sm={4} xs={4} lg={4} >
                                <img
                                    alt=""
                                    style={{ height: "80px", width: "80px", borderRadius: "40%" }}
                                    src={selectedImage}
                                />
                            </Grid>
                        }
                        
                        {imageSelectedError &&
                            <p className="error" style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}>Please select the Image (image format) having size below 5Mb before uploading.</p>
                        }
                        {
                            configs.valType === ConfigInputType.LABEL &&
                            <Grid item md={12} sm={12} xs={12} lg={12} >
                                <TextField
                                    id="val"
                                    label="Enter Configuration Value"
                                    name="val"
                                    size="small"
                                    defaultValue={configs.val}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            ((configs.valType === ConfigInputType.RADIO && !(configs.configName === DEFDASHBOARDSCREEN)) || configs.valType === ConfigInputType.BOOL) &&
                            <Grid item md={12} sm={12} xs={12} lg={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Select Configuration Value</FormLabel>
                                    <RadioGroup aria-label="val" name="val"  value={configs.val} onChange={handleInputChange} row={true}>
                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        }
                        {
                            ((configs.valType === ConfigInputType.RADIO && (configs.configName === DEFDASHBOARDSCREEN))) &&
                            <Grid item md={12} sm={12} xs={12} lg={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Select Defaut Dashboard Screen</FormLabel>
                                    <RadioGroup aria-label="val" name="val"  value={configs.val} onChange={handleInputChange} row={true}>
                                        <FormControlLabel value={DashboardComponent.REPORTMANAGEMENT.toString()} control={<Radio />} label="Owner Dashboard" />
                                        <FormControlLabel value={DashboardComponent.BILLDETAILS.toString()} control={<Radio />} label="Billing Management" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        }
                        {
                            (configs.valType === ConfigInputType.DROPDOWN && (configs.configName === DEFBALANCETYPE)) &&
                            <Grid item md={12} xs={12} sm={6} lg={12}>
                                <Autocomplete
                                    id="balance-type-label"
                                    variant="filled"
                                    size="small"
                                    autoHighlight
                                    options={listAcc}
                                    onChange={handleBalanceTypeChanged}
                                    getOptionLabel={(option) => option}
                                    defaultValue={configs.val}
                                    renderOption={(props, option) => (
                                        <Box
                                            component="li"
                                            {...props}
                                        >
                                            {option}
                                        </Box>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Default Balance type used in Dashboard"
                                            inputProps={{
                                                ...params.inputProps,
                                            }}
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                />
                            </Grid>
                        }
                        <Grid item md={12} sm={12} xs={12} lg={12}>
                            <TextField
                                id="comments"
                                variant="filled"
                                size="small"
                                label="Enter Comments"
                                name="comments"
                                defaultValue={configs.comments}
                                onChange={handleInputChange}
                                multiline={true}
                                rows={2}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        {
                            (isRoleAllowed(ADMINRIGHTS)) &&
                            <Grid item md={12} sm={12} xs={12} lg={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Is Configuration Allowed(For Employee)</FormLabel>
                                    <RadioGroup aria-label="allowed" name="allowed" value={configs.allowed} onChange={handleInputChange} row={true}>
                                        <FormControlLabel value={true} control={<Radio />} label="Yes" />
                                        <FormControlLabel value={false} control={<Radio />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        }
                    </Grid>
                </div>
            </React.Fragment>
        );
    }

    const renderDialogAction = () => {
        return (
            <React.Fragment>
                <Button variant="contained" color="primary" onClick={handleFileUpload}>
                    {isEditProduct ? "Update" : "Submit"}
                </Button>
                <Button variant="contained" color="primary" onClick={handleDialogClose}>
                    Dismiss
                </Button>
            </React.Fragment>
        );
    }

    const renderSnpContent = () => {
        return (
            <React.Fragment>
                {
                    storeActiveShopHash.cartType === CartDets.Resto &&
                    <div>
                        <Button
                    variant="contained" color="primary" style={{ float: 'right' }} className="float-right" onClick={() => setComponentIndex(16)}>
                        Table Configuration
                    </Button>
                    <br/><br/>
                    </div>
                }
                <CustomDataGrid
                    rows={listConfigs}
                    columns={isRoleAllowed(ADMINRIGHTS) ? columnsAdmin : columns}
                    loading={snpComponent.isLoader}
                />
                < DialogBox
                    showDialog={showDialog}
                    title={isEditProduct ? "Update Configuration" : "Add Configuration"}
                    onClose={handleDialogClose}
                    dialogContent={renderMainDialogContent()}
                    dialogActions={renderDialogAction()}
                ></DialogBox>
                {videoModel.show && <YouTubeVideo videoId={videoModel.path} onDismiss={(e) => setVideoModel({...videoModel,show:false})}/> }
                {videoModel.path && <SingleFab onClick={(e)=> setVideoModel({...videoModel,show:true})}/> }
            </React.Fragment>
        );
    }

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    const onClose = () => {
        console.log("onClose=> configuration");
        window.opener = null;
        window.open("", "_self");
        window.close();
    };

    return (
        <React.Fragment>
            <SnpComponent
                content={renderSnpContent()}
                onSnackClose={handleSnackClose}
                snackBarOpen={snpComponent.snackBarOpen}
                snackBarMsg={snpComponent.snackBarMsg}
                outSideClickDismiss={snpComponent.outSideClickDismiss}
                showDialog={snpComponent.showDialog}
                title={snpComponent.title}
                subTitle={snpComponent.subTitle}
                negativeAction={snpComponent.negativeAction}
                positiveReq={snpComponent.positiveReq}
                positiveAction={snpComponent.positiveAction}
                negativeReq={snpComponent.negativeReq}
                cancelExtra={snpComponent.cancelExtra}
                onCancel={onClose}
            />
            
        </React.Fragment>
    );
}