import React from "react";
import Loader from "./loader/Loader";
import ConfirmDialog from "./ConfirmDialog";
import { Snackbar } from "@mui/material";

export default function SnpComponent(props) {

  const {
    showDialog = false,
    outSideClickDismiss = true,
    handleSubmit = () => { },
    title = "",
    subTitle = "",
    negativeAction = "",
    positiveReq = false,
    positiveAction = "",
    handleDialogClose = () => { },
    isLoader = false,
    iconReq = "",
    iconContent = "",
    content = "",
    snackBarOpen = false,
    snackBarMsg = "",
    onSnackClose = () => { },
    negativeReq = false,
    cancelExtra = false,
    onCancel = () => { },
    gui = "",
    bgColor = '',
    snackColor = 'grey',
    snackVertical = 'bottom',
    snackHorizontal = 'left'
    // children,
  } = props;

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onSnackClose(event);
  };

  const onDialogClose = (event) => {
    //console.log("onDialogClose",outSideClickDismiss);
    if (outSideClickDismiss) {
      handleDialogClose(event);
    }
  };


  return (
    <div style={{ backgroundColor: bgColor }}>
      {isLoader ? <Loader /> : content}
      {
        showDialog && <ConfirmDialog isOpen={showDialog}
          title={title}
          subTitle={subTitle}
          negativeReq={negativeReq}
          negativeAction={negativeAction}
          positiveReq={positiveReq}
          positiveAction={positiveAction}
          onClose={onDialogClose}
          iconReq={iconReq}
          iconContent={iconContent}
          onSubmit={handleSubmit}
          cancelExtra={cancelExtra}
          onCancel={onCancel}
          gui={gui}
        />
      }
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={3000}
        message={snackBarMsg}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: snackVertical, horizontal: snackHorizontal }}
        sx={{
          '& .MuiSnackbarContent-root': {
            backgroundColor: snackColor,
            color: '#ffffff',
            maxWidth: '80vw',
            whiteSpace: 'pre-wrap',
          },
          '& .MuiSnackbarContent-message': {
            width: '100%',
             whiteSpace: 'normal', // Allow text to wrap
            wordBreak: 'break-word'
          },
        }}
      />
    </div>
  );
};