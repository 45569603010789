import React, { useEffect } from 'react';

const NotificationSound = ({ message, show }) => {
  useEffect(() => {
    if (show) {
      const audio = new Audio('/order_received_grab.mp3');
      audio.play();
    }
  }, [show]);

  return (
    show && (
      <div className="notification">
        <p>{message}</p>
      </div>
    )
  );
};

export default NotificationSound;
