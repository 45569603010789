import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import SnpComponent from "../global/SnpComponent";
import { A2ZPAYMENTFAIL, BillStatus, ECARTGETSHOPCATG, ECARTGETSHOPITEM, EcartUrlQuery, SUCCESS, isEmpty, printDebugLog, printInfoLog } from "../../constants/constant";
import { Box, Button, CssBaseline, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import './Ecart.css';
import { makeStyles } from "@mui/styles";
import Carousel from "./Carousel";
import EcartNavbar from "./EcartNavbar";
import DialogBox from "../global/DialogBox";
import SnpButton from "../global/SnpButton";
import DismissButton from "../global/DismissButton";
import BottomAppBar from "./BottomAppBar";
import SnpProgress from "../global/loader/SnpPrgress";
import utilities from "../../utilities/utilities";

export default function EcartWeb() {
    const location = useLocation();
    const navigate = useNavigate();
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
    };

    const useStyles = makeStyles({
        input: {
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
    });
    const colQuantityClasses = useStyles();

    const SHOP_INFO = {
        shopHash: "",
        shopName: "",
        shopAddress: "",
        shopEmail: "",
        shopNumber: "",
        shopWebAddress: "",
        shopCompanyName: "",
        tableHash: "",
        tableName: "",
        tableOwnerName: "",

    }

    const CUST_INFO = {
        custId: "",
        custName: "",
        custAddress: "",
        custEmail: "",
        custNumber: "",
        verificationRequired: 0,
    }

    const [quantityCache, setQuantityCache] = useState(new Map());

    const INITIAL_BILL_STOCK_DATA = {
        prdId: "",
        productImage: "",
        productId: "",
        name: "",
        sgst: 0,
        cgst: 0,
        price: 0.0,
        foodQuantityName: "",
        priceIndex: 0,
        finalprice: 0.0,
        note: "",
        quantity: 1,
        offerDenom: 0,
        offerAmt: 0.0,
        print: false,
        combo: false,
    };

    const INITIAL_LISTBILLINGDATA = {
        billNo: "",
        list: [],
        totalQuantity: 0,
        billType: -1,
        billStatus: BillStatus.NO_ENTRY,
        pointRedeem: 0,
        token: 0,
        roundOff: 0.0,
        createdAt: "",
        foodStatus: "",
        assignedBy: "",
        assignedByName: "",
        transactionId: "",
        subTotal: 0.0,
        total: 0.0,
        takenOffrAmt: 0.0,
        sgst: 2.5,
        cgst: 2.5,
        cgstAmt: 0.0,
        sgstAmt: 0.0,
        isGstAllowed: true,
        delvCharge: 0.0,
    };

    const INITIAL_RAZOR_PAYDATA = {
        finalAmtInPaisa: 0,
        onlinePaymentTransactionId: "",//Order Id
        pgId: "", //pay gateway id need to decode
        logo: "",
        payeeName: "",
        currency: "",
        retryConfig: 0,
    }

    const [initialBg, setInitialBg] = useState({
        background: "",
        image: true,
    })

    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);
    const [listCateogory, setListCateogory] = useState([]);
    const [listProduct, setListProduct] = useState([]);
    const [shopInfo, setShopInfo] = useState(SHOP_INFO);
    const [custInfo, setCustInfo] = useState(CUST_INFO);
    const [selectedCatg, setSelectedCatg] = useState("");
    const [billingData, setBillingData] = useState(INITIAL_LISTBILLINGDATA);
    const [cartDialog, setCartDialog] = useState(false);
    const [initialRazorPayData, setInitialRazorPayData] = useState(INITIAL_RAZOR_PAYDATA);
    const [isCustLoading, setIsCustLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [custDialog, setCustDialog] = useState(false);

    useEffect(() => {
        // const handleBeforeUnload = () => {
        //     // Remove the item from sessionStorage
        //     console.log("handleBeforeUnload")
        //     sessionStorage.removeItem(EcartUrlQuery.SHOPHASH);
        // }; const handleUnload = () => {
        //     // Remove the item from sessionStorage
        //     console.log("handleUnload")
        //     sessionStorage.removeItem(EcartUrlQuery.SHOPHASH);
        // };
        // // Attach the event listeners when the component mounts
        // window.addEventListener('beforeunload', handleBeforeUnload);
        // window.addEventListener('unload', handleUnload);

        //Check For Query  Params
        const queryParams = new URLSearchParams(location.search);
        const myParam = queryParams.get(EcartUrlQuery.SHOPHASH);
        const myTableParam = queryParams.get(EcartUrlQuery.TABLEID);
        //console.log(EcartUrlQuery.SHOPHASH,myParam); // Read the value of 'myParam' from the URL
        if (!isEmpty(myParam)) {
            // Remove query parameters
            queryParams.delete(EcartUrlQuery.SHOPHASH);
            sessionStorage.setItem(EcartUrlQuery.SHOPHASH, myParam);
            //console.log("!isempty")
            // Update the URL without the query parameter
            navigate(`?${queryParams.toString()}`, { replace: true });
            getEcartShopCateogory(myParam);
        }else if (!isEmpty(myTableParam)) {
            // Remove query parameters
            queryParams.delete(EcartUrlQuery.TABLEID);
            navigate(`?${queryParams.toString()}`, { replace: true });
            validateQrCodeHash(myTableParam);
        }else {
            const shopHash = sessionStorage.getItem(EcartUrlQuery.SHOPHASH); //ShopHash
            //console.log("sessionStorage",shopHash); // Read the value of 'myParam' from the URL
            if (!isEmpty(shopHash)) {
                getEcartShopCateogory(shopHash);
            } else {
                //Panic fallback to Snp WebSite
                window.location.href = 'http://www.snpnextgen.com';
            }
        }
        // return () => {
        //     window.removeEventListener('beforeunload', handleBeforeUnload);
        //     window.removeEventListener('unload', handleUnload);
        // };
    }, [storeApiUrl]);

    const validateQrCodeHash = async (myParam) => {
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/validate-table-hash?tableHash="+myParam;
        axios.get(
            cartUrl,
            {}
        ).then(response => {
            printDebugLog("validateQrCodeHash responses => ", response.data);
            setSnpComponent({
                ...snpComponent,
                isLoader: false,
            });
            if (response.data === SUCCESS) {
                setShopInfo({
                    ...shopInfo, tableHash: myParam, shopAddress: response.data.jcJson.shopAddress,
                    shopName: response.data.jcJson.shopName,
                    shopEmail: response.data.jcJson.shopEmail,
                    shopNumber: response.data.jcJson.shopNumber,
                    shopWebAddress: response.data.jcJson.shopWebAddress,
                    shopCompanyName: response.data.jcJson.shopCompanyName,
                    shopHash: response.data.jcJson.shopHash,
                    tableName: response.data.jcJson.tableName,
                    tableOwnerName:response.data.jcJson.tableOwnerName,
                })
                sessionStorage.setItem(EcartUrlQuery.SHOPHASH, response.data.jcJson.shopHash);
                getEcartShopCateogory(response.data.jcJson.shopHash);
            }
        }).catch(error => {
            setSnpComponent({
                ...snpComponent,
                isLoader: false,
            });
            console.log("error validateQrCodeHash: ", error);
            //window.location.href = "#/error";
        });

    };

    const getEcartShopCateogory = async (myParam) => {
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + ECARTGETSHOPCATG;
        let data = {
            jcJson: {
                shopHash: myParam,
            }
        };

        //console.log("getEcartShopCateogory  => ", data);
        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                //console.log("getEcartShopCateogory <= ", response.data);
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                if (response.data.status === SUCCESS) {
                    setInitialBg({
                        ...initialBg,
                        background: response.data.jcJson.background,
                        image: response.data.jcJson.image,
                    });
                    setShopInfo({
                        ...shopInfo, shopHash: myParam, shopAddress: response.data.jcJson.shopAddress,
                        shopName: response.data.jcJson.shopName,
                        shopEmail: response.data.jcJson.shopEmail,
                        shopNumber: response.data.jcJson.shopNumber,
                        shopWebAddress: response.data.jcJson.shopWebAddress,
                        shopCompanyName: response.data.jcJson.shopCompanyName,
                    })
                    setListCateogory(response.data.jcJson.value_2);
                    getEcartShopProduct(myParam, response.data.jcJson.value_2[0].categoryId);
                }
                //
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };

    const getEcartShopProduct = async (myParam, mCatgId) => {

        let cartUrl = storeApiUrl.BACKEND_BASE_URL + ECARTGETSHOPITEM;
        let data = {
            jcJson: {
                shopHash: isEmpty(shopInfo.shopHash) ? myParam : shopInfo.shopHash, //For the first time
                catgId: isEmpty(mCatgId) ? "" : mCatgId,
            }
        };
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        //console.log("getEcartShopProduct  => ", data);
        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                setSelectedCatg(mCatgId);
                printDebugLog("getEcartShopProduct <= ", response.data);
                if (response.data.status === SUCCESS) {
                    const updatedProducts = response.data.jcJson.value_2.map((item) => {
                        // Add a new field (e.g., 'newField') to each item in the list
                        return {
                            ...item,
                            quantity: getQuantity(item.id),
                        };
                    });

                    // Set the updated product list
                    setListProduct(updatedProducts);
                }
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                printInfoLog("errr getEcartShopProduct", error);
                //window.location.href = "#/error";
            });
    };


    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const placeOrderProcess = (mOrderId, paymentId, paymentSignature) => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/place-order";
        let data = {
            jcJson: {
                shopHash: shopInfo.shopHash,
                custMob: custInfo.custNumber,
                billNo: billingData.billNo,
                billStatus: BillStatus.PAID,
                bank_transactionId: paymentId,
                signature: paymentSignature,
                orderId: mOrderId,
            }
        };
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        //console.log("getEcartShopProduct  => ", data);
        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                printDebugLog("placeOrderProcess <= ", response.data);
                if (response.data.status === SUCCESS) {
                    setBillingData({
                        ...billingData, foodStatus: response.data.jcJson.foodStatus,
                        billStatus: BillStatus.PAID,
                    })
                    setSnpComponent({
                        ...snpComponent,snackBarMsg:"Order Initiated Successfully, Your Bill Number is "+billingData.billNo,
                        snackBarOpen:true,
                    });
                }
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                printInfoLog("errr placeOrder", error);
                //window.location.href = "#/error";
            });
    }

    const displayRazorpay = async (pgId, finalAmtInPaisa, onlinePaymentTransactionId, mCurrency, payeeName) => {
        const res = await loadRazorpayScript();

        if (!res) {
            alert('Razorpay SDK failed to load. Please check your connection.');
            return;
        }

        const options = {
            key: pgId, // Replace with your Razorpay key ID
            amount: finalAmtInPaisa, // Amount is in currency subunits. 50000 refers to 50000 paise or INR 500
            currency: mCurrency,
            name: payeeName,
            description: 'Pay ' + billingData.total + " for new registeration in A2z", //Add Donation Here
            image: 'https://www.snpnextgen.com/logo.png', // Replace with your logo URL
            order_id: onlinePaymentTransactionId, // This is a sample Order ID. Replace with your generated Order ID from your backend.
            handler: function (response) {
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
                printDebugLog("displayRazorpay success", response.razorpay_payment_id,
                    response.razorpay_order_id, response.razorpay_signature
                );
                placeOrderProcess(onlinePaymentTransactionId, response.razorpay_payment_id, response.razorpay_signature);
                //Handle success logic here
            },
            prefill: {
                name: custInfo.custName,
                email: custInfo.custEmail,
                contact: custInfo.custNumber,
            },
            notes: {
                address: shopInfo.shopAddress,
            },
            theme: {
                color: '#00333E',
            },
            modal: {
                ondismiss: function () {
                    //alert('Payment process was cancelled.');
                    printDebugLog("displayRazorpay cancel by user");
                    a2zPayFail(onlinePaymentTransactionId, 1, "", "", "", "", "", "", "", "", "", "");
                    // Handle the modal dismiss event (like close button, back button, etc.)
                },
            },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', function (response) {
            // alert(`Payment failed with error code: ${response.error.code}`);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
            printDebugLog("displayRazorpay failed", response.error.metadata.payment_id,
                response.error.metadata.order_id, response.error.description, response.error.source
                , response.error.code, response.error.step, response.error.reason
            );
            a2zPayFail(onlinePaymentTransactionId, 0, response.error.metadata.payment_id
                , response.error.code, response.error.description, response.error.source,
                response.error.step, response.error.reason, pgId, finalAmtInPaisa, mCurrency, payeeName);
            // Handle the failed payment response here
        });
        rzp1.open();
    };

    const a2zPayFail = (mOrderId, mCancelled, mPayId, mCode, mDescription
        , mSource, mStep, mReason, pgId, finalAmtInPaisa, mCurrency, payeeName) => {
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + A2ZPAYMENTFAIL + "?orderId=" + mOrderId + "&cancelled=" + mCancelled + "&payId=" + mPayId + "&code=" + mCode;
        catgUrl = catgUrl + "&description=" + mDescription + "&source=" + mSource + "&step=" + mStep + "&reason=" + mReason;
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        axios.get(
            catgUrl,
            {}
        ).then(response => {
            printDebugLog("a2zPayFail responses => ", response.data);
            if (response.data === SUCCESS) {
                let msg = "";
                if (mCancelled === 1)
                    msg = "Your payment is cancelled.Do you want to try again";
                else
                    msg = "Payment failed due to " + mReason + " (Error Code: " + mCode + ")"
                setSnpComponent({
                    ...snpComponent,
                    negativeReq: true,
                    positiveReq: true,
                    positiveAction: "Try again",
                    negativeAction: "Close",
                    showDialog: true,
                    isLoader: false,
                    outSideClickDismiss: false,
                    cancelExtra: true,
                    requestType: 99,
                    subTitle: msg,
                    handleSnpSubmit: () => {
                        displayRazorpay(pgId, finalAmtInPaisa, mOrderId, mCurrency, payeeName)
                    },
                });
            } else {

            }
        }).catch(error => {
            setSnpComponent({
                ...snpComponent,
                isLoader: false,
            });
            console.log("error a2zPayFail: ", error);
            //window.location.href = "#/error";
        });
    }

    const createOrder = () => {
        if (isEmpty(custInfo.custNumber)) {
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "Kindly login & then initiate pay!",
                snackBarOpen: true,
            })
            return;
        }
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/create-order-web";
        let data = {
            jcJson: {
                shopHash: shopInfo.shopHash,
                custMob: custInfo.custNumber,
                tableHash: shopInfo.tableHash,
                ...billingData,
            }
        };
        setSnpComponent({
            ...snpComponent,
            isLoader: true,
        });
        //console.log("getEcartShopProduct  => ", data);
        axios.post(
            cartUrl,
            data
        )
            .then((response) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                printDebugLog("createOrder <= ", response.data);
                if (response.data.status === SUCCESS) {
                    setBillingData({
                        ...billingData, foodStatus: response.data.jcJson.foodStatus,
                        billNo: response.data.jcJson.billNo,
                        token: response.data.jcJson.token,
                    })
                    setInitialRazorPayData({
                        ...initialRazorPayData,
                        finalAmtInPaisa: response.data.jcJson.finalAmtInPaisa,
                        onlinePaymentTransactionId: response.data.jcJson.onlinePaymentTransactionId,
                        pgId: response.data.jcJson.pgId,
                        logo: response.data.jcJson.logo,
                        payeeName: response.data.jcJson.payeeName,
                        currency: response.data.jcJson.currency,
                        retryConfig: response.data.jcJson.retryConfig,
                    })
                    let pgId = utilities.decode(response.data.jcJson.pgId);
                    displayRazorpay(pgId, response.data.jcJson.finalAmtInPaisa,
                        response.data.jcJson.onlinePaymentTransactionId, response.data.jcJson.currency, response.data.jcJson.payeeName
                    );
                }
            }).catch((error) => {
                setSnpComponent({
                    ...snpComponent,
                    isLoader: false,
                });
                printInfoLog("errr createOrder", error);
                //window.location.href = "#/error";
            });
    }

    const reqForOtp = (e) => {
        if (e.target.value.length === 10) {
            let custgUrl = storeApiUrl.BACKEND_BASE_URL + "cart/req-mobile-otp?mobNo=" + e.target.value + "&shopHash=" + shopInfo.shopHash;
            setIsCustLoading(true);
            axios.get(custgUrl, {}).then((response) => {
                setIsCustLoading(false);
                printDebugLog("reqForOtp <= ", response.data);
                if (response.data === SUCCESS) {
                    setCustInfo({
                        ...custInfo, custNumber: e.target.value,
                        verificationRequired: 1,
                    })
                    setSnpComponent({
                        ...snpComponent, snackBarMsg: "Otp Send Successfully!", snackBarOpen: true,
                    })
                } else {
                    setSnpComponent({
                        ...snpComponent, snackBarMsg: "Mobile Verification failed", snackBarOpen: true,
                    })
                }
            }).catch((error) => {
                setIsCustLoading(false);
                printInfoLog("errr reqForOtp", error);
                //window.location.href = "#/error";
            });
        }
    };

    const getCustomerData = () => {
        printDebugLog("getCustomerData", otp);
        if (!isEmpty(otp) && otp.length > 3) {
            let cartUrl = storeApiUrl.BACKEND_BASE_URL + "cart/get-shop-customer";
            let data = {
                jcJson: {
                    shopHash: shopInfo.shopHash,
                    custMob: custInfo.custNumber,
                    sso: false,
                    otp: otp,
                }
            };
            setIsCustLoading(true);
            //console.log("getEcartShopProduct  => ", data);
            axios.post(
                cartUrl,
                data
            )
                .then((response) => {
                    setIsCustLoading(false);
                    printDebugLog("createOrder <= ", response.data);
                    if (response.data.status === SUCCESS) {
                        setCustInfo({
                            ...custInfo,
                            custId: response.data.jcJson.custId,
                            custName: response.data.jcJson.custName,
                            custEmail: response.data.jcJson.custEmail,
                            verificationRequired: 2,
                        })
                    }
                }).catch((error) => {
                    setIsCustLoading(false);
                    printInfoLog("errr createOrder", error);
                    //window.location.href = "#/error";
                });
        } else {
            setSnpComponent({
                ...snpComponent, snackBarMsg: "Please enter correct otp!", snackBarOpen: true,
            })
        }
    }

    const updateCustomerData = () => {
        //Save Data
    };

    const logoutUser = () => {
        setCustInfo(CUST_INFO);
    };

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };

    const getPriceDets = (product) => {
        if (!isEmpty(product.priceName))
            return "\u20B9 " + product.price + " (" + product.priceName + ")";
        return "\u20B9 " + product.price;
    }

    // Function to handle increment
    const handleIncrement = (cellData) => {
        var updatedList = [...billingData.list];
        const index = updatedList.findIndex(singleValue => singleValue.prdId === cellData.id);
        var listObj;
        let mSubtotal = Number(billingData.subTotal);
        if (index === -1) {
            listObj = INITIAL_BILL_STOCK_DATA;
            listObj.prdId = cellData.id;
            listObj.productId = cellData.productId;
            listObj.productImage = isEmpty(cellData.productImage) ? "" : cellData.productImage[0];
            listObj.name = cellData.name;
            listObj.price = cellData.price;
            listObj.foodQuantityName = cellData.priceName;
            listObj.finalprice = cellData.price;
            listObj.note = "";
            listObj.priceIndex = 0;
            listObj.quantity = 1;
            listObj.offerDenom = cellData.offertype;
            listObj.offerAmt = cellData.offerAmt;
            listObj.print = false;
            listObj.combo = (cellData.catgId === 'snp_combo_id');

            mSubtotal = Number(mSubtotal) + Number(cellData.price);
        } else {
            listObj = updatedList[index];
            listObj.quantity = listObj.quantity + 1;
            listObj.finalprice = listObj.price * listObj.quantity;

            mSubtotal = Number(mSubtotal) - Number(cellData.price) + Number(listObj.finalprice);
        }

        setQuantityCache((prevCache) => {
            const newCache = new Map(prevCache);
            newCache.set(cellData.id, listObj.quantity);
            return newCache;
        });

        let pIndex = listProduct.findIndex(singleValue => singleValue.id === cellData.id);
        cellData.quantity = listObj.quantity;
        listProduct[pIndex] = cellData;
        // setListProduct((previousState) => {
        //     const updatedList = [...previousState];
        //     updatedList[pIndex] = cellData;
        //     return {
        //         ...previousState,updatedList,
        //     };
        // });
        setListProduct(listProduct);

        let mCgstAmt = 0.0;
        let mSgstAmt = 0.0;
        let mRoundOff = 0.0;
        let mTotal = mSubtotal;
        if (billingData.isGstAllowed) {
            mCgstAmt = (Number(mTotal) * Number(billingData.cgst)) / 100;
            mSgstAmt = (Number(mTotal) * Number(billingData.sgst)) / 100;
        }
        mTotal = parseFloat(mTotal) + parseFloat(mCgstAmt.toFixed(2)) + parseFloat(mSgstAmt.toFixed(2));
        mRoundOff = mTotal.toFixed() - mTotal;
        mTotal = mTotal + mRoundOff;
        // if (index === -1) {
        //     setBillingData(prvsState => ({
        //         ...billingData,
        //         list: [...prvsState.list, { ...listObj }],
        //         subTotal: mSubtotal,
        //         total: mTotal,
        //         roundOff: mRoundOff,
        //         cgstAmt: mCgstAmt,
        //         sgstAmt: mSgstAmt,
        //     }));
        // } else {
        //     const updatedList = [...billingData.list];
        //     updatedList[index] = listObj;
        //     setBillingData({
        //         ...billingData, list: updatedList,
        //         subTotal: mSubtotal,
        //         total: mTotal,
        //         roundOff: mRoundOff,
        //         cgstAmt: mCgstAmt,
        //         sgstAmt: mSgstAmt,
        //     })
        // }
        printDebugLog("handleIncrement(index)", index);
        printDebugLog("handleIncrement(cellData)", cellData);

        printDebugLog("handleIncrement(listProduct)", listProduct);
        printDebugLog("handleIncrement", mSubtotal, mTotal, mCgstAmt, mSgstAmt, mRoundOff);

        setBillingData((prevData) => {
            // Create a copy of the list

            if (index > -1)
                updatedList[index] = listObj;
            else
                updatedList.push(listObj);
            printDebugLog("handleIncrement(updatedList)", updatedList);
            return {
                ...prevData,
                list: updatedList,
                subTotal: mSubtotal,
                total: mTotal,
                roundOff: mRoundOff,
                cgstAmt: mCgstAmt,
                sgstAmt: mSgstAmt,
                totalQuantity: prevData.totalQuantity + 1,
            };
        });
    };

    // Function to handle decrement
    const handleDecrement = (cellData) => {
        printDebugLog("handleDecrement(quantityCache)", quantityCache);
        printDebugLog("handleDecrement(billingData)", billingData);
        var array = [...billingData.list];
        const index = array.findIndex(singleValue => singleValue.prdId === cellData.id);
        if (index !== -1) {
            cellData.quantity = cellData.quantity - 1;
            setQuantityCache((prevCache) => {
                const newCache = new Map(prevCache);
                newCache.set(cellData.id, cellData.quantity);
                return newCache;
            });
            if (cellData.quantity === 0)
                array.splice(index, 1);
            else
                array[index].quantity = cellData.quantity;

            let mSubtotal = billingData.subTotal - cellData.price;

            let mCgstAmt = 0.0;
            let mSgstAmt = 0.0;
            let mRoundOff = 0.0;
            let mTotal = mSubtotal;
            if (billingData.isGstAllowed) {
                mCgstAmt = (Number(mTotal) * Number(billingData.cgst)) / 100;
                mSgstAmt = (Number(mTotal) * Number(billingData.sgst)) / 100;
            }
            mTotal = parseFloat(mTotal) + parseFloat(mCgstAmt.toFixed(2)) + parseFloat(mSgstAmt.toFixed(2));
            mRoundOff = mTotal.toFixed() - mTotal;
            mTotal = mTotal + mRoundOff;
            let pIndex = listProduct.findIndex(singleValue => singleValue.id === cellData.id);
            listProduct[pIndex] = cellData;

            setListProduct(listProduct);
            printDebugLog("handleDecrement(cellData)", cellData);
            printDebugLog("handleDecrement(array)", array);
            printDebugLog("handleDecrement(listProduct)", listProduct);
            printDebugLog("handleDecrement", mSubtotal, mTotal, mCgstAmt, mSgstAmt, mRoundOff);
            setBillingData({
                ...billingData,
                list: array,
                subTotal: mSubtotal,
                total: mTotal,
                roundOff: mRoundOff,
                cgstAmt: mCgstAmt,
                sgstAmt: mSgstAmt,
                totalQuantity: billingData.quantity - 1,
            });
        }

    };

    const handleCartIncrement = (prdId) => {
        let pIndex = listProduct.findIndex(singleValue => singleValue.id === prdId);
        handleIncrement(listProduct[pIndex]);
    }

    const handleCartDecrement = (prdId) => {
        let pIndex = listProduct.findIndex(singleValue => singleValue.id === prdId);
        handleDecrement(listProduct[pIndex]);
    }
    const getQuantity = (id) => {
        if (quantityCache.has(id))
            return quantityCache.get(id);
        return 0;
    }

    const renderCustomerDialog = () => {
        return (
            <>
                <Grid container spacing={2} sx={{ justifyContent: "center", alignItems: "center", alignContent: "center", p: 2 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField id="standard" label="Enter Your Mobile Number" fullWidth variant="filled" size="small" onChange={reqForOtp} defaultValue={custInfo.custNumber} disabled={isCustLoading ? true : false} />
                    </Grid>
                    {
                        custInfo.verificationRequired === 1 &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField id="standard" fullWidth label="Enter Otp" variant="filled" size="small" onChange={(e) => setOtp(e.target.value)} disabled={isCustLoading ? true : false} />
                        </Grid>
                    }
                    {
                        custInfo.verificationRequired === 1 &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Button variant="contained" color="primary" onClick={getCustomerData}>
                                Submit Otp
                            </Button>
                        </Grid>
                    }
                    {
                        custInfo.verificationRequired === 2 &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField id="standard" fullWidth label="Enter Name" variant="filled" size="small" defaultValue={custInfo.custName} onChange={(e) => setCustInfo({ ...custInfo, custName: e.target.value })} />
                        </Grid>
                    }
                    {
                        custInfo.verificationRequired === 2 &&
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <TextField id="standard" fullWidth label="Enter Email" variant="filled" size="small" defaultValue={custInfo.custEmail} onChange={(e) => setCustInfo({ ...custInfo, custEmail: e.target.value })} />
                        </Grid>
                    }
                    <span style={{ verticalAlign: "middle" }}>
                        {isCustLoading ? <SnpProgress mSize={30} /> : ''}
                    </span>

                </Grid>

            </>
        )
    }

    const custCopyAction = () => {
        return (
            <React.Fragment>
                {
                    custInfo.verificationRequired === 2 &&
                    <SnpButton variant="contained" color="primary" onClick={() => {
                        setCustDialog(false);
                        updateCustomerData();
                    }}>
                        Submit
                    </SnpButton>
                }
                {
                    !isEmpty(custInfo.custId) &&
                    <SnpButton variant="contained" color="secondary" onClick={() => {
                        setCustDialog(false);
                        logoutUser()
                    }}>
                        Sign Out
                    </SnpButton>
                }
                <DismissButton variant="contained" color="primary" onClick={() => setCustDialog(false)}>
                    Dismiss
                </DismissButton>
            </React.Fragment>
        )
    };
    const custCopyFunc = () => {
        return < DialogBox
            showDialog={custDialog}
            title="Customer Info!"
            onClose={() => setCustDialog(false)}
            dialogContent={renderCustomerDialog()}
            dialogActions={custCopyAction()}
        ></DialogBox>
    };

    const renderSnpProduct = (product) => {
        return (
            <Paper
                elevation={5}
                autoHeight
                className="product-container"
                sx={{
                    borderRadius: 3,
                    backgroundColor: "white",
                }}>
                <div>
                    <div className="div-img-container" style={{ borderRadius: 4, background: isEmpty(product.categoryBackgroundColor) ? "#FFFFFF" : product.categoryBackgroundColor }}>
                        <center>
                            <Carousel mainUrl={storeApiUrl.BASE_IMAGE_URL} images={product.productImage} />
                        </center>
                    </div>
                    <Grid spacing={1} sx={{ p: 1 }}>
                        <Grid item xs={12} md={12} lg={12} sm={12}>
                            <p className="card-name" style={{
                                whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                                , fontWeight: "bold", fontSize: "16px", color: !isEmpty(product.defItemFontColor) ? product.defItemFontColor : "#000000"
                            }}>{product.name}</p>
                            <p className="category-name" style={{
                                whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                            }}>{product.categoryName}</p>
                            <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: "12px" }}
                            >{getPriceDets(product)}</p>
                            <div style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'flex-end', width: "130px", marginLeft: "auto"
                            }}>
                                <TextField
                                    id="quantity"
                                    name="quantity"
                                    className={colQuantityClasses.input}
                                    type="number"
                                    inputMode="numeric"
                                    value={product.quantity || 0}
                                    variant="standard"
                                    size="small"
                                    sx={{
                                        width: "100%",
                                        justifyContent: "center", alignContent: "center",
                                        '& .MuiInputBase-input': {
                                            textAlign: "center",
                                        },
                                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Add shadow effect
                                        borderRadius: 1, // Optional: Add some border radius
                                    }}


                                    InputProps={{
                                        min: '0',
                                        startAdornment: (
                                            <InputAdornment>
                                                <IconButton>
                                                    <AddIcon color="success" sx={{
                                                        fontSize: "2rem",
                                                    }} onClick={(e) => handleIncrement(product)} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton>
                                                    <RemoveIcon color="warning" sx={{
                                                        fontSize: "2rem",
                                                    }} onClick={(e) => handleDecrement(product)} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>

            </Paper>
        );
    }

    const renderProductItem = () => {
        return (
            <Box
                sx={{
                    "& .MuiGrid-container": {
                        padding: "50px",
                        justifyContent: "space-around",
                    },
                    "& .MuiCard-root": {
                        position: "relative",
                        ...(!initialBg.image && {
                            backgroundColor: "transparent",
                        })
                    },
                    "& .MuiCardMedia-root": {
                        position: "absolute",
                        top: 0,
                        right: 0,
                        height: "100%",
                        width: "100%",
                    },
                    "& .MuiButtonBase-root": {
                        //margin: "1px 20px",
                    },
                    margin: "7px 0px 1px 0px",
                    borderRadius: "10px",
                    minHeight: 600,
                    ...(!initialBg.image && {
                        bgcolor: initialBg.background,
                    }),
                    display: "flex",
                    position: "relative",
                    backgroundImage: initialBg.image && `url(${storeApiUrl.BASE_IMAGE_URL + initialBg.background})`,
                }}
            >
                <Grid container spacing={2} sx={{ justifyContent: "center", alignContent: "center" }}>
                    <Grid item xs={12} md={12} sm={12} lg={12} sx={{mt:"10px"}}>
                        <div className="scroll-container">
                            {listCateogory.map((catg) => (
                                renderCateogory(catg)
                            ))}
                        </div>
                    </Grid>
                    {!snpComponent.isLoader && Object.values(listProduct).map((product) => {
                        return (
                            <Grid item xs={12} sm={6} md={3} lg={3} key={product.id}>
                                {renderSnpProduct(product)}
                            </Grid>
                        );
                    })
                    }
                </Grid>
            </Box>
        );
    };

    const renderCateogory = (catg) => {
        return (
            <Paper
                elevation={10}
                className="cateogory-container"
                onClick={(e) => getEcartShopProduct("", catg.categoryId)}
                sx={{
                    borderRadius: 3,
                    padding: 3,
                    backgroundColor: (selectedCatg === catg.categoryId) ? "#fcf3f3" : "white",
                }}>
                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontFamily: "sans-serif", fontSize: (selectedCatg === catg.categoryId) ? "17px" : "16px", fontWeight: "bold" }}>{catg.categoryName}</p>
                {/* {(selectedCatg ===catg.categoryId) && <PanToolAltIcon/>} */}
            </Paper>
        );
    };

    const renderCartDialogContent = () => {
        return (
            <Grid container spacing={3} sx={{ p: 1 }} >
                <Grid item xs={12} sm={12} md={7} lg={7}>
                    <div className="scroll-container-vertical">
                        {
                            billingData.list.length > 0 && (
                                Object.values(billingData.list).map((listObj) => {
                                    return (
                                        <Grid container spacing={1}>
                                            <Grid item xs={6} md={7} sm={6} lg={7}>
                                                <p className="card-name" style={{
                                                    whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                                                    , fontSize: "14px"
                                                }}>{listObj.name}</p>
                                                <p style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontFamily: "sans-serif", fontSize: "12px" }}>{listObj.quantity + " X ₹" + listObj.price + " = ₹" + listObj.finalprice}</p>
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'flex-start', width: "120px"
                                                }}>
                                                    <TextField
                                                        id="quantity"
                                                        name="quantity"
                                                        className={colQuantityClasses.input}
                                                        type="number"
                                                        inputMode="numeric"
                                                        value={listObj.quantity || 0}
                                                        variant="standard"
                                                        size="small"
                                                        sx={{
                                                            width: "100%",
                                                            justifyContent: "center", alignContent: "center",
                                                            '& .MuiInputBase-input': {
                                                                textAlign: "center",
                                                            },
                                                        }}


                                                        InputProps={{
                                                            min: '0',
                                                            startAdornment: (
                                                                <InputAdornment>
                                                                    <IconButton>
                                                                        <AddIcon color="success" sx={{
                                                                            fontSize: "2rem",
                                                                        }} onClick={(e) => handleCartIncrement(listObj.prdId)} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment>
                                                                    <IconButton>
                                                                        <RemoveIcon color="warning" sx={{
                                                                            fontSize: "2rem",
                                                                        }} onClick={(e) => handleCartDecrement(listObj.prdId)} />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={6} md={5} sm={6} lg={5}>
                                                <div className="cart-img-container">
                                                    <img
                                                        src={storeApiUrl.BASE_IMAGE_URL + listObj.productImage}
                                                        alt={`cart-img-${listObj.name}`}
                                                        className="carousel-image"
                                                    />
                                                </div>
                                            </Grid>

                                        </Grid>
                                    )
                                })
                            )
                        }
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={5} lg={5} >
                    <center>
                        <Paper
                            elevation={5}
                            height="auto"
                            sx={{
                                borderRadius: 3,
                                backgroundColor: "#e1edf0",
                                padding: "10px",
                            }}>
                            <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '18px' }} ><u>Bill Summary:</u></Typography>
                            <Typography variant="subtitle4" component="div" style={{ color: "black", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '14px' }} >Total Quantity: {billingData.totalQuantity}</Typography>
                            <Typography variant="subtitle4" component="div" style={{ color: "black", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '14px' }} >Sub Total: ₹{billingData.subTotal}</Typography>
                            <Typography variant="subtitle4" component="div" style={{ color: "#3d0201", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '12px' }} >Cgst({billingData.cgst + "%"}): ₹{billingData.cgstAmt}</Typography>
                            <Typography variant="subtitle4" component="div" style={{ color: "#3d0201", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '12px' }} >Sgst({billingData.sgst + "%"}): ₹{billingData.sgstAmt}</Typography>
                            <Typography variant="subtitle4" component="div" style={{ color: "#a17978", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '12px' }} >RoundOff({billingData.roundOff > 0 ? "+" : "-"}): {billingData.roundOff.toFixed(2)}</Typography>
                            <CssBaseline />
                            <Typography variant="subtitle4" component="div" style={{ color: "#2f1160", whiteSpace: 'pre-wrap', overflowWrap: 'break-word', fontSize: '15px', fontWeight: "bold" }} >Final: ₹{billingData.total}</Typography>
                        </Paper>
                    </center>
                </Grid>
            </Grid>
        )
    };

    const renderCartDialogAction = () => {
        return (
            <>
                <SnpButton variant="contained" color="primary" onClick={createOrder}>
                    Pay
                </SnpButton>
                <DismissButton variant="contained" color="primary" onClick={(e) => setCartDialog(false)}>
                    Add More!
                </DismissButton>
            </>
        )
    };

    const renderCartDialog = () => {
        return < DialogBox
            showDialog={cartDialog}
            title="Cart!"
            dialogContent={renderCartDialogContent()}
            dialogActions={renderCartDialogAction()}
        ></DialogBox>
    };

    const renderSnpContent = () => {
        return (
            <Grid container spacing={0} sx={{ justifyContent: "center", alignContent: "center" }}>
                <Grid item xs={12} md={12} sm={12} lg={12} sx={{
                    display: {
                        xs: 'none', // Hide on xs
                        sm: 'none', // Hide on sm
                        md: 'block', // Show on md and larger
                    },
                }}>
                    <EcartNavbar shopName={shopInfo.shopName} totalQuantity={billingData.totalQuantity} custName={custInfo.custName} shopIconClick={(e) => setCartDialog(true)} custIconClick={(e) => setCustDialog(true)} />
                </Grid>

                {/* <br/><br/> */}
                <Grid item xs={12} md={12} sm={12} lg={12}>
                    {renderProductItem()}
                </Grid>
                {renderCartDialog()}
                {custCopyFunc()}
                {/* <Grid item xs={12} md={12} sm={12} lg={12}>
                    <Button variant="contained" color="primary" onClick={(e) => setCartDialog(true)}>
                        Open Cart
                    </Button>
                </Grid> */}
                <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    lg={12}
                    sx={{
                        display: {
                            xs: 'block', // Show on xs
                            sm: 'block', // Show on sm
                            md: 'none',  // Hide on md and larger
                            lg: 'none',  // Hide on lg
                        },
                    }}
                >
                    <BottomAppBar shopName={shopInfo.shopName} totalQuantity={billingData.totalQuantity} custName={custInfo.custName} shopIconClick={(e) => setCartDialog(true)} custIconClick={(e) => setCustDialog(true)} />
                </Grid>
            </Grid>
        )
    };
    return (
        <>
            <SnpComponent content={renderSnpContent()}
                onSnackClose={handleSnackClose}
                isLoader={snpComponent.isLoader}
                snackBarOpen={snpComponent.snackBarOpen}
                snackBarMsg={snpComponent.snackBarMsg}
                outSideClickDismiss={snpComponent.outSideClickDismiss}
                handleSnpSubmit={snpComponent.handleSnpSubmit} />
        </>
    );
}