import React, { useLayoutEffect, useState } from "react";
import { Grid, TextField, Box,Paper,TableBody } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Checkbox from '@mui/material/Checkbox';
import "./Employee.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
    CHECK_EMPLOYEE_IN_JCID,
    GET_EMPLOYEE,
    SET_EMPLOYEE,
    SUCCESS,
    ItemStatus,
    CART_INITIAL_STATE,
    isRoleAllowed,
    USERUPDATION,
    GETEMPACCOUNTING,
    ADMINRIGHTS,
    USERCREATION,
    getPreUrlAccordingToCart,
    GETSINGLECONFIGS,
    HOTELSALESCONFIG,
    HOTELSALES,
    printDebugLog,
    GETVIDEOPATH
} from "../../constants/constant";
import axios from "axios";
import DialogBox from "../global/DialogBox";
import CustomDataGrid from "../global/CustomDataGrid";
import {
    Button, RadioGroup, FormControlLabel,
    FormLabel, Radio
} from "@mui/material";
import { useSelector } from "react-redux";
import SnpComponent from "../global/SnpComponent";
import { snpAxios } from '../global/api';
import YouTubeVideo from "../global/YouTubeVideo";
import SingleFab from "../global/fab/SingleFab";
import { SCREEN_EMP } from "../../constants/VideoConstant";
import DismissButton from "../global/DismissButton";
import SnpButton from "../global/SnpButton";

export default function Employee({ showDialogBoxWithPaper }) {
    const storeCartType = useSelector(state => state.setCartTypeReducer.cartType);
    const storeActiveShopHash = useSelector(state => state.setActiveShopHashReducer.activeShopHash);
    const storeApiUrl = useSelector(state => state.setApiUrlReducer.MAINURL.INITIAL_BASE_URL);
    const [isDataLoaded, setDataLoaded] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [isEditProduct, setEditProduct] = useState(false);
    const [checkData, setCheckData] = useState("");
    const [hotelSale,setHotelSales] = useState(false);
    const [checkAccounting, setCheckAccounting] = useState(false);
    const [employeeData, setEmployeeData] = useState([]);
    const [empCount,setEmpCount] = useState(0);
    const [empCreate,setEmpCreate] = useState(false);
    const [myRoles, setMyRoles] = useState(new Map());
    const [myRolesRules, setMyRolesRules] = useState(true);
    const [employeeSingleData, setSingleEmployeeData] = useState({
        name: "",
        userEmail: "",
        jcId: "",
        activeStatus: 1,
        phone: "",
        createdDate: "",
        passWord: "",
        roles: [{}],
        employeeType: "",
        firstTimeLogin: false,
    });
    const [videoModel, setVideoModel] = useState({show:false,path:""});

    const INITIAL_SNP_COMPONENT = {
        showDialog: false,
        outSideClickDismiss: true,
        title: "",
        subTitle: "",
        negativeAction: "",
        positiveAction: "",
        positiveReq: false,
        isLoader: false,
        iconReq: "",
        snackBarOpen: false,
        snackBarMsg: "",
        cancelExtra: false,
        negativeReq: false,
        requestType: 0,
    };
    const [snpComponent, setSnpComponent] = useState(INITIAL_SNP_COMPONENT);

    const INITIAL_DAYBOOK = {
        openingBalance: "",
        todaysBalance: "",
        totalBalance: "",
        totalExpense: "",
        totalDues: "",
        totalLoanAmt: "", todaysExpense: "", todaysDues: "", todaysLoanAmt: "",
        totalCashBalance: "", openingCashBalance: "", todaysCashBalance: "",
        todaysCashExpenses: "", totalCashExpenses: "", totalOnlineBalance: "",
        todaysOnlineBalance: "", openingOnlineBalance: "", totalOnlineExpenses: "",
        todaysOnlineExpenses: "", totalTotalOnlineBalance: "", todaysTotalOnlineBalance: "", openingTotalOnlineBalance: "", totalTotalOnlineExpenses: "",
        todaysTotalOnlineExpenses: "",
    };

    const [dayBook, setDayBook] = useState(INITIAL_DAYBOOK);

    const [formSubmitted, setFormSubmitted] = useState(false);

    const columns = [
        { field: 'name', headerName: 'Name', width: 150, renderCell: (params) => (
            <span style={{ fontWeight: 'bold', color: '#151b45' }}>{params.value}</span>
        ), },
        { field: 'employeeType', headerName: 'Emp. Type', width: 150 },
        { field: 'userEmail', headerName: 'Email', width: 190 },
        { field: 'phone', headerName: 'Phone', width: 150,renderCell: (params) => (
            <span style={{ fontWeight: 'bold', color: '#0f633b' }}>{params.value}</span>
        ), },
        { field: 'activeStatusStr', headerName: 'Status', width: 150 },
        {
            field: "edit",
            headerName: "Edit",
            width: 90,
            renderCell: (cellValues) => {
                return (
                    <EditIcon
                        onClick={(event) => {
                            if (isRoleAllowed(USERUPDATION)) {
                                handleCellClick(event, cellValues);
                            } else {
                                showDialogBoxWithPaper("You Are not allowed to Update Employee.Contact the Owner");
                            }

                        }}
                    >
                    </EditIcon>
                );
            }
        }
    ];


    const columnsAdmin = [
        { field: 'name', headerName: 'Name', width: 150, renderCell: (params) => (
            <span style={{ fontWeight: 'bold', color: '#151b45',fontSize:"18px" }}>{params.value}</span>
        ), },
        { field: 'employeeType', headerName: 'Emp. Type', width: 150 },
        { field: 'userEmail', headerName: 'Email', width: 190 },
        { field: 'phone', headerName: 'Phone', width: 150,renderCell: (params) => (
            <span style={{  color: '#151b45' }}>{params.value}</span>
        ), },
        { field: 'activeStatusStr', headerName: 'Status', width: 150,valueGetter: (params) => params.row.activeStatusStr,renderCell: (params) => (
            <span style={{  color: params.value === 'Active' ? '#0f633b' : "#7a1904" }}>{params.value}</span>
        ), },
        {
            field: "view",
            headerName: "View Sales",
            width: 110,
            renderCell: (cellValues) => {
                return (
                    <VisibilityIcon
                        onClick={(event) => {
                            handleViewClick(cellValues.row.empId);
                        }}
                    >
                    </VisibilityIcon>
                );
            }
        },
        {
            field: "edit",
            headerName: "Edit",
            width: 90,
            renderCell: (cellValues) => {
                return (
                    <EditIcon
                        onClick={(event) => {
                            if (isRoleAllowed(USERUPDATION)) {
                                handleCellClick(event, cellValues);
                            } else {
                                showDialogBoxWithPaper("You Are not allowed to Update Employee.Contact the Owner");
                            }

                        }}
                    >
                    </EditIcon>
                );
            }
        }
    ];

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'searchJcIdPhone') {
            setCheckData(value);
        }else if (name === 'checkUnCheck') {
            let checked = myRolesRules;
            checkUnCheckRoles(!checked);
        } else if (name === 'rolechecked') {
            //console.log("handleInputChange", event.target);
            let id = event.target.id;
            let roleValue = myRoles.get(id);

            setMyRoles(m => {
                const nList = new Map(m);

                nList.set(id, !roleValue);

                return nList;
            })

            //console.log("handleInputChange employeeData", id,myRoles);
        } else {
            setSingleEmployeeData({
                ...employeeSingleData,
                [name]: value,
            });
        }
        //console.log("setSingleEmployeeData-> ",employeeSingleData);
    };

    const handleCellClick = (param, event) => {
        //console.log("handleCellClick : ", event.row);
        setSingleEmployeeData({
            ...employeeSingleData,
            name: event.row.name,
            userEmail: event.row.userEmail,
            jcId: event.row.jcId,
            activeStatus: event.row.activeStatus,
            phone: event.row.phone,
            createdDate: event.row.createdDate,
            passWord: event.row.userPassWord,
            roles: event.row.roles,
            employeeType: event.row.employeeType,
            firstTimeLogin: event.row.firstTimeLogin,
        });
        Object.keys(CART_INITIAL_STATE.roles).map((key) => addRoles(hotelSale,key,event.row.roles));
        setShowDialog(true);
        setEditProduct(true);
    };

    const handleViewClick = (mEmpId) => {
        //console.log("handleViewClick : ", mEmpId);
        setDataLoaded(false);
        let eUrl = storeApiUrl.BACKEND_BASE_URL + GETEMPACCOUNTING
        let filledData = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                empId: mEmpId,
                shopHash: storeActiveShopHash.id,
            }
        };
        snpAxios.post(eUrl,filledData)
        .then(response => {
            //console.log("responses => ", response.data);
            setDataLoaded(true);
            if (response.data.status === SUCCESS) {
                setDayBook(response.data.jcJson.dayBook);
                setCheckAccounting(true);
                setShowDialog(true);
            } else {
                setSnpComponent({
                    ...snpComponent,
                    snackBarMsg: "No Sales Found for this Employee",
                    snackBarOpen: true,
                })
            }
        }).catch(error => {
            console.log("error : ", error);
            setDataLoaded(true);
            setSnpComponent({
                ...snpComponent,
                snackBarMsg: "No Sales Found for this Employee",
                snackBarOpen: true,
            })
            //window.location.href = "#/error";
        });
    };

    const addRoles = (haveHotel , key ,array) => {
        if (key === HOTELSALES) {
            //console.log("addRoles" , haveHotel , key,array[key]);
            if (haveHotel) {
                setMyRoles(myRoles.set(key, array[key]));
            }
        } else {
            //console.log("addRoles" , haveHotel , key);
            setMyRoles(myRoles.set(key, array[key]));
        }
            
    };


    const callForEmployee = (haveHotel)=> {
        Object.keys(CART_INITIAL_STATE.roles).map((key) => addRoles(haveHotel,key , CART_INITIAL_STATE.roles));
        // console.log('Printing Login Details : ', myRoles);
        getEmployeeDetails();  
    };

    const getSingleConfigs = (mConfigName) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETSINGLECONFIGS + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&configName=${mConfigName}` + `&shopHash=${storeActiveShopHash.id}`;
        let data = {};
        //const urlFormattedString = encodeURIComponent(cUrl);
        //printLog("getSingleConfigs => ", cUrl);
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    if (mConfigName === HOTELSALESCONFIG) {
                        callForEmployee(response.data === 'Yes');
                        setHotelSales(response.data === 'Yes');
                    } else {
                        callForEmployee(false);
                    }
                }
            }).catch((error) => {
                callForEmployee(false);
                //printLog("error : ", error);
                //window.location.href = "#/error";
            });
    };

    const handleEmployeeSubmit = async () => {
        setFormSubmitted(true);
        if (!isValid()) {
            return;
        }
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + SET_EMPLOYEE;
        employeeSingleData.roles = [];
        let jsonObject = {};
        myRoles.forEach((value, key) => {
            jsonObject[key] = value
        });
        //console.log(jsonObject);
        setDataLoaded(false);
        let filledData = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                jcId: employeeSingleData.jcId,
                shopHash: storeActiveShopHash.id,
                phone: employeeSingleData.phone,
                activeStatus: parseInt(employeeSingleData.activeStatus),
                name: employeeSingleData.name,
                userEmail: employeeSingleData.userEmail,
                value: employeeSingleData.passWord,
                employeeType: employeeSingleData.employeeType,
                roles: jsonObject
            }
        };
        //console.log("handleEmployeeSubmit filledData => ", filledData);
        setShowDialog(false);
        snpAxios.post(catgUrl,filledData)
        .then(response => {
            setDataLoaded(true);
            printDebugLog("responses => ", response.data);
            if (response.data.status === SUCCESS) {
                getEmployeeDetails();
            }
        }).catch(error => {
            setDataLoaded(true);
            console.log("error : ", error);
            setFormSubmitted(false);
            //window.location.href = "#/error";
        });
    };

    const getEmployeeDetails = async () => {
        let cartUrl = storeApiUrl.BACKEND_BASE_URL + GET_EMPLOYEE;

        let data = {
            jcJson: {
                apiToken: CART_INITIAL_STATE.apiToken,
                shopHash: storeActiveShopHash.id,
            }
        };
        //  console.log("getProductDetails  => ",data);
        snpAxios.post(
            cartUrl,
            data
        )
            .then((response) => {
                //console.log("response  => ", response.data.jcJson);
                setDataLoaded(true);
                if (response.data.status === SUCCESS) {
                    setEmployeeData(response.data.jcJson.value_2);
                }
                setEmpCount(response.data.jcJson.empCount);
                setEmpCreate(response.data.jcJson.empCreate);
            }).catch((error) => {
                console.log("errr ", error);
                //window.location.href = "#/error";
            });
    };
    const checkEmployeeInJc = async () => {
        let catgUrl = storeApiUrl.BACKEND_BASE_URL + CHECK_EMPLOYEE_IN_JCID;
        let data = {
            jcJson: {
                value: checkData,
            }
        };
        snpAxios.post(
            catgUrl,
            data
        )
            .then((response) => {

                if (response.data.status === SUCCESS) {
                    //console.log("response checkEmployeeinjc", response.data);
                    setSingleEmployeeData({
                        ...employeeSingleData,
                        userEmail: response.data.jcJson.email,
                        jcId: response.data.jcJson.jcId,
                        phone: response.data.jcJson.phone,
                        name: response.data.jcJson.name,
                        passWord: response.data.jcJson.value,
                        activeStatus: 1,
                        roles: [{}],
                    });
                }
            }).catch((error) => {
                console.log("checkEmployeeInJc ", error);
            });
    };
    useLayoutEffect(() => {
        //console.log('Printing Login Details : ', CART_INITIAL_STATE);
        getYoutubeVideo(SCREEN_EMP , 'hi');
        getSingleConfigs(HOTELSALESCONFIG);
    }, [storeCartType, storeActiveShopHash,storeApiUrl]);

    const getYoutubeVideo = (videoName,lang) => {
        let cUrl = storeApiUrl.BACKEND_BASE_URL + getPreUrlAccordingToCart(storeActiveShopHash.cartType) + GETVIDEOPATH + `?adminId=${CART_INITIAL_STATE.apiToken}`
            + `&name=${videoName}` + `&lang=${lang}`;
        let data = {};
        axios.get(
            cUrl,
            data
        )
            .then((response) => {
                //printLog("getSingleConfigs<=",response.data);
                if (response.data !== '') {
                    setVideoModel({...videoModel,path : response.data});
                } else 
                    setVideoModel({...videoModel,path : ''});
            }).catch((error) => {
                //printLog("error : ", error);
                //window.location.href = "#/error";
                setVideoModel({...videoModel,path : ''});
            });
    };

    const renderDialogContent = () => {
        return (
            <React.Fragment>
                <div>
                    <Box
                        component="form"
                        autoComplete="off"
                    >

                        {/* {
                            !isEditProduct ?
                                <div className="tooltip">

                                    <TextField
                                        required
                                        id="searchJcIdPhone"
                                        label="Search By JcId or Phone"
                                        name="searchJcIdPhone"
                                        variant="filled"
                                        size="small"
                                        onChange={handleInputChange}
                                        value={checkData} />
                                    <PersonSearchIcon onClick={checkEmployeeInJc} />
                                    <span className="tooltiptext">Search</span>
                                </div>
                                : null
                        }
                        <br /><br /> */}



                        <Grid container spacing={1} justifyContent="center"
                            alignItems="center" sx={{
                                padding: 0.1,
                            }}>
                            <Grid md={12} sm={12} xs={12} lg={12} >
                                <center>
                                    <div>
                                        <FormLabel component="legend">Enter Employee Status</FormLabel>
                                        <RadioGroup row aria-label="activeStatus" name="activeStatus" value={employeeSingleData.activeStatus.toString()} onChange={handleInputChange} style={{
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            <FormControlLabel value={ItemStatus.ACTIVE.toString()} control={<Radio />} label="Active" />
                                            <FormControlLabel value={ItemStatus.INACTIVE.toString()} control={<Radio />} label="InActive" />
                                        </RadioGroup>
                                    </div>
                                </center>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={6}>
                                <TextField
                                    required
                                    id="name"
                                    label="Enter Employee Name"
                                    name="name"
                                    variant="filled"
                                    size="small"
                                    value={employeeSingleData.name}
                                    onChange={handleInputChange}
                                    fullWidth
                                    error={formSubmitted && !employeeSingleData.name}
                                    helperText={getErrorMessage('name')}
                                /></Grid>
                            <Grid item xs={12} md={6} sm={6} lg={6}>
                                <TextField
                                    required
                                    id="phone"
                                    label="Enter Phone"
                                    name="phone"
                                    variant="filled"
                                    size="small"
                                    value={employeeSingleData.phone}
                                    onChange={handleInputChange}
                                    fullWidth
                                    error={formSubmitted && !employeeSingleData.phone}
                                    helperText={getErrorMessage('phone')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={6}>
                                <TextField
                                    required
                                    id="userEmail"
                                    variant="filled"
                                    size="small"
                                    label="Enter Email"
                                    name="userEmail"
                                    value={employeeSingleData.userEmail}
                                    onChange={handleInputChange}
                                    fullWidth
                                    error={formSubmitted && !employeeSingleData.userEmail}
                                    helperText={getErrorMessage('userEmail')}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={6}>
                                <TextField
                                    id="employeeType"
                                    label="Enter Employee Type"
                                    name="employeeType"
                                    variant="filled"
                                    size="small"
                                    value={employeeSingleData.employeeType}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                        </Grid> <Grid item xs={12} md={6} sm={6} lg={6}>
                            <TextField
                                id="jcId"
                                label="Unique Id"
                                name="jcId"
                                disabled
                                variant="filled"
                                size="small"
                                value={employeeSingleData.jcId}
                                onChange={handleInputChange}
                                fullWidth
                            />

                        </Grid>

                        {
                            employeeSingleData.firstTimeLogin && <Grid item xs={12} md={6} sm={6} lg={4}>
                                <center>
                                    <p>{"Your First Time Password is: " + employeeSingleData.passWord}</p>
                                </center>
                            </Grid>
                        }
                        <p> Employee Role Management.  <FormControlLabel control={<Checkbox
                                        checked={myRolesRules}
                                        onChange={handleInputChange}
                                        name="checkUnCheck"
                                        fullWidth
                                        id="checkUnCheck"
                                    />}
                                        label={myRolesRules ? "Unchecked all roles" : "Checked all roles"} />
                                        </p>
                        <Grid container spacing={1}>
                            {[...myRoles.keys()].map(key => (
                                <Grid item xs={12} md={6} sm={6} lg={6}>
                                    <FormControlLabel control={<Checkbox
                                        checked={myRoles.get(key)}
                                        onChange={handleInputChange}
                                        name="rolechecked"
                                        fullWidth
                                        id={key}
                                    />}
                                        label={key} />
                                </Grid>
                            ))}
                        </Grid>

                    </Box>
                </div>
            </React.Fragment >

        );
    }
    const getBalance = (type) => {
        let bal = 0;
        if (type === 0)
            bal = dayBook.openingBalance;
        else if (type === 1)
            bal = dayBook.todaysBalance;
        else if (type === 2)
            bal = dayBook.todaysExpense;
        else if (type === 3)
            bal = dayBook.totalBalance;
        else if (type === 4)
            bal = dayBook.totalExpense;
        return bal;
    }

    const renderDialogEmpAccount = () => {
        return (
            <React.Fragment>
                <div>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" className="rate_card">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center">Cash(₹)</TableCell>
                                    <TableCell align="center">Online(₹)</TableCell>
                                    <TableCell align="center">T.Amt(₹)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.values(dayBook).map((val, index) => {
                                    return (
                                        <TableRow
                                            className="rate_card--row"
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell align="center">{val.tye}</TableCell>
                                            <TableCell align="center" >{val.cash}</TableCell>
                                            <TableCell align="center" >{val.online}</TableCell>
                                            <TableCell align="center" >{val.total}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </React.Fragment>
        );
    }

    const renderMainDialogContent = () => {
        return (
            <React.Fragment>
                <div>
                    {
                        checkAccounting ? renderDialogEmpAccount() : renderDialogContent()
                    }
                </div></React.Fragment>
        );
    }

    const renderDialogAction = () => {
        return (
            <React.Fragment>
                {
                    !checkAccounting && <SnpButton variant="contained" onClick={handleEmployeeSubmit}>
                        {isEditProduct ? "Update" : "Submit"}
                    </SnpButton>
                }
                <DismissButton variant="contained" onClick={handleDialogClose}>
                    Dismiss
                </DismissButton>
            </React.Fragment>
        );
    }

    const checkUnCheckRoles = (checked) => {
        Object.keys(CART_INITIAL_STATE.roles).map((key) => (
            setMyRoles(myRoles.set(key, checked))
        ));
        setMyRolesRules(checked);
    }

    const handleDialogClose = () => {
        // console.log("handleDialogClose");

        setSingleEmployeeData({
            name: "",
            userEmail: "",
            jcId: "",
            activeStatus: 1,
            phone: "",
            createdDate: "",
            passWord: "",
            roles: [],
        })
        setDataLoaded(INITIAL_DAYBOOK);
        setCheckAccounting(false);
        Object.keys(CART_INITIAL_STATE.roles).map((key) => (
            setMyRoles(myRoles.set(key, CART_INITIAL_STATE.roles[key]))
        ));
        setShowDialog(false);
        setEditProduct(false);
        setFormSubmitted(false);
        setMyRolesRules(true);
    };

    const getErrorMessage = (field) => {
        if (!formSubmitted) {
            return;
        } else if (!employeeSingleData[field]) {
            return 'Field is required';
        }
    }

    const isValid = () => {
        let isEmployeeDataValid = true;

        if (!employeeSingleData.name || !employeeSingleData.phone || !employeeSingleData.userEmail) {
            isEmployeeDataValid = false;
        }

        return isEmployeeDataValid;
    }
    const renderSnpContent = () => {
        return (
            <React.Fragment>
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ padding: 10 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ boxShadow: 10 }}
                                style={{ float: "right" }}
                                onClick={(event) => {
                                    if (isRoleAllowed(USERCREATION)) {
                                        if (empCreate) 
                                            setShowDialog(true);
                                        else
                                            showDialogBoxWithPaper("You cannot Create Employee more than "+empCount +" .Contact SNP");
                                    } else
                                        showDialogBoxWithPaper("You Are not allowed to Create Employee.Contact the Owner");
                                }}>
                                Add New Employee
                            </Button>
                        </div>

                        <CustomDataGrid
                            getRowId={row => row.phone}
                            rows={employeeData}
                            columns={isRoleAllowed(ADMINRIGHTS) ? columnsAdmin : columns}
                            loading={!isDataLoaded}
                        />
                    </Grid>
                </Grid>
                < DialogBox
                    showDialog={showDialog}
                    title={checkAccounting ? "Employee Balance Sheet " : isEditProduct ? "Update Employee" : "Add New Employee"}
                    onClose={handleDialogClose}
                    dialogContent={renderMainDialogContent()}
                    dialogActions={renderDialogAction()}
                ></DialogBox>
                {videoModel.show && <YouTubeVideo videoId={videoModel.path} onDismiss={(e) => setVideoModel({...videoModel,show:false})}/> }
                {videoModel.path && <SingleFab onClick={(e)=> setVideoModel({...videoModel,show:true})}/> }
            </React.Fragment>
        );
    }

    const handleSnackClose = () => {
        setSnpComponent({
            ...snpComponent,
            snackBarOpen: false,
            snackBarMsg: "",
        });
    };
    return (
        <React.Fragment>
            <SnpComponent
                content={renderSnpContent()}
                onSnackClose={handleSnackClose}
                snackBarOpen={snpComponent.snackBarOpen}
                snackBarMsg={snpComponent.snackBarMsg}
                outSideClickDismiss={snpComponent.outSideClickDismiss}
            />
        </React.Fragment>
    );
}


